import SegmentIcon from '@mui/icons-material/Segment'
import { Chip, styled } from '@mui/material'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineTooltip, colors } from 'siteline-common-web'
import { useBillingWorksheetContext } from '../../common/contexts/BillingWorksheetContext'
import { useCompanyContext } from '../../common/contexts/CompanyContext'

export const BILLING_WORKSHEET_SIDEBAR_WIDTH_PERCENT = 40

const WorksheetLinesIconChip = styled(Chip)(() => ({
  '&.MuiChip-root': {
    backgroundColor: colors.grey30,
    color: colors.grey70,
    transition: 'background-color 150ms ease-out, color 150ms ease-out',
    '&:hover': {
      backgroundColor: colors.grey20,
    },
    '&.isItemOpen': {
      backgroundColor: colors.grey50,
      color: colors.grey10,
      '& .MuiSvgIcon-root': {
        color: colors.grey10,
      },
    },
    '&:not(.hasLabel)': {
      '& .MuiChip-label': {
        // Override padding on the label element if it's empty so it doesn't take up extra space
        paddingRight: 0,
      },
    },
  },
}))

interface BillingWorksheetLinesIconProps {
  sovLineItemId: string
  numWorksheetItems: number
  /**
   * Logic for when the icon can be shown.
   * default: The icon is always shown, as long as worksheets are enabled for the company
   * withItems: The icon is shown only when the SOV line item has worksheet items
   * never: The icon is never shown, even if the SOV line item has worksheet items
   */
  displayMode: 'default' | 'withItems' | 'never'
}

export function BillingWorksheetLinesIcon({
  sovLineItemId,
  numWorksheetItems,
  displayMode,
}: BillingWorksheetLinesIconProps) {
  const { t } = useTranslation()
  const { enableBillingWorksheets } = useCompanyContext()
  const { onViewSovLineItemWorksheet, viewingSovLineItemId, onCloseSidebar } =
    useBillingWorksheetContext()

  const handleViewLineItemInWorksheet = useCallback(() => {
    if (viewingSovLineItemId === sovLineItemId) {
      onCloseSidebar()
    } else {
      onViewSovLineItemWorksheet(sovLineItemId)
    }
  }, [viewingSovLineItemId, sovLineItemId, onCloseSidebar, onViewSovLineItemWorksheet])

  if (!enableBillingWorksheets || displayMode === 'never') {
    return null
  }

  // If there are no worksheet items, we don't show the icon in certain cases (e.g. on the invoice
  // where the user wouldn't be able to add line items)
  if (numWorksheetItems === 0 && displayMode === 'withItems') {
    return null
  }

  return (
    <SitelineTooltip title={t('projects.subcontractors.worksheet.worksheet_icon_tooltip')}>
      <WorksheetLinesIconChip
        onClick={handleViewLineItemInWorksheet}
        size="small"
        icon={<SegmentIcon />}
        label={numWorksheetItems > 0 ? numWorksheetItems : undefined}
        className={clsx({
          isItemOpen: sovLineItemId === viewingSovLineItemId,
          hasLabel: numWorksheetItems > 0,
        })}
      />
    </SitelineTooltip>
  )
}
