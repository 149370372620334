import { TFunction } from 'i18next'
import moment, { Moment } from 'moment-timezone'
import { bold, italic, string } from 'siteline-common-web'
import type { CellObject } from 'xlsx-js-style'
import { apolloClient } from '../../client'
import { UserProps } from '../contexts/UserContext'
import {
  AgingAmountType,
  BillingType,
  CashForecastPeriodType,
  ContractMonthlyStatusFilter,
  DashboardFiltersInput,
  GetGeneralContractorsDocument,
  GetGeneralContractorsQuery,
  GetGeneralContractorsQueryVariables,
  GetLeadPMsDocument,
  GetLeadPMsQuery,
  GetLeadPMsQueryVariables,
  LocationProperties,
} from '../graphql/apollo-operations'
import { getOfficeName } from './Office'

export type ExportContext = {
  t: TFunction
  filters: DashboardFiltersInput
  search: string
  user: UserProps
  companyId: string | null
  locations: LocationProperties[]
  month: number
  year: number
  cashForecastPeriod: CashForecastPeriodType
}

/**
 * Returns a list of all lead PMs to be used in an export.
 */
export async function getLeadPMs(companyId: string | null): Promise<GetLeadPMsQuery['leadPMs']> {
  const query = await apolloClient.query<GetLeadPMsQuery, GetLeadPMsQueryVariables>({
    query: GetLeadPMsDocument,
    variables: { companyId },
  })
  return query.data.leadPMs
}

/**
 * Returns a list of all GCs to be used in an export.
 */
export async function getGeneralContractors(
  companyId: string | null
): Promise<GetGeneralContractorsQuery['generalContractors']> {
  const query = await apolloClient.query<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  >({
    query: GetGeneralContractorsDocument,
    variables: { companyId },
  })
  return query.data.generalContractors
}

interface GetFilterRowsParams {
  ctx: ExportContext
  leadPMs: GetLeadPMsQuery['leadPMs']
  generalContractors: GetGeneralContractorsQuery['generalContractors']
  /** Forecast maps to the billing forecast */
  exportFrom: 'aging' | 'billing' | 'forecast' | 'cashForecast' | 'overview'
  ignoreProjectStatusFilter?: boolean
}

/**
 * Returns a list of rows for each filter used in the export.
 */
export function getFilterRows({
  ctx,
  leadPMs,
  generalContractors,
  exportFrom,
  ignoreProjectStatusFilter = false,
}: GetFilterRowsParams): CellObject[][] {
  const rows: CellObject[][] = []
  const filterAll = ctx.t(`reporting_home.filters.all`)

  if (exportFrom === 'aging') {
    // Aging amount type filter
    const agingAmountType =
      ctx.filters.agingAmountType ?? AgingAmountType.PROGRESS_MINUS_RETENTION_HELD
    let agingAmountTypeName: string
    switch (agingAmountType) {
      case AgingAmountType.PROGRESS_MINUS_RETENTION_HELD:
        agingAmountTypeName = ctx.t(
          `reporting_home.filters.aging_amount_types.progress_minus_retention_held`
        )
        break
      case AgingAmountType.RETENTION_RELEASED:
        agingAmountTypeName = ctx.t(`reporting_home.filters.aging_amount_types.retention_released`)
        break
      case AgingAmountType.AMOUNT_DUE:
        agingAmountTypeName = ctx.t(`reporting_home.filters.aging_amount_types.amount_due`)
        break
    }
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.aging_amount_type`))),
      string(agingAmountTypeName),
    ])

    // Payment status filter (if filtering by 'past due')
    const isFilteringByPastDue = ctx.filters.overdueOnly
    const filterValue = isFilteringByPastDue
      ? ctx.t('reporting_home.filters.past_due')
      : ctx.t('reporting_home.filters.all')
    rows.push([bold(string(ctx.t('reporting_home.filters.payment_status'))), string(filterValue)])
  }

  // Billing type filter
  if (exportFrom !== 'forecast' && exportFrom !== 'overview') {
    const billingTypes = ctx.filters.billingType ?? []

    // Switch is exhaustive, but eslint doesn't know that
    const billingTypeNames = billingTypes.map((billingType) => {
      switch (billingType) {
        case BillingType.LUMP_SUM:
          return ctx.t(`reporting_home.filters.project_types.lump_sum`)
        case BillingType.UNIT_PRICE:
          return ctx.t(`reporting_home.filters.project_types.unit_price`)
        case BillingType.TIME_AND_MATERIALS:
          return ctx.t('reporting_home.filters.project_types.time_and_materials')
        case BillingType.QUICK:
          return ctx.t(`reporting_home.filters.project_types.quick_bill`)
      }
    })
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.project_type`))),
      string(billingTypes.length === 0 ? filterAll : billingTypeNames.join(', ')),
    ])
  }

  // Lead PM filter
  const leadPMIds = ctx.filters.leadPMIds ?? []
  const leadPMNames = leadPMIds.map((id) => {
    const found = leadPMs.find((pm) => pm.user.id === id)
    if (!found) {
      return ''
    }
    return `${found.user.firstName} ${found.user.lastName}`
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.lead_pm`))),
    string(leadPMIds.length === 0 ? filterAll : leadPMNames.join(', ')),
  ])

  // Office filter
  const officeIds = ctx.filters.officeIds ?? []
  const locations = ctx.locations
  const officeNames = officeIds.map((id) => {
    const found = locations.find((location) => location.id === id)
    if (!found) {
      return ''
    }
    return getOfficeName(found)
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.office`))),
    string(officeIds.length === 0 ? filterAll : officeNames.join(', ')),
  ])

  // GC filter
  const generalContractorIds = ctx.filters.generalContractorIds ?? []
  const gcNames = generalContractorIds.map((id) => {
    const found = generalContractors.find((gc) => gc.id === id)
    if (!found) {
      return ''
    }
    return found.name
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.gc`))),
    string(generalContractorIds.length === 0 ? filterAll : gcNames.join(', ')),
  ])

  // Project status filter
  if (exportFrom === 'billing') {
    const projectStatuses = ctx.filters.projectStatus ?? []
    // Switch is exhaustive, but eslint doesn't know that
    const projectStatusNames = projectStatuses.map((projectStatus) => {
      switch (projectStatus) {
        case ContractMonthlyStatusFilter.DRAFT_ON_TIME:
          return ctx.t('reporting_home.filters.project_statuses.draft_on_time')
        case ContractMonthlyStatusFilter.NOT_BILLING:
          return ctx.t('reporting_home.filters.project_statuses.not_billing')
        case ContractMonthlyStatusFilter.PAST_DUE:
          return ctx.t('reporting_home.filters.project_statuses.past_due')
        case ContractMonthlyStatusFilter.SUBMITTED:
          return ctx.t('reporting_home.filters.project_statuses.submitted')
      }
    })
    rows.push([
      bold(string(ctx.t('reporting_home.filters.project_status'))),
      string(
        projectStatuses.length === 0 || ignoreProjectStatusFilter
          ? filterAll
          : projectStatusNames.join(', ')
      ),
    ])
  }

  // Search
  if (ctx.search) {
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.search_term`))),
      italic(string(ctx.search)),
    ])
  }

  return rows
}

/**
 * Returns a moment representing the month for which the report is being generated.
 */
export function getViewingDate(ctx: Pick<ExportContext, 'month' | 'year'>): Moment {
  return moment
    .tz(moment.tz.guess())
    .set({
      month: ctx.month,
      year: ctx.year,
    })
    .startOf('month')
}

export function formatExcelDateWithTime(date: Moment): string {
  return date.format('MM/DD/YYYY [at] h:mma z')
}

export function formatExcelDate(date: Moment): string {
  return date.format('MM/DD/YYYY')
}

export function multilineString(text: string): CellObject {
  return {
    v: text,
    t: 's',
    s: {
      alignment: { wrapText: true, vertical: 'top' },
    },
  }
}
