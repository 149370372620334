import { styled, Tab, Tabs } from '@mui/material'
import { colors } from 'siteline-common-web'
import { themeSpacing, TOP_HEADER_HEIGHT } from '../../common/themes/Main'

export const HorizontalTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    minHeight: theme.spacing(9),
    '& .MuiButtonBase-root.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '& .MuiTab-wrapper': {
        pointerEvents: 'none',
      },
    },
  },
  '& .MuiTabs-flexContainer': {
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  '& .MuiTabs-indicator': {
    backgroundColor: colors.brandOrange,
    height: theme.spacing(0.5),
    position: 'absolute',
  },
}))

export const HorizontalTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: theme.spacing(2),
    // Needs to match padding while hovering
    paddingBottom: theme.spacing(2.5),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    height: '100%',
    '&:hover': {
      borderBottom: '4px solid',
      borderBottomColor: colors.grey30,
      marginBottom: '-13px',
      paddingBottom: theme.spacing(2),
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  '&.MuiTab-labelIcon': {
    minHeight: 'auto',
    minWidth: '160px',
    '& *:first-child': {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  '&.MuiTab-textColorPrimary': {
    color: colors.grey50,
    '& svg': {
      fill: colors.grey50,
    },
    '&.Mui-selected': {
      color: colors.grey70,
      '& svg': {
        fill: colors.grey70,
      },
      '& svg:not(.no-fill) path': {
        fill: colors.grey70,
        fillOpacity: 1,
      },
      '& svg path[stroke="black"]': {
        stroke: colors.grey70,
        strokeOpacity: 1,
      },
    },
  },
}))

export const VerticalTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: colors.grey70,
    left: 0,
    right: 'auto',
    width: '4px',
  },
}))

export const VerticalTab = styled(Tab)(() => ({
  '&.MuiTab-root': {
    minWidth: 'auto',
    opacity: 0.75,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    whiteSpace: 'nowrap',
    paddingTop: themeSpacing(1),
    paddingBottom: themeSpacing(1),
    paddingLeft: themeSpacing(2),
    '& > *:first-child': {
      paddingLeft: 6,
      marginRight: themeSpacing(2),
      marginBottom: 0,
    },
    '&.Mui-selected': {
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: colors.grey10,
    },
  },
  '&.MuiTab-labelIcon': {
    minHeight: TOP_HEADER_HEIGHT,
  },
  '&.MuiTab-textColorPrimary': {
    color: colors.grey70,
    '&.Mui-selected': {
      color: colors.grey70,
    },
  },
}))
