import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { DAY_FORMAT, centsToDollars } from 'siteline-common-all'
import {
  amount,
  bold,
  getColumnSizesFromRows,
  leftAlign,
  rightAlign,
  saveAs,
  string,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { CellObject, WorkSheet } from 'xlsx-js-style'
import {
  ContractForCashForecastProperties,
  useContractCashForecastDataLazyQuery,
  useContractsCashForecastDataLazyQuery,
} from '../../../common/graphql/apollo-operations'
import {
  ExportContext,
  getFilterRows,
  getGeneralContractors,
  getLeadPMs,
  getViewingDate,
} from '../../../common/util/Export'
import { currentPayAppAmountDue, isPayAppFullyPaid } from '../../../common/util/PayApp'
import { getGeneralContractorName } from '../../../common/util/Project'

const collator = new Intl.Collator()

const i18nBase = 'reporting_home.cash_forecast'

type SingleCashForecastParams = {
  contract: ContractForCashForecastProperties
  exportType: 'single'
}

type AggregateCashForecastParams = {
  contracts: ContractForCashForecastProperties[]
  exportType: 'aggregate'
}

type CashForecastParams = (SingleCashForecastParams | AggregateCashForecastParams) & {
  ctx: ExportContext
}
const UNKNOWN_PAYMENT_KEY = 'unknown'

// Returns the end of the week from a given date, with Friday used as the end of the week.
function getEndOfWeek(date: Moment) {
  // Since moment js days are 0-indexed from Sunday, Friday is day 5
  const fridayDayOfWeek = 5
  const day = date.day()
  if (day > fridayDayOfWeek) {
    // On Saturday, add 6 days to get the following Friday
    return date.clone().add(6, 'days')
  } else {
    // On all other days up through Friday, use the Friday of the same week
    return date.clone().day(fridayDayOfWeek)
  }
}

// Get weeks matching weeks in common/src/utils/cash-forecast.ts
function getWeekKey(date: Moment) {
  // We want Monday-Sunday weeks, so if the date is Sunday, subtract a day
  // to ensure it gets grouped with the previous week
  if (date.day() === 0) {
    date = date.clone().subtract(1, 'day')
  }
  // The week starts on Sunday. Add a day to get the start of the work week.
  const startDate = date.clone().startOf('week').add(1, 'day').format(DAY_FORMAT)
  // The week ends on Saturday. Add a day because we include the Sat/Sun after the work week
  // ends in the prior period.
  const endDate = date.clone().endOf('week').add(1, 'day').format(DAY_FORMAT)
  return `${startDate}--${endDate}`
}

async function cashForecastDataToWorkSheet({
  ctx,
  ...params
}: CashForecastParams): Promise<WorkSheet> {
  const { t } = ctx
  const rows: CellObject[][] = []

  // Header
  const generationDate = moment.tz(moment.tz.guess())
  rows.push([
    string(
      t(`${i18nBase}.generated_on`, {
        date: generationDate.format('MM/DD/YYYY [at] h:mma z'),
      })
    ),
  ])
  rows.push([])

  // Project summary for an individual project
  if (params.exportType === 'single') {
    const { contract } = params
    rows.push([
      bold(string(t(`${i18nBase}.project_name`))),
      leftAlign(string(contract.project.name)),
    ])
    rows.push([
      bold(string(t(`${i18nBase}.project_number`))),
      leftAlign(string(contract.internalProjectNumber ?? contract.project.projectNumber)),
    ])
    rows.push([
      bold(string(t(`${i18nBase}.gc`))),
      leftAlign(string(getGeneralContractorName(contract.project))),
    ])
    rows.push([])
  }

  // Filters
  if (params.exportType === 'aggregate') {
    const leadPMs = await getLeadPMs(ctx.companyId)
    const generalContractors = await getGeneralContractors(ctx.companyId)
    const filterRows = getFilterRows({
      ctx,
      leadPMs,
      generalContractors,
      exportFrom: 'cashForecast',
    })
    rows.push(...filterRows)
    rows.push([])
  }

  const contracts = params.exportType === 'single' ? [params.contract] : params.contracts

  const outstandingPayApps = _.chain(contracts)
    .flatMap((contract) => contract.payApps)
    .filter((payApp) => !isPayAppFullyPaid(payApp))
    .value()

  const progressOutstanding = _.sumBy(outstandingPayApps, (payApp) =>
    // Take the amount of progress billed on the pay app and subtract any amount already paid. If
    // the difference is less than zero, assume the amount paid is for retention billed and there
    // is no progress outstanding.
    Math.max(payApp.currentBilled - payApp.currentRetention - (payApp.amountPaid ?? 0), 0)
  )
  const retentionOutstanding = _.sumBy(outstandingPayApps, (payApp) =>
    // Take the amount of retention billed on the pay app and subtract any amount already paid. If
    // the difference is less than zero, assume the amount paid is for progress billed and there
    // is no retention outstanding.
    Math.max(payApp.previousRetentionBilled - (payApp.amountPaid ?? 0), 0)
  )
  rows.push([bold(string(t(`${i18nBase}.cash_outstanding`)))])
  rows.push([string(t(`${i18nBase}.progress`)), amount(centsToDollars(progressOutstanding))])
  rows.push([string(t(`${i18nBase}.retention`)), amount(centsToDollars(retentionOutstanding))])
  rows.push([])

  // Contracts table
  const includeContractColumns = params.exportType === 'aggregate'
  rows.push([
    // Aggregate reports have additional columns for contract info
    ..._.times(includeContractColumns ? 4 : 1, () => string('')),
    bold(string(t(`${i18nBase}.week_ending`))),
  ])

  // Find the earliest and latest week with a predicted payment date and generate a list of
  // consecutive weeks across the entire range
  const payAppsByPredictedPaymentWeek = _.chain(outstandingPayApps)
    .groupBy((payApp) =>
      payApp.predictedPaymentDate
        ? // Group pay apps by the monday-sunday week of the predicted payment date
          getWeekKey(moment.tz(payApp.predictedPaymentDate, DAY_FORMAT, payApp.timeZone))
        : UNKNOWN_PAYMENT_KEY
    )
    .value()
  const predictedPaymentWeeks = _.keys(payAppsByPredictedPaymentWeek)
    .filter((key) => key !== UNKNOWN_PAYMENT_KEY)
    .map((weekKey) => {
      // Get a date back out of the week key, then find the Friday of that week
      const weekStart = weekKey.split('--')[0]
      const weekStartMoment = moment.tz(weekStart, DAY_FORMAT, moment.tz.guess())
      const endOfWeek = getEndOfWeek(weekStartMoment)
      return endOfWeek
    })

  // Start with the earliest predicted payment week, or today if there is no earlier week
  const earliestWeek = _.min([...predictedPaymentWeeks, getEndOfWeek(moment.tz(moment.tz.guess()))])
  const latestWeek = _.max(predictedPaymentWeeks)
  const weeks: { weekKey: string; week: Moment }[] = []
  if (earliestWeek && latestWeek) {
    const cursor = earliestWeek.clone()
    while (cursor.isSameOrBefore(latestWeek, 'week')) {
      weeks.push({ weekKey: getWeekKey(cursor), week: cursor.clone() })
      cursor.add(1, 'week')
    }
  }

  const hasUnknownPayments = _.keys(payAppsByPredictedPaymentWeek).includes(UNKNOWN_PAYMENT_KEY)
  const headerRow = [
    ...(includeContractColumns
      ? [
          bold(string(t(`${i18nBase}.project_number`))),
          bold(string(t(`${i18nBase}.project_name`))),
          bold(string(t(`${i18nBase}.gc`))),
        ]
      : []),
    bold(string(t(`${i18nBase}.pay_app_number`))),
    ...weeks.map((week) => bold(string(getEndOfWeek(week.week).format('M/D/YYYY')))),
    ...(hasUnknownPayments ? [bold(string(t(`${i18nBase}.unknown`)))] : []),
  ]
  rows.push(headerRow)

  // Contracts
  const sortedContracts = [...contracts].sort((a, b) => {
    return collator.compare(a.project.name, b.project.name)
  })
  const contractRows = sortedContracts.flatMap((contract) => {
    const payApps = contract.payApps.filter((payApp) => !isPayAppFullyPaid(payApp))
    const sortedPayApps = _.orderBy(payApps, (payApp) => payApp.payAppNumber, 'asc')
    return sortedPayApps.map((payApp, index) => {
      const cells = [
        ...(includeContractColumns && index === 0
          ? [
              leftAlign(string(contract.internalProjectNumber ?? contract.project.projectNumber)),
              leftAlign(string(contract.project.name)),
              leftAlign(string(getGeneralContractorName(contract.project))),
            ]
          : _.times(includeContractColumns ? 3 : 0, () => string(''))),
        leftAlign(string(payApp.payAppNumber.toString())),
      ]

      const paymentWeekKey = payApp.predictedPaymentDate
        ? getWeekKey(moment.tz(payApp.predictedPaymentDate, DAY_FORMAT, payApp.timeZone))
        : null
      const weekColumns = weeks.map((week) => {
        if (paymentWeekKey && paymentWeekKey === week.weekKey) {
          return amount(centsToDollars(currentPayAppAmountDue(payApp)))
        }
        return rightAlign(string('–'))
      })
      cells.push(...weekColumns)

      if (hasUnknownPayments) {
        const unknownCell = paymentWeekKey
          ? rightAlign(string('–'))
          : amount(centsToDollars(currentPayAppAmountDue(payApp)))
        cells.push(unknownCell)
      }

      return cells
    })
  })
  rows.push(...contractRows)

  const unknownTotal =
    hasUnknownPayments && UNKNOWN_PAYMENT_KEY in payAppsByPredictedPaymentWeek
      ? _.sumBy(payAppsByPredictedPaymentWeek[UNKNOWN_PAYMENT_KEY], currentPayAppAmountDue)
      : null
  const totalsRow = [
    ...(includeContractColumns ? _.times(3, () => string('')) : []),
    leftAlign(string(t(`${i18nBase}.total`))),
    ...weeks.map((week) => {
      const weekPayApps =
        week.weekKey in payAppsByPredictedPaymentWeek
          ? payAppsByPredictedPaymentWeek[week.weekKey]
          : null
      if (!weekPayApps || weekPayApps.length === 0) {
        return rightAlign(string('–'))
      }
      const weekTotal = _.sumBy(weekPayApps, currentPayAppAmountDue)
      return amount(centsToDollars(weekTotal))
    }),
    ...(hasUnknownPayments
      ? [_.isNumber(unknownTotal) ? amount(centsToDollars(unknownTotal)) : rightAlign(string('–'))]
      : []),
  ]
  rows.push(totalsRow)

  const { utils } = await import('xlsx-js-style')
  const sheet = utils.json_to_sheet(rows, { skipHeader: true })
  const autoFitRows = [headerRow, ...contractRows, totalsRow]
  sheet['!cols'] = getColumnSizesFromRows(autoFitRows)
  return sheet
}

function getFileName(params: CashForecastParams): string {
  let base = 'siteline-cash-forecast-report-'
  if (params.exportType === 'single') {
    base += params.contract.internalProjectNumber ?? params.contract.project.projectNumber
  } else {
    base += 'aggregate'
  }
  const viewingDate = getViewingDate(params.ctx)
  base += `-${viewingDate.format('MMMYYYY')}`
  return base
}

export async function exportCashForecastDataToCsv(params: CashForecastParams): Promise<void> {
  const { utils } = await import('xlsx-js-style')
  const sheet = await cashForecastDataToWorkSheet(params)
  const csv = utils.sheet_to_csv(sheet)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const filename = `${getFileName(params)}.csv`
  saveAs(blob, filename)
}

export async function exportCashForecastDataDataToXlsx(params: CashForecastParams): Promise<void> {
  const { utils, write } = await import('xlsx-js-style')
  const sheet = await cashForecastDataToWorkSheet(params)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, sheet, 'Cash forecast')
  const out = write(workbook, { type: 'array' })
  const blob = new Blob([out], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  })
  const filename = `${getFileName(params)}.xlsx`
  saveAs(blob, filename)
}

export function useExportCashForecastDataToXlsx(ctx: ExportContext) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [loadContractData] = useContractCashForecastDataLazyQuery()
  const [loadContractsData] = useContractsCashForecastDataLazyQuery()

  const downloadXlsx = async ({ contractId }: { contractId: string | null }) => {
    if (contractId) {
      const { data } = await loadContractData({
        variables: { id: contractId },
      })
      if (!data) {
        snackbar.showError(t('common.errors.snackbar.generic'))
        return
      }
      await exportCashForecastDataDataToXlsx({
        exportType: 'single',
        contract: data.contract,
        ctx,
      })
    } else {
      try {
        const data = await loadContractsData({
          variables: {
            input: {
              companyId: ctx.companyId,
              periodType: ctx.cashForecastPeriod,
              currentDate: moment.tz(moment.tz.guess()).format(DAY_FORMAT),
              search: ctx.search,
              billingTypes: ctx.filters.billingType,
              leadPMIds: ctx.filters.leadPMIds,
              officeIds: ctx.filters.officeIds,
              generalContractorIds: ctx.filters.generalContractorIds,
              limit: 0,
            },
          },
        })
        await exportCashForecastDataDataToXlsx({
          exportType: 'aggregate',
          contracts:
            data.data?.paginatedCashForecastContracts.contracts.flatMap(
              ({ contract }) => contract
            ) ?? [],
          ctx,
        })
      } catch (err) {
        snackbar.showError(err.message)
      }
    }
  }
  return [downloadXlsx] as const
}
