import { Message } from '@ai-sdk/react'
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box, Theme } from '@mui/system'
import clsx from 'clsx'
import { marked } from 'marked'
import { memo, useMemo } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { colors, makeStylesFast } from 'siteline-common-web'
import { RouterLink } from '../../../common/components/RouterLink'

/**
 * Parses markdown into blocks so that can be rendered and memoized separately.
 */
function parseMarkdownIntoBlocks(markdown: string): string[] {
  const tokens = marked.lexer(markdown)
  return tokens.map((token) => token.raw)
}

const useStyles = makeStylesFast((theme: Theme) => ({
  textPart: {
    marginBottom: theme.spacing(2),
    maxWidth: '90%',
    border: `1px solid ${colors.grey20}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2, 2),
  },
  userTextPart: {
    alignSelf: 'flex-end',
    backgroundColor: colors.grey20,
    borderRadius: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: 'none',
  },
  textBlock: {
    '&:not(:first-child):not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))

/**
 * Renders a link from the LLM, with handling of absolute URLs and in-app URLs.
 */
function MarkdownLink({ children, href }: { children: React.ReactNode; href: string | undefined }) {
  const baseUrl = useMemo(() => window.location.origin, [])
  const isLocalLink = useMemo(() => href && href.startsWith(baseUrl), [baseUrl, href])
  const pathname = useMemo(() => {
    if (!href) {
      return ''
    }
    return new URL(href).pathname
  }, [href])

  if (!href) {
    return null
  }

  // URLs from the LLM are absolute. If we were to pass them to the RouterLink directly,
  // it would do a clean load of the page instead of navigating smoothly.
  // If the URL is on the current site, we extract the pathname and pass it to the RouterLink
  // in the `to` prop. Otherwise, we pass the href as is to the RouterLink in the `href` prop,
  // with a `target="_blank"` attribute which opens a new tab.
  if (isLocalLink) {
    return <RouterLink to={pathname}>{children}</RouterLink>
  } else {
    return (
      <RouterLink href={href} target="_blank">
        {children}
      </RouterLink>
    )
  }
}

const markdownComponents: Components = {
  p: ({ children }) => <Typography variant="body1">{children}</Typography>,
  a: ({ children, href }) => <MarkdownLink href={href}>{children}</MarkdownLink>,
  h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
  h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
  h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
  h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
  h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
  h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
  ol: ({ children }) => <Typography component="ol">{children}</Typography>,
  ul: ({ children }) => <Typography component="ul">{children}</Typography>,
  li: ({ children }) => <Typography component="li">{children}</Typography>,
  hr: () => <Divider />,
  blockquote: ({ children }) => (
    <Box
      component="blockquote"
      dir="auto"
      sx={{
        borderInlineStart: '3px solid',
        paddingInlineStart: '1.5rem',
        borderColor: 'text.secondary',
        m: '0.25rem 0',
      }}
    >
      {children}
    </Box>
  ),
  pre: ({ children }) => (
    <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
      {children}
    </Box>
  ),
  span: ({ children }) => <Typography component="span">{children}</Typography>,
  table: ({ children }) => <Table sx={{ my: 2 }}>{children}</Table>,
  tbody: ({ children }) => <TableBody>{children}</TableBody>,
  td: ({ children }) => <TableCell>{children}</TableCell>,
  tfoot: ({ children }) => <TableFooter>{children}</TableFooter>,
  th: ({ children }) => <TableCell>{children}</TableCell>,
  thead: ({ children }) => <TableHead>{children}</TableHead>,
  tr: ({ children }) => <TableRow>{children}</TableRow>,
  strong: ({ children }) => (
    <Box component="span" sx={{ fontWeight: 700 }}>
      {children}
    </Box>
  ),
}

const MarkdownBlock = memo(
  ({ block }: { block: string }) => {
    const classes = useStyles()
    return (
      <Box className={classes.textBlock}>
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>
          {block}
        </ReactMarkdown>
      </Box>
    )
  },
  (prevProps, nextProps) => prevProps.block === nextProps.block
)
MarkdownBlock.displayName = 'MarkdownBlock'

type MemoizedMarkdownProps = {
  content: string
}

/**
 * Renders markdown content in memoized blocks.
 */
const Markdown = memo(
  ({
    // False positive from the linter, prop-types are passed in correctly
    // eslint-disable-next-line react/prop-types
    content,
  }: MemoizedMarkdownProps) => {
    const blocks = useMemo(() => parseMarkdownIntoBlocks(content), [content])
    return blocks.map((block, index) => <MarkdownBlock key={index} block={block} />)
  },
  (prevProps, nextProps) => prevProps.content === nextProps.content
)
Markdown.displayName = 'Markdown'

/**
 * A single part of a message.
 * For instance a part can be a text message, a tool invocation, etc.
 */
export function ChatMessagePart({
  role,
  part,
}: {
  role: Message['role']
  part: Exclude<Message['parts'], undefined>[number]
}) {
  const classes = useStyles()

  // We don't support parts that are not text or tool invocation.
  if (part.type !== 'text') {
    return null
  }

  return (
    <Box className={clsx(classes.textPart, role === 'user' && classes.userTextPart)}>
      <Markdown content={part.text} />
    </Box>
  )
}
