import { Theme } from '@mui/material/styles'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isPayAppLineItemsPayload, isPayAppPayload } from 'siteline-common-all'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { PartialWriteSyncPayload } from './SyncButton'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& ul': {
      display: 'inline-block',
      textAlign: 'left',
      listStyle: 'disc',
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    '& li': {
      marginBottom: theme.spacing(1),
      listStylePosition: 'inside',
    },
  },
}))

interface SyncDialogProps {
  open: boolean
  onClose: () => void
  integration: MinimalIntegrationProperties
  payload: PartialWriteSyncPayload
  names: string[]
  onClick: () => void
}

export function SyncConfirmationDialog({
  open,
  onClose,
  integration,
  payload,
  names,
  onClick,
}: SyncDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const integrationName = integration.shortName

  const onSyncClick = useCallback(() => {
    onClick()
  }, [onClick])

  const syncNames = useMemo(() => {
    const showNames = [...names]
    if (isPayAppPayload(payload.type) || isPayAppLineItemsPayload(payload.type)) {
      showNames.unshift(t('integrations.confirmation_dialog.invoice_name'))
    }
    return showNames
  }, [names, payload.type, t])

  const title = useMemo(() => {
    switch (payload.type) {
      case 'payAppTextura':
      case 'payAppGcPay':
      case 'payAppProcore':
      case 'payAppSage100':
      case 'payAppFoundation':
      case 'payAppQuickbooks':
      case 'payAppFoundationFileGenie':
      case 'payAppFoundationFileFsi':
      case 'payAppComputerEase':
      case 'payAppManual':
      case 'payAppCmic':
        return t('integrations.confirmation_dialog.title.pay_app', { integrationName })
      case 'payAppLineItemsSage300':
      case 'payAppLineItemsSpectrum':
      case 'payAppLineItemsVista':
      case 'payAppLineItemsAcumatica':
      case 'payAppLineItemsSageIntacct':
        return t('integrations.confirmation_dialog.title.pay_app_line_items', { integrationName })
      case 'lienWaivers':
        return t('integrations.confirmation_dialog.title.lien_waivers', { integrationName })
      case 'legalRequirement':
        return t('integrations.confirmation_dialog.title.legal_requirement', { integrationName })
    }
  }, [integrationName, payload.type, t])

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      title={title}
      submitLabel={t('integrations.button.sync')}
      subscript={t('integrations.confirmation_dialog.description')}
      onSubmit={onSyncClick}
      size="email"
    >
      <div className={classes.root}>
        <div>
          <SitelineText variant="body1">
            {t('integrations.confirmation_dialog.sub_title', { integrationName })}
          </SitelineText>
        </div>
        <ul>
          {syncNames.map((name, index) => {
            return (
              <SitelineText variant="body1" bold key={index}>
                <li>{name}</li>
              </SitelineText>
            )
          })}
        </ul>
      </div>
    </SitelineDialog>
  )
}
