import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { Button, ButtonProps, Theme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationTypeFamily, getIntegrationTypeFamily } from 'siteline-common-all'
import { SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../../common/contexts/ProjectContext'
import {
  Permission,
  useSovChangeSetFromIntegrationLazyQuery,
} from '../../../../common/graphql/apollo-operations'
import { getIntegrationForReadLineItems } from '../../../../common/util/Integration'
import { isPayAppCompleted, isUnpaidPayAppResettable } from '../../../../common/util/PayApp'
import { PayAppForBackup } from '../../backup/PayAppBackup'
import { PreviewSovChangeSetDialog } from '../../home/PreviewSovChangeSetDialog'
import { OnboardingButton } from '../../onboarding/OnboardingButton'
import { EMPTY_CHANGE_SET } from '../../sync/SyncButton'
import { PayAppForProgress } from '../LumpSumPayAppInvoice'
import { ResetPayAppToDraftDialog } from '../ResetPayAppToDraftDialog'
import { AddOrEditChangeOrderDialog } from './AddOrEditChangeOrderDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  addButton: {
    marginLeft: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
  buttons: {
    display: 'flex',
    margin: theme.spacing(0, -1),
    '& > *': {
      flex: 1,
      margin: theme.spacing(0, 1),
    },
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.invoice.add_change_order'

type AddButtonProps = ButtonProps & {
  payApp: PayAppForBackup | PayAppForProgress
  onChangeOrdersAdded?: () => void
}

/** Button for adding new change orders either manually or through import from an integration. */
export function AddChangeOrderButton({
  payApp,
  onChangeOrdersAdded,
  variant,
  color,
}: AddButtonProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false)
  const [selectDialogOpen, setSelectDialogOpen] = useState<boolean>(false)
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false)
  const { contract } = useProjectContext()
  const readIntegration = contract ? getIntegrationForReadLineItems(contract) : undefined
  const [getSovChangeSetFromIntegration, { data: changeSetData, loading: loadingChangeSet }] =
    useSovChangeSetFromIntegrationLazyQuery({
      fetchPolicy: 'cache-and-network',
    })

  const changeSet = useMemo(
    () => changeSetData?.sovChangeSetFromIntegration ?? EMPTY_CHANGE_SET,
    [changeSetData?.sovChangeSetFromIntegration]
  )

  const { permissions } = useCompanyContext()
  const canEdit =
    !isPayAppCompleted(payApp.status) && permissions.includes(Permission.EDIT_CHANGE_ORDER)

  let icon = <AddIcon />
  let label = t(`${i18nBase}.quick_add_change_order`)
  let onlyChangeOrders = true
  const readIntegrationFamily = readIntegration && getIntegrationTypeFamily(readIntegration.type)
  if (readIntegration) {
    switch (readIntegrationFamily) {
      case IntegrationTypeFamily.GC_PORTAL: {
        // The button text will read "import SOV from {integration}" rather than "import change orders".
        // We always import the full SOV for GC portals, because the full SOVs need to stay in sync between the two.
        label = t('projects.subcontractors.pay_app.invoice.import_sov.import_from', {
          integrationName: readIntegration.shortName,
        })
        icon = <SyncIcon />
        onlyChangeOrders = false
        break
      }
      case IntegrationTypeFamily.ERP:
        // If the project has an ERP integration, clicking this button will open a dialog which presents
        // options to import change orders from the ERP or add manually. Because of the multi-step process, we
        // aren't labeling this "quick add". Note: for ERPs we only import change orders here, not the whole SOV
        label = t(`${i18nBase}.add_change_order`)
        break
      case undefined:
        break
    }
  }

  const styledLabel =
    variant === 'text' ? (
      <SitelineText variant="secondary" bold>
        {label}
      </SitelineText>
    ) : (
      label
    )

  const handlePreviewChanges = useCallback(async () => {
    if (!readIntegration) {
      return
    }
    snackbar.showLoading(
      t(`${i18nBase}.sync.loading`, {
        integrationName: readIntegration.shortName,
      })
    )
    try {
      const { data, error } = await getSovChangeSetFromIntegration({
        variables: {
          input: {
            integrationId: readIntegration.id,
            onlyChangeOrders: readIntegrationFamily === IntegrationTypeFamily.ERP,
          },
        },
      })

      // If call succeeds, changeset is either null (no changes), or not null (changes exist)
      if (data) {
        if (data.sovChangeSetFromIntegration) {
          snackbar.closeAll()
          setPreviewDialogOpen(true)
        } else {
          const noChangesMessage = t(`${i18nBase}.sync.success_no_changes`, {
            integrationName: readIntegration.shortName,
          })
          snackbar.showSuccess(noChangesMessage)
        }

        // If there's an error from the API, show it
      } else if (error) {
        snackbar.showError(error.message)

        // This should not happen but we handle it just in case
      } else {
        snackbar.showError(t('common.errors.snackbar.generic'))
      }
    } catch {
      snackbar.showError(t('common.errors.snackbar.generic'))
    }
  }, [getSovChangeSetFromIntegration, readIntegration, readIntegrationFamily, snackbar, t])

  const onClick = useCallback(() => {
    if (isUnpaidPayAppResettable(payApp.status)) {
      setResetDialogOpen(true)
    } else if (readIntegration) {
      switch (readIntegrationFamily) {
        case IntegrationTypeFamily.GC_PORTAL:
          handlePreviewChanges()
          break
        case IntegrationTypeFamily.ERP:
          setSelectDialogOpen(true)
          break
        case undefined:
          break
      }
    } else {
      setAddDialogOpen(true)
    }
  }, [handlePreviewChanges, payApp.status, readIntegration, readIntegrationFamily])

  const onResetClose = useCallback(
    (success: boolean) => {
      setResetDialogOpen(false)
      if (success) {
        if (readIntegration) {
          handlePreviewChanges()
        } else {
          setAddDialogOpen(true)
        }
      }
    },
    [handlePreviewChanges, readIntegration]
  )

  const taxGroups = useMemo(
    // We don't filter out archived tax groups here since an archived tax group may already be used.
    // Instead they're filtered out inside the dialog dropdown.
    () => [...payApp.contract.company.taxGroups],
    [payApp.contract.company.taxGroups]
  )

  if (!canEdit) {
    return null
  }

  return (
    <>
      <Button
        variant={variant}
        color={color}
        startIcon={icon}
        onClick={onClick}
        className={classes.addButton}
      >
        {styledLabel}
      </Button>

      <AddOrEditChangeOrderDialog
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        payApp={payApp}
        onChangeOrdersAdded={onChangeOrdersAdded}
        taxCalculationType={payApp.contract.taxCalculationType}
        taxGroups={taxGroups}
      />

      {readIntegration && readIntegrationFamily === IntegrationTypeFamily.ERP && (
        <SitelineDialog
          open={selectDialogOpen}
          onClose={() => setSelectDialogOpen(false)}
          title=""
          actionsLayout="closeIcon"
          maxWidth="sm"
        >
          <div className={classes.buttons}>
            <OnboardingButton
              title={t(`${i18nBase}.import_line_items`)}
              imageSrc={<SyncIcon />}
              subtitle={readIntegration.shortName}
              onClick={() => {
                setSelectDialogOpen(false)
                handlePreviewChanges()
              }}
            />
            <OnboardingButton
              title={t(`${i18nBase}.add_manually`)}
              imageSrc={<AddIcon />}
              subtitle={t(`${i18nBase}.enter_co_details`)}
              onClick={() => {
                setSelectDialogOpen(false)
                setAddDialogOpen(true)
              }}
            />
          </div>
        </SitelineDialog>
      )}

      <ResetPayAppToDraftDialog open={resetDialogOpen} onClose={onResetClose} payApp={payApp} />
      {readIntegration && (
        <PreviewSovChangeSetDialog
          open={previewDialogOpen}
          onClose={() => setPreviewDialogOpen(false)}
          integration={readIntegration}
          onlyChangeOrders={onlyChangeOrders}
          changeSet={changeSet}
          loading={loadingChangeSet}
          payAppId={payApp.id}
        />
      )}
    </>
  )
}
