import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonLabelSpinner,
  SitelineText,
  SitelineTooltip,
  SitelineVariant,
  colors,
  makeStylesFast,
} from 'siteline-common-web'

export const STANDARD_FIXED_DIALOG_TOP = 100

const useStyles = makeStylesFast((theme: Theme) => ({
  dialog: {
    '&.emailSize': {
      '& .MuiDialog-paper': {
        maxWidth: 720,
      },
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
      color: colors.grey90,
      '&.Mui-disabled': {
        color: colors.grey50,
      },
      '&:not(.Mui-disabled):not(.Mui-error)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.grey30,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.grey40,
        },
      },
    },
    '& .MuiDialogTitle-root': {
      padding: theme.spacing(4, 5, 3),
      '& .subtitle': {
        marginTop: theme.spacing(1.5),
      },
      '& .supertitle': {
        display: 'block',
        marginBottom: theme.spacing(1),
      },
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0, 5, 1),
      '&:not(.isScrollable)': {
        overflow: 'hidden',
      },
    },
    '&.hideActions .MuiDialogContent-root': {
      paddingBottom: theme.spacing(5.5),
    },
    '& .actions': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 5, 4),
      marginTop: theme.spacing(2),
      '& .buttons': {
        display: 'flex',
        '& button': {
          whiteSpace: 'nowrap',
        },
        '& .MuiButton-outlinedSecondary': {
          borderColor: colors.grey30,
        },
        '& .paddedButton': {
          marginRight: theme.spacing(2),
        },
      },
    },
    '& fieldset': {
      // If the default border styling isn't overridden, it sometimes causes a doubled
      // border on only the side and bottom
      borderWidth: '1px !important',
    },
    '& .isDestructive': {
      backgroundColor: colors.red50,
      '&.Mui-disabled': {
        backgroundColor: colors.red30,
      },
    },
    '& .additionalButton': {
      marginRight: theme.spacing(2),
    },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  hasFixedTopPosition: {
    position: 'absolute',
  },
}))

export interface SitelineDialogProps extends Omit<DialogProps, 'title'> {
  open: boolean
  onClose?: (fromButton: boolean) => void

  /**
   * This is separate from passing undefined to `onClose` because it still allows for closing
   * via backdrop click, if not disabled
   */
  hideCloseButton?: boolean

  /** If not provided, will show only close and no submit button */
  onSubmit?: () => void

  title: string | ReactNode
  subtitle?: string | ReactNode
  subtitleVariant?: SitelineVariant
  subscript?: string | ReactNode
  supertitle?: string | ReactNode
  /**
   * If 'actionsRow' (default), the submit and cancel buttons will be displayed in a row at the bottom of the dialog.
   * If 'closeIcon', the submit and cancel buttons are hidden and a close icon is displayed in the top right corner.
   * If 'hideActions', the submit and cancel buttons are hidden and the dialog can only be closed by clicking the backdrop.
   * In all cases, the dialog can be closed by clicking the backdrop unless otherwise specified by `disableBackdrop`.
   *  @default 'actionsRow'
   **/
  actionsLayout?: 'actionsRow' | 'closeIcon' | 'hideActions'

  cancelLabel?: string
  cancelVariant?: 'outlined' | 'text'
  canceling?: boolean
  submitLabel?: string
  disableSubmit?: boolean
  /**
   * If provided, a tooltip will be shown when hovering over the submit button while `disableSubmit`
   * is true.
   */
  disableSubmitTooltip?: string
  submitting?: boolean
  submitButtonColor?: 'primary' | 'destructive'
  submitButton?: ReactNode

  /** Extra button rendered between the close and submit buttons */
  additionalButton?: ReactNode

  /** Material-UI removed the original properties on Dialog, so we use these with onClose instead */
  disableBackdrop?: boolean
  disableEscapeKey?: boolean

  /** If true, will disable the close button and disable closing via backdrop or escape key */
  disableClose?: boolean

  /** Disables scrolling on the dialog content container */
  disableContentScroll?: boolean

  /**
   * If a number is provided, the dialog will have a fixed top and not be vertically centered
   * on the screen. This is useful when you don't want the dialog to jump around when the content changes.
   */
  fixedTopPosition?: number

  /** Email is a custom size between the standard sm and md */
  size?: 'default' | 'email'

  /** Class applied to the popover */
  popoverClassName?: string

  onResetDialog?: () => void
}

/** Standard dialog with some custom styling for consistency */
export function SitelineDialog({
  open,
  onClose,
  hideCloseButton,
  onSubmit,
  submitButton,
  submitButtonColor,
  title,
  subtitle,
  subtitleVariant = 'h4',
  subscript,
  supertitle,
  submitLabel,
  cancelLabel,
  cancelVariant = 'outlined',
  disableSubmit,
  disableSubmitTooltip = '',
  disableClose,
  disableBackdrop = disableClose,
  disableEscapeKey = disableClose,
  disableContentScroll = false,
  submitting,
  canceling,
  additionalButton,
  size = 'default',
  fixedTopPosition,
  className,
  onResetDialog,
  children,
  actionsLayout = 'actionsRow',
  popoverClassName,
  ...props
}: PropsWithChildren<SitelineDialogProps>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const defaultCancelLabel = onSubmit ? t('common.actions.cancel') : t('common.actions.close')

  return (
    <Dialog
      fullWidth
      maxWidth={props.maxWidth ?? 'md'}
      // Don't restore focus when the dialog closes, as it may cause the page to
      // scroll to the last focused button
      disableRestoreFocus
      open={open}
      onClose={(event, reason) => {
        if (disableBackdrop && reason === 'backdropClick') {
          return
        }
        if (disableEscapeKey && reason === 'escapeKeyDown') {
          return
        }
        if (onClose) {
          onClose(false)
        }
      }}
      className={clsx(classes.dialog, className, {
        emailSize: size === 'email',
        hideActions: actionsLayout !== 'actionsRow',
      })}
      PaperProps={{
        className: clsx(popoverClassName, {
          [classes.hasFixedTopPosition]: fixedTopPosition !== undefined,
        }),
        style:
          fixedTopPosition !== undefined
            ? {
                top: fixedTopPosition,
                // Avoid the dialog going all the way to the bottom of the screen when the content
                // is larger than the available height
                maxHeight: `calc(90% - ${fixedTopPosition}px)`,
              }
            : undefined,
      }}
      TransitionProps={{
        onEnter: onResetDialog,
        onExited: onResetDialog,
      }}
      {...props}
    >
      {actionsLayout === 'closeIcon' && onClose && (
        <IconButton
          color="secondary"
          size="small"
          onClick={() => onClose(false)}
          className={classes.close}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle component="div">
        {typeof supertitle === 'string' ? (
          <SitelineText variant="h5" color="grey50" className="supertitle">
            {supertitle}
          </SitelineText>
        ) : (
          supertitle
        )}
        {typeof title === 'string' ? (
          <SitelineText variant="h2" bold>
            {title}
          </SitelineText>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <SitelineText variant={subtitleVariant} color="grey50" className="subtitle">
            {subtitle}
          </SitelineText>
        ) : (
          subtitle
        )}
      </DialogTitle>
      <DialogContent className={clsx({ isScrollable: !disableContentScroll })}>
        {children}
      </DialogContent>
      {actionsLayout === 'actionsRow' && (
        <DialogActions className="actions">
          <div>
            {typeof subscript === 'string' ? (
              <SitelineText variant="body1" color="grey50">
                {subscript}
              </SitelineText>
            ) : (
              subscript
            )}
          </div>
          <div className="buttons">
            {onClose && !hideCloseButton && (
              <Button
                onClick={(evt) => {
                  evt.stopPropagation()
                  onClose(true)
                }}
                variant={cancelVariant}
                color="secondary"
                className={clsx({
                  paddedButton: submitButton || onSubmit || additionalButton,
                })}
                disabled={disableClose}
              >
                <SitelineText
                  variant="button"
                  color={cancelVariant === 'outlined' ? 'grey70' : 'grey50'}
                  startIcon={canceling ? <ButtonLabelSpinner /> : undefined}
                >
                  {cancelLabel ?? defaultCancelLabel}
                </SitelineText>
              </Button>
            )}
            {additionalButton && <div className="additionalButton">{additionalButton}</div>}
            {submitButton ||
              (onSubmit && (
                <SitelineTooltip
                  title={disableSubmit ? disableSubmitTooltip : ''}
                  placement="top-end"
                >
                  <div>
                    <Button
                      disabled={disableSubmit || submitting}
                      startIcon={submitting ? <ButtonLabelSpinner /> : undefined}
                      onClick={(evt) => {
                        evt.stopPropagation()
                        onSubmit()
                      }}
                      variant="contained"
                      color="primary"
                      className={clsx({ isDestructive: submitButtonColor === 'destructive' })}
                    >
                      {submitLabel ?? t('common.actions.submit')}
                    </Button>
                  </div>
                </SitelineTooltip>
              ))}
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}
