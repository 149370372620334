import CheckIcon from '@mui/icons-material/Check'
import LockIcon from '@mui/icons-material/Lock'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { Prompt } from '../../../common/components/Prompt'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '& .greyButton': {
      color: colors.grey50,
    },
    '& .saveButton': {
      marginLeft: theme.spacing(3),
    },
  },
}))

interface BulkSaveProps {
  onSave: () => Promise<void>
  onCancel: () => void
  hasEdited: boolean
  confirmSaveTitle?: string
  confirmSaveMessage?: string
}

interface SettingsHeaderProps {
  title: string
  canEdit: boolean
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  isSaving?: boolean
  disableSave?: boolean
  endIcon?: ReactNode

  /**
   * There are 2 types of settings sections:
   *  1. The clicks "edit", makes changes, and those changes aren't saved until the user clicks "save"
   *     In this case, bulkSaveProps should be defined
   *  2. The user clicks "edit" which opens up the editing UI, but changes are saved as soon as they're
   *     made. The user clicks "done" to exit the editing mode, but there is not option to cancel changes
   *     In this case, bulkSaveProps is null
   */
  bulkSaveProps: BulkSaveProps | null
}

/** Displays a header on the project settings page, with edit, cancel, and save buttons. */
export function SettingsHeader({
  title,
  canEdit,
  isEditing,
  setIsEditing,
  disableSave,
  isSaving,
  bulkSaveProps,
  endIcon,
}: SettingsHeaderProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()

  const onSave = bulkSaveProps?.onSave
  const onCancel = bulkSaveProps?.onCancel
  const hasEdited = bulkSaveProps?.hasEdited
  const confirmSaveTitle = bulkSaveProps?.confirmSaveTitle
  const confirmSaveMessage = bulkSaveProps?.confirmSaveMessage

  // If the user is in a section where changes are saved as they go,
  // handleConfirmChanges allows them to exit the editing mode
  const handleConfirm = useCallback(() => {
    setIsEditing(false)
  }, [setIsEditing])

  // If the user has made edits and will save all edits at once, this callback is used
  const handleSave = useCallback(() => {
    if (!bulkSaveProps || !onCancel) {
      return
    }
    if (!hasEdited) {
      setIsEditing(false)
      onCancel()
      return
    }
    confirm({
      title: confirmSaveTitle ?? t('projects.subcontractors.settings.confirm.title'),
      details: confirmSaveMessage ?? t('projects.subcontractors.settings.confirm.details'),
      maxWidth: 'sm',
      callback: async (confirmed: boolean) => {
        if (!confirmed) {
          return
        }
        if (onSave) {
          await onSave()
        }
        setIsEditing(false)
      },
    })
  }, [
    bulkSaveProps,
    confirm,
    confirmSaveMessage,
    confirmSaveTitle,
    hasEdited,
    onCancel,
    onSave,
    setIsEditing,
    t,
  ])

  const handleCancelClick = useCallback(() => {
    setIsEditing(false)
    if (onCancel) {
      onCancel()
    }
  }, [onCancel, setIsEditing])

  return (
    <>
      <Prompt
        when={bulkSaveProps && hasEdited !== undefined ? hasEdited : false}
        message={t('projects.subcontractors.settings.navigate_away')}
      />
      <div className={classes.root}>
        <SitelineText variant="h3" bold color="grey90" endIcon={endIcon}>
          {title}
        </SitelineText>
        {canEdit && !isEditing && (
          <Button
            variant="text"
            color="secondary"
            className="greyButton"
            onClick={() => setIsEditing(true)}
          >
            {t('common.actions.edit')}
          </Button>
        )}
        {!canEdit && (
          <SitelineText variant="body1" color="grey50" startIcon={<LockIcon fontSize="small" />}>
            {t('projects.subcontractors.settings.no_permission')}
          </SitelineText>
        )}
        {isEditing && (
          <Row alignItems="center">
            {bulkSaveProps && (
              <Button
                variant="text"
                color="secondary"
                className="greyButton"
                onClick={handleCancelClick}
              >
                {t('common.actions.cancel')}
              </Button>
            )}
            {bulkSaveProps && (
              <LoadingButton
                variant="contained"
                className="saveButton"
                color="primary"
                onClick={handleSave}
                disabled={disableSave || isSaving}
                loading={isSaving ?? false}
                loadingPosition="start"
                startIcon={<CheckIcon />}
              >
                {t('common.actions.save')}
              </LoadingButton>
            )}
            {bulkSaveProps === null && (
              <LoadingButton
                variant="contained"
                className="saveButton"
                color="primary"
                onClick={handleConfirm}
                disabled={disableSave || isSaving}
                loading={isSaving ?? false}
                loadingPosition="start"
                startIcon={<CheckIcon />}
              >
                {t('common.actions.done')}
              </LoadingButton>
            )}
          </Row>
        )}
      </div>
    </>
  )
}
