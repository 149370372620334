import { Divider, TextField, styled } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TAX_RATE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import { NumericFormatWithForwardRef } from '../../../../common/components/NumberFormat'
import { taxGroupPercentToDecimal } from '../../../../common/util/TaxGroup'
import { CompanyIntegrationForAddTaxGroups } from './AddCompanyTaxGroupsDialog'
import { SelectTaxGroupIntegration } from './SelectTaxGroupIntegration'

const StyledAddOrEditTaxGroupForm = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '& .row': {
    display: 'flex',
    alignItems: 'center',
    '& .label': {
      width: 150,
      '&.invalidName': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  '& .percentInput': {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey30}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    height: 39,
    ...theme.typography.body1,
    '&:active, &:focus': {
      outline: 'none',
    },
  },
  '& .input': {
    flexGrow: 1,
  },
}))

const i18nBase = 'projects.subcontractors.taxes'

interface AddOrEditTaxGroupFormProps {
  taxName: string
  onTaxNameChange: (name: string) => void
  taxPercent: number | null
  onTaxPercentChange: (rate: number | null) => void
  integrationTaxGroupIds: Record<string, string>
  onIntegrationTaxGroupIdsChange: (ids: Record<string, string>) => void
  /** Map of company integration IDs to the selected integration tax group IDs */
  integrations: CompanyIntegrationForAddTaxGroups[]
  /** Name is invalid if the name field matches an existing tax group name */
  isNameValid: boolean
  autoFocus?: boolean
}

/** Form for creating or editing a tax group */
export function AddOrEditTaxGroupForm({
  taxName,
  onTaxNameChange,
  taxPercent,
  onTaxPercentChange,
  integrationTaxGroupIds,
  onIntegrationTaxGroupIdsChange,
  integrations,
  isNameValid,
  autoFocus,
}: AddOrEditTaxGroupFormProps) {
  const { t } = useTranslation()

  const handleChangePercent = useCallback(
    (value: number | undefined) => {
      const decimalPercent = value !== undefined ? taxGroupPercentToDecimal(value) : null
      onTaxPercentChange(decimalPercent)
    },
    [onTaxPercentChange]
  )

  const handleSelectIntegrationTaxGroupId = useCallback(
    (integrationTaxGroupId: string | null, companyIntegrationId: string) =>
      onIntegrationTaxGroupIdsChange(
        integrationTaxGroupId
          ? {
              ...integrationTaxGroupIds,
              [companyIntegrationId]: integrationTaxGroupId,
            }
          : _.omit(integrationTaxGroupIds, companyIntegrationId)
      ),
    [integrationTaxGroupIds, onIntegrationTaxGroupIdsChange]
  )

  return (
    <StyledAddOrEditTaxGroupForm>
      <div className="row">
        <SitelineText
          variant="body1"
          color="grey50"
          className={clsx('label', { invalidName: !isNameValid })}
        >
          {t(`${i18nBase}.name`)}
        </SitelineText>
        <TextField
          variant="outlined"
          value={taxName}
          onChange={(evt) => onTaxNameChange(evt.currentTarget.value)}
          autoFocus={autoFocus}
          error={!isNameValid}
          helperText={isNameValid ? '' : t(`${i18nBase}.already_exists`)}
          className="input"
        />
      </div>
      <div className="row">
        <SitelineText variant="body1" color="grey50" className="label">
          {t(`${i18nBase}.tax_rate_percent`)}
        </SitelineText>
        <NumericFormatWithForwardRef
          value={
            taxPercent !== null ? decimalToPercent(taxPercent, TAX_RATE_PERCENT_PRECISION) : ''
          }
          onValueChange={({ floatValue }) => handleChangePercent(floatValue)}
          decimalScale={TAX_RATE_PERCENT_PRECISION}
          displayType="input"
          thousandSeparator={true}
          suffix="%"
          allowNegative={false}
          className={clsx('percentInput', 'input')}
        />
      </div>
      {integrations.length > 0 && (
        <>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <SitelineText variant="h4" color="grey50">
            {t(`${i18nBase}.link_tax_group`)}
          </SitelineText>
          {integrations.map((integration) => (
            <SelectTaxGroupIntegration
              key={integration.id}
              companyIntegration={integration}
              selectedIntegrationTaxGroupId={_.get(integrationTaxGroupIds, integration.id, null)}
              onSelectIntegrationTaxGroupId={handleSelectIntegrationTaxGroupId}
              sitelineTaxPercent={taxPercent}
            />
          ))}
        </>
      )}
    </StyledAddOrEditTaxGroupForm>
  )
}
