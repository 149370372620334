import { Link } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import LogoGrey from '../../assets/images/logo/grey.svg'
import { AI_CHAT_ENABLED_STORAGE_KEY } from '../../components/reporting/chat/ChatSidebar'
import { useUserContext } from '../contexts/UserContext'
import { SITELINE_FOOTER_HEIGHT, Z_INDEX } from '../themes/Main'
import { useLocalStorage } from '../util/SafeLocalStorage'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    // Override any padding set by parent containers
    padding: `${theme.spacing(2, 3, 2)} !important`,
    height: SITELINE_FOOTER_HEIGHT,
    zIndex: Z_INDEX.sitelineFooter,
    '& .footer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `1px solid ${colors.grey20}`,
      paddingTop: theme.spacing(2),
      '& .logo': {
        height: 12,
      },
      '& .links': {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          marginLeft: theme.spacing(3),
        },
      },
    },
  },
}))

const termsUrl = 'https://www.siteline.com/terms'
const privacyUrl = 'https://www.siteline.com/privacy'

interface SitelineFooterProps {
  backgroundColor?: string
  className?: string
}

/** Footer with the Siteline wordmark and a link to privacy & terms */
export function SitelineFooter({ backgroundColor, className }: SitelineFooterProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { email } = useUserContext()
  const snackbar = useSitelineSnackbar()
  const [logoClickCount, setLogoClickCount] = useState(0)
  const [isAiChatEnabledString, setIsAiChatEnabledString] = useLocalStorage(
    AI_CHAT_ENABLED_STORAGE_KEY,
    JSON.stringify(false)
  )
  const isAiChatEnabled = useMemo(
    () => JSON.parse(isAiChatEnabledString) as boolean,
    [isAiChatEnabledString]
  )
  const setAiChatEnabled = useCallback(
    (enabled: boolean) => {
      setIsAiChatEnabledString(JSON.stringify(enabled))
    },
    [setIsAiChatEnabledString]
  )

  const style = useMemo(() => ({ backgroundColor }), [backgroundColor])

  const handleLogoClick = useCallback(() => {
    const canEnableAiChat = email.endsWith('@siteline.com')
    if (!canEnableAiChat) {
      return
    }
    setLogoClickCount((count) => count + 1)
  }, [email])

  // Whenever the Siteline logo is clicked 7 times, toggle the AI chat
  useEffect(() => {
    if (logoClickCount !== 7) {
      return
    }
    setLogoClickCount(0)
    const shouldEnable = !isAiChatEnabled
    setAiChatEnabled(shouldEnable)
    if (shouldEnable) {
      snackbar.showInfo(t('ai_assistant.enabled'))
    } else {
      snackbar.showInfo(t('ai_assistant.disabled'))
    }
  }, [isAiChatEnabled, logoClickCount, setAiChatEnabled, snackbar, t])

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <div className="footer">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img src={LogoGrey} alt="Siteline" className="logo" onClick={handleLogoClick} />
        <div className="links">
          <Link underline="hover" href={termsUrl} target="_blank" rel="noopener noreferrer">
            <SitelineText variant="label" color="grey50" bold>
              {t('footer.terms_of_service')}
            </SitelineText>
          </Link>
          <Link underline="hover" href={privacyUrl} target="_blank" rel="noopener noreferrer">
            <SitelineText variant="label" color="grey50" bold>
              {t('footer.privacy_policy')}
            </SitelineText>
          </Link>
        </div>
      </div>
    </div>
  )
}
