import { useNavigate } from '@tanstack/react-router'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getIntegrationTypeFamily,
  IntegrationTypeFamily,
  integrationTypes,
} from 'siteline-common-all'
import { evictWithGc, getIntegrationWebsite, useSitelineSnackbar } from 'siteline-common-web'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  IntegrationSyncResultCode,
  MinimalIntegrationProperties,
  useMarkPayAppAsSyncedInErpMutation,
  useMarkPayAppAsSyncedInGcPortalMutation,
  useUpdateRoundRetentionMutation,
} from '../../../common/graphql/apollo-operations'
import { FailedOperation } from '../../../common/util/Integration'
import { trackMarkAsSubmitted } from '../../../common/util/MetricsTracking'
import { BaseMetricsPayApp, getMetricsForPayApp } from '../../../common/util/PayApp'
import { BillingPathType, BillingTab, getBillingPath, PayAppTab } from '../Billing.lib'
import {
  WriteSyncResultScreen,
  WriteSyncResultScreenAction,
  WriteSyncResultScreenText,
} from './WriteSyncResultScreen'

interface WriteSyncFailureScreenProps {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload

  // Result if operation was resolved
  operation: FailedOperation | null

  // API error, network error, etc
  error: Error | null

  onClose: () => void

  onSyncAgain: () => void

  /** If a back action is provided, it will be shown instead of a sync again button */
  onBack?: () => void
}

const i18nBase = 'integrations.failure'

/** Screen shown when a sync request comes back from the server (success or fail). */
export function WriteSyncFailureScreen({
  integration,
  projectId,
  payload,
  operation,
  error,
  onClose,
  onBack,
  onSyncAgain,
}: WriteSyncFailureScreenProps) {
  const { t } = useTranslation()
  const integrationName = integration.shortName
  const thirdPartyUrl = getIntegrationWebsite(integration)
  const [markPayAppAsSyncedInGcPortal] = useMarkPayAppAsSyncedInGcPortalMutation()
  const [markPayAppAsSyncedInErp] = useMarkPayAppAsSyncedInErpMutation()
  const [updateRoundRetention] = useUpdateRoundRetentionMutation()
  const project = useProjectContext()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()

  const subTitle = useMemo((): string => {
    switch (payload.type) {
      case 'payAppTextura':
      case 'payAppGcPay':
      case 'payAppProcore':
      case 'payAppSage100':
      case 'payAppFoundation':
      case 'payAppManual':
      case 'payAppLineItemsSage300':
      case 'payAppLineItemsSpectrum':
      case 'payAppLineItemsVista':
      case 'payAppLineItemsAcumatica':
      case 'payAppLineItemsSageIntacct':
      case 'payAppQuickbooks':
      case 'payAppFoundationFileGenie':
      case 'payAppFoundationFileFsi':
      case 'payAppComputerEase':
      case 'payAppCmic':
        return t(`${i18nBase}.sub_title.pay_app`)
      case 'legalRequirement': {
        const requirementName = operation?.legalRequirement?.name ?? ''
        return t(`${i18nBase}.sub_title.legal_requirement`, { requirementName })
      }
      case 'lienWaivers': {
        const vendorName = operation?.lienWaivers[0].vendorContract?.vendor.name ?? ''
        return t(`${i18nBase}.sub_title.lien_waiver`, { vendorName })
      }
    }
  }, [operation?.legalRequirement?.name, operation?.lienWaivers, payload.type, t])

  const errorDetails = {
    description: operation?.result.error ?? error?.message ?? '',
    subTitle,
  }

  const markAsSynced = useCallback(
    (payApp: BaseMetricsPayApp) => {
      const mutation =
        getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.GC_PORTAL
          ? markPayAppAsSyncedInGcPortal
          : markPayAppAsSyncedInErp
      mutation({
        variables: {
          input: {
            payAppId: payApp.id,
            integrationId: integration.id,
            syncedAt: moment.utc().toISOString(),
          },
        },
        update(cache, { data }) {
          if (!data || !project.contract) {
            return
          }
          const { contract } = project
          evictWithGc(cache, (evict) => {
            evict({ id: cache.identify(contract), fieldName: 'invoiceAmountOutstanding' })
            evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
            evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
            evict({ id: 'ROOT_QUERY', fieldName: 'aggregateBillingForecast' })
          })
        },
      }).then(() => {
        onClose()
        trackMarkAsSubmitted({
          ...getMetricsForPayApp(payApp, project.id, project.name),
          action: 'synced',
        })
      })
    },
    [
      integration.id,
      integration.type,
      markPayAppAsSyncedInErp,
      markPayAppAsSyncedInGcPortal,
      onClose,
      project,
    ]
  )

  let text: WriteSyncResultScreenText

  if (operation) {
    const isCannotBillForRetentionError =
      operation.result.code === IntegrationSyncResultCode.CANNOT_BILL_FOR_RETENTION
    const isRetentionDoesNotMatchError =
      operation.result.code === IntegrationSyncResultCode.PAY_APP_RETENTION_DOES_NOT_MATCH
    const isAlreadySubmittedError =
      operation.result.code === IntegrationSyncResultCode.PAY_APP_ALREADY_SUBMITED
    const needsLineItemImport =
      operation.result.code === IntegrationSyncResultCode.LINE_ITEM_IMPORT_NEEDED
    const needsRounding =
      operation.result.code === IntegrationSyncResultCode.BILLING_MUST_BE_ROUNDED
    const needsJournal =
      operation.result.code === IntegrationSyncResultCode.EXPORT_FAILED_JOURNAL_NEEDED

    let actions: WriteSyncResultScreenAction[]

    // Cannot bill for retention, create manually in integation + mark as synced
    if (isCannotBillForRetentionError || isAlreadySubmittedError) {
      actions = [
        {
          title: t(`${i18nBase}.mark_as_synced`),
          variant: 'outlined',
          color: 'secondary',
          onClick: () => {
            if (!operation.payApp) {
              return
            }
            markAsSynced(operation.payApp)
          },
        },
        {
          title: t(`${i18nBase}.open`, { integrationName }),
          onClick: () => window.open(thirdPartyUrl, '_blank'),
        },
      ]

      // Need to adjust retention to match integration
    } else if (isRetentionDoesNotMatchError) {
      actions = [
        {
          title: t(`${i18nBase}.adjust_retention`),
          onClick: () => {
            if (!operation.payApp) {
              return
            }
            navigate(
              getBillingPath({
                pathType: BillingPathType.PayApp,
                projectId,
                payAppId: operation.payApp.id,
                payAppTab: PayAppTab.INVOICE,
              })
            )
            onClose()
          },
        },
      ]

      // Need to import line items first
    } else if (needsLineItemImport) {
      actions = [
        {
          title: t(`${i18nBase}.go_to_sov`),
          onClick: () => {
            navigate(
              getBillingPath({
                pathType: BillingPathType.ProjectBilling,
                projectId,
                billingTab: BillingTab.SOV,
              })
            )
            onClose()
          },
        },
      ]

      // Retention needs rounding
    } else if (needsRounding) {
      const contractId = project.contract?.id
      const roundingAction = async () => {
        if (!contractId) {
          return onSyncAgain()
        }
        try {
          // Update the contract to round retention. If successful, then round billing on line items
          // and sync to the integration
          await updateRoundRetention({
            variables: {
              input: { contractId, roundRetention: true },
            },
          })
          onSyncAgain()
        } catch (error) {
          snackbar.showError(error.message)
        }
      }
      actions = [
        {
          title: t(`${i18nBase}.round_and_sync`),
          onClick: roundingAction,
        },
      ]

      // Need to inspect Sage journal
    } else if (needsJournal) {
      actions = [
        {
          title: t(`${i18nBase}.journal_needed`),
          opensLinkInNewTab: true,
          variant: 'outlined',
          color: 'secondary',
          onClick: () => {
            window.open(
              'https://support.siteline.com/hc/en-us/articles/23563732311828-Sage-100-300-Export-Troubleshooting',
              '_blank'
            )
          },
        },
        {
          title: t(`${i18nBase}.sync_again`),
          onClick: () => onSyncAgain(),
        },
      ]

      // Sync again or go back
    } else {
      actions = onBack
        ? [
            {
              title: t('common.actions.back'),
              onClick: onBack,
            },
          ]
        : [
            {
              title: t(`${i18nBase}.sync_again`),
              onClick: () => onSyncAgain(),
            },
          ]
    }

    text = {
      actions,
      title: t(`${i18nBase}.title`),
      error: errorDetails,
      description: t(`${i18nBase}.description`),
      closeLabel: t('common.actions.close'),
    }
  } else if (error) {
    const action: WriteSyncResultScreenAction = {
      title: t(`${i18nBase}.sync_again`),
      onClick: () => onSyncAgain(),
    }
    text = {
      actions: [action],
      title: t(`${i18nBase}.title`),
      error: errorDetails,
      description: t(`${i18nBase}.description`),
      closeLabel: t('common.actions.close'),
    }
  } else {
    return null
  }

  return (
    <WriteSyncResultScreen
      text={text}
      integration={integration}
      payload={payload}
      projectId={projectId}
      onClose={onClose}
    />
  )
}
