import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  useClearPayAppFormsMutation,
  useRemovePayAppRequirementMutation,
} from '../../../common/graphql/apollo-operations'
import {
  buildPayAppTemplateSets,
  CompanyWithForms,
  derivePayAppTemplateIdsFromContract,
} from '../../../common/util/Forms'
import {
  deriveFormSelectionStatusFromContract,
  getClearOnboardingFormsRefetchQuery,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import { OnboardingFormsSection } from './OnboardingFormsSection'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

gql`
  mutation clearPayAppForms($contractId: ID!) {
    clearPayAppForms(contractId: $contractId) {
      id
      payAppRequirementGroups {
        id
        order
        payAppRequirements {
          id
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      payApps {
        id
        formTemplates {
          formTemplateVariant {
            id
            template {
              id
            }
          }
        }
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${fragments.onboardedProjectContractStatus}
`

const i18nBase = 'projects.subcontractors.settings.forms'

const FORM_TYPE = ProjectOnboardingFormType.PAY_APP

interface PayAppFormsTaskProps {
  contract: ContractForProjectOnboarding
  company: CompanyWithForms
  /**
   * Only applicable if this is rendered in a component that has an "edit" mode,
   * as is the case in project settings.
   */
  isEditing?: boolean
  shouldConfirmFormDeletion?: boolean
}

/** Under the onboarding checklist Forms task, section for selecting pay app forms */
export function PayAppFormsSection({
  contract,
  isEditing = true,
  company,
  shouldConfirmFormDeletion,
}: PayAppFormsTaskProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const clearFormsRefetchQuery = useMemo(
    () => getClearOnboardingFormsRefetchQuery(companyId),
    [companyId]
  )

  const [clearForms] = useClearPayAppFormsMutation({
    ...clearFormsRefetchQuery,
    update: (cache) => {
      // OnboardedStatus.selectedPayAppForms might change
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const [removePayAppRequirement] = useRemovePayAppRequirementMutation()

  const formSelectionStatus = useMemo(() => {
    return deriveFormSelectionStatusFromContract(FORM_TYPE, contract)
  }, [contract])

  const companyPayAppTemplateSets = useMemo(() => {
    const templateSets = company.payAppFormTemplateSets
    return buildPayAppTemplateSets([...templateSets], contract.billingType)
  }, [contract.billingType, company.payAppFormTemplateSets])

  const contractFormTemplates = useMemo(
    () => derivePayAppTemplateIdsFromContract(contract),
    [contract]
  )

  const handleClearForms = useCallback(async () => {
    try {
      await clearForms({ variables: { contractId: contract.id } })
      snackbar.showSuccess(t(`${i18nBase}.cleared_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [clearForms, contract.id, snackbar, t])

  const handleRemovePayAppRequirement = useCallback(
    async (formTemplateId: string) => {
      try {
        await removePayAppRequirement({
          variables: {
            input: {
              contractId: contract.id,
              formTemplateId,
            },
          },
        })
        snackbar.showSuccess(t(`${i18nBase}.cleared_pay_app_form`))
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract, removePayAppRequirement, snackbar, t]
  )

  return (
    <>
      <OnboardingFormsSection
        onboardingFormType={FORM_TYPE}
        formTemplates={contractFormTemplates}
        companyTemplateSets={companyPayAppTemplateSets}
        isProcessingForms={formSelectionStatus.isProcessingForms}
        onClearForms={handleClearForms}
        contract={contract}
        onDeleteForm={isEditing ? handleRemovePayAppRequirement : undefined}
        isEditing={isEditing}
        shouldConfirmFormDeletion={shouldConfirmFormDeletion}
      />
    </>
  )
}
