import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import AddFormsBlue50Icon from '../../../assets/icons/add_forms_blue50.svg'
import AddFormsGrey70Icon from '../../../assets/icons/add_forms_grey70.svg'
import MagicBlue50Icon from '../../../assets/icons/magic_blue50.svg'
import MagicGrey70Icon from '../../../assets/icons/magic_grey70.svg'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  ProjectOnboardingFormType,
  useSelectProjectFormsMutation,
} from '../../../common/graphql/apollo-operations'
import { isNonEmptyArray } from '../../../common/util/Array'
import {
  CompanyTemplateSet,
  DEFAULT_FORM_TYPES,
  FORM_SET_TYPES,
  SelectFormsRefetchQuery,
} from '../../../common/util/Forms'
import { trackSelectedProjectForms } from '../../../common/util/MetricsTracking'
import {
  ContractOnboardingFormType,
  OnboardingFormsInstructions,
  invalidateContractsAfterOnboardingStatusChange,
  useUploadOnboardingForms,
} from '../../../common/util/ProjectOnboarding'
import { PendingFile } from '../backup/attachments/FileDragUpload'
import { CopyProjectFormsDialog } from './CopyProjectFormsDialog'
import { OnboardingButton } from './OnboardingButton'
import { OnboardingFormUploadDialog } from './OnboardingFormUploadDialog'
import { ContractForCompanyFormSetSelection } from './PreviewCompanyTemplatesDialog'
import { PreviewDefaultFormsDialog } from './PreviewDefaultFormsDialog'
import { SelectCompanyTemplatesDialog } from './SelectCompanyTemplatesDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  formTemplateOptions: {
    display: 'flex',
    marginBottom: theme.spacing(3.5),
    '& > *': {
      flex: 1,
    },
    '& > *:not(:last-child) ': {
      marginRight: theme.spacing(2),
      flex: 1,
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

enum DialogStep {
  SELECT_TEMPLATE = 'SELECT_TEMPLATE',
  PREVIEW_DEFAULT = 'PREVIEW_DEFAULT',
  CHOOSE_TEMPLATE_SET = 'CHOOSE_TEMPLATE_SET',
  CHOOSE_COPY = 'CHOOSE_COPY',
  UPLOAD = 'UPLOAD',
}

export enum FormSelectionType {
  DEFAULT = 'DEFAULT',
  COPY = 'COPY',
  UPLOAD = 'UPLOAD',
}

interface SelectFormTemplatesDialogProps {
  selectTemplateTypeTitle: string
  open: boolean
  onClose: () => void
  selectFormsRefetchQuery?: SelectFormsRefetchQuery
  formType: ContractOnboardingFormType
  companyTemplateSets: CompanyTemplateSet[]
  contract: ContractForCompanyFormSetSelection
  location: string
  /**
   * By default, when forms have been selected and the selection confirmed, we display
   * a success toast. This callback replaces that default behavior.
   *
   */
  onSelectionSubmitted?: (selectType: FormSelectionType) => void
}

/**
 * Dialog for onboarding project forms, presents options to upload, choose defaults, or
 * copy from a project. This is used in both the billing and vendor project onboarding flows
 */
export function SelectFormTemplatesDialog({
  selectTemplateTypeTitle,
  open,
  onClose,
  selectFormsRefetchQuery,
  formType,
  companyTemplateSets,
  contract,
  location,
  onSelectionSubmitted,
}: SelectFormTemplatesDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()
  const { name: projectName } = useProjectContext()

  const [dialogStep, setDialogStep] = useState<DialogStep | null>(null)
  const [isUploadingForms, setIsUploadingForms] = useState<boolean>(false)

  const [selectForms, { loading }] = useSelectProjectFormsMutation({
    ...selectFormsRefetchQuery,
    update: (cache) => {
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const hasDefaults = companyTemplateSets.length === 1
  const hasMultipleTemplateSets = companyTemplateSets.length > 1
  const contractId = contract.id

  const uploadForms = useUploadOnboardingForms({
    formType,
    contractId,
    refetchQuery: selectFormsRefetchQuery,
  })

  const formTypes = useMemo(() => [formType], [formType])

  const [defaultFormTemplates, hasConditionalDefaultForms, isProcessingDefaultForms] =
    useMemo(() => {
      if (!hasDefaults) {
        return [null, false, false]
      }
      const defaultCompanyTemplateSet = companyTemplateSets[0]
      return [
        defaultCompanyTemplateSet.formTemplates,
        !!defaultCompanyTemplateSet.hasConditionalRequirements,
        defaultCompanyTemplateSet.isProcessingForms,
      ]
    }, [companyTemplateSets, hasDefaults])

  const companyTemplateGroups = useMemo(() => {
    return {
      type: 'templateSet' as const,
      groups: companyTemplateSets,
    }
  }, [companyTemplateSets])

  const handleGoToDialogFirstStep = useCallback(
    (fromButton?: boolean) => {
      if (fromButton === false) {
        onClose()
        return
      }
      setDialogStep(DialogStep.SELECT_TEMPLATE)
    },
    [onClose]
  )

  const handleGoToDefaultFormsStep = useCallback(() => {
    setDialogStep(DialogStep.PREVIEW_DEFAULT)
  }, [])

  const handleGoToChooseTemplateSetStep = useCallback(() => {
    setDialogStep(DialogStep.CHOOSE_TEMPLATE_SET)
  }, [])

  const handleGoToCopyFormsStep = useCallback(() => {
    setDialogStep(DialogStep.CHOOSE_COPY)
  }, [])

  const handleGoToUploadFormsStep = useCallback(() => {
    setDialogStep(DialogStep.UPLOAD)
  }, [])

  const handleConfirmDefaultForms = useCallback(
    async (includeChangeOrderLogOnPayApps?: boolean) => {
      if (!hasDefaults) {
        return
      }
      try {
        const [defaultCompanyTemplateSet] = companyTemplateSets

        const isFormSetType = FORM_SET_TYPES.includes(formType)
        const isDefaultFormType = DEFAULT_FORM_TYPES.includes(formType)

        const templateSetId = isFormSetType ? defaultCompanyTemplateSet.id : undefined
        const useDefaultForms = isDefaultFormType ? true : undefined

        await selectForms({
          variables: {
            input: {
              contractId,
              formTypes,
              templateSetId,
              useDefaultForms,
              includeChangeOrderLogOnPayApps,
            },
          },
        })
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          location,
          selectType: 'defaultForms',
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.DEFAULT)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.added_forms_success.${formType}`))
        }
      } catch (error) {
        snackbar.showError(error.message)
      }
      onClose()
    },
    [
      companyTemplateSets,
      contractId,
      formType,
      formTypes,
      hasDefaults,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      selectForms,
      snackbar,
      t,
    ]
  )

  const handleConfirmCopyForms = useCallback(
    async (
      copyFormTypes: ContractOnboardingFormType[],
      copyFromContractId: string,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      try {
        await selectForms({
          variables: {
            input: {
              contractId,
              formTypes: copyFormTypes,
              copyFromContractId,
              includeChangeOrderLogOnPayApps,
            },
          },
        })
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          location,
          selectType: 'copyFromProject',
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.COPY)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.added_forms_success.${formType}`))
        }
      } catch (error) {
        snackbar.showError(error.message)
      }
      onClose()
    },
    [
      contractId,
      formType,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      selectForms,
      snackbar,
      t,
    ]
  )

  const handleConfirmUploadForms = useCallback(
    async (
      pendingFiles: PendingFile[],
      instructions: OnboardingFormsInstructions,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      setIsUploadingForms(true)
      try {
        await uploadForms(pendingFiles, instructions, includeChangeOrderLogOnPayApps)
        trackSelectedProjectForms({
          contractId,
          projectName,
          formsType: formType,
          selectType: 'newForms',
          location,
          includeChangeOrderLogOnPayApps,
        })
        if (onSelectionSubmitted) {
          onSelectionSubmitted(FormSelectionType.UPLOAD)
        } else {
          snackbar.showSuccess(t(`${i18nBase}.forms_uploaded`))
        }
        onClose()
      } catch (err) {
        snackbar.showError(err.message)
        // Re-throw so that the dialog doesn't close
        throw err
      } finally {
        setIsUploadingForms(false)
      }
    },
    [
      contractId,
      formType,
      location,
      onClose,
      onSelectionSubmitted,
      projectName,
      snackbar,
      t,
      uploadForms,
    ]
  )

  const formTypeForCopyDialog = useMemo(() => {
    return [formType]
  }, [formType])

  const formUploadProps = useMemo(
    () => ({
      formsType: formType,
      onUploadForms: handleConfirmUploadForms,
      submitting: isUploadingForms,
    }),
    [formType, handleConfirmUploadForms, isUploadingForms]
  )

  // Opening & closing the dialog is handled externally. Update here
  // whenever the open state changes
  useEffect(() => {
    if (open) {
      handleGoToDialogFirstStep()
    } else {
      setDialogStep(null)
    }
  }, [handleGoToDialogFirstStep, open])

  return (
    <>
      <SitelineDialog
        title={selectTemplateTypeTitle}
        open={dialogStep === DialogStep.SELECT_TEMPLATE}
        onClose={onClose}
        cancelLabel={t('common.actions.cancel')}
        actionsLayout="closeIcon"
      >
        <div className={classes.formTemplateOptions}>
          {hasDefaults && (
            <OnboardingButton
              imageSrc={MagicGrey70Icon}
              hoverImageSrc={MagicBlue50Icon}
              title={t(`${i18nBase}.use_default_forms`)}
              subtitle={t(`${i18nBase}.standard_forms`)}
              onClick={handleGoToDefaultFormsStep}
              imageSize="sm"
            />
          )}
          {hasMultipleTemplateSets && (
            <OnboardingButton
              imageSrc={MagicGrey70Icon}
              hoverImageSrc={MagicBlue50Icon}
              title={t(`${i18nBase}.select_form_set`)}
              subtitle={t(`${i18nBase}.standard_forms`)}
              onClick={handleGoToChooseTemplateSetStep}
              imageSize="sm"
            />
          )}
          <OnboardingButton
            imageSrc={<FileCopyOutlinedIcon />}
            title={t(`${i18nBase}.copy_forms`)}
            subtitle={t(`${i18nBase}.reuse_forms`)}
            onClick={handleGoToCopyFormsStep}
            imageSize="sm"
          />
          <OnboardingButton
            imageSrc={AddFormsGrey70Icon}
            hoverImageSrc={AddFormsBlue50Icon}
            title={t(`${i18nBase}.add_new_forms`)}
            subtitle={t(`${i18nBase}.forms_not_used`)}
            onClick={handleGoToUploadFormsStep}
            imageSize="sm"
          />
        </div>
      </SitelineDialog>
      {defaultFormTemplates !== null && (
        <PreviewDefaultFormsDialog
          open={dialogStep === DialogStep.PREVIEW_DEFAULT}
          onClose={handleGoToDialogFirstStep}
          defaultFormTemplates={defaultFormTemplates}
          hasConditionalDefaultForms={hasConditionalDefaultForms}
          onConfirm={handleConfirmDefaultForms}
          submitting={loading}
          isProcessingForms={isProcessingDefaultForms}
          // If we're at this dialog step we can default to true because default forms have been selected
          includeChangeOrderLogInPayAppPackage={
            formType === ProjectOnboardingFormType.CHANGE_ORDER_LOG ? true : undefined
          }
        />
      )}
      {isNonEmptyArray(formTypes) && (
        <SelectCompanyTemplatesDialog
          open={dialogStep === DialogStep.CHOOSE_TEMPLATE_SET}
          onClose={handleGoToDialogFirstStep}
          formTypes={formTypes}
          contract={contract}
          companyTemplateGroups={companyTemplateGroups}
          location={location}
        />
      )}
      <CopyProjectFormsDialog
        open={dialogStep === DialogStep.CHOOSE_COPY}
        onClose={handleGoToDialogFirstStep}
        cancelLabel={t('common.actions.back')}
        formTypes={formTypeForCopyDialog}
        onConfirm={handleConfirmCopyForms}
        submitting={loading}
        includeChangeOrderLogInPayAppPackage
      />
      <OnboardingFormUploadDialog
        open={dialogStep === DialogStep.UPLOAD}
        onClose={handleGoToDialogFirstStep}
        cancelLabel={t('common.actions.back')}
        contractFormUploadProps={formUploadProps}
        initialIncludeCoLogInPayAppPackage
      />
    </>
  )
}
