import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  IntegrationTypeFamily,
  StoredMaterialsCarryoverType,
  TaxCalculationType,
  getIntegrationTypeFamily,
  supportsReadTaxCalculationType,
} from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { Spreadsheet } from '../../../common/components/Spreadsheet/Spreadsheet'
import {
  SpreadsheetDataType,
  SpreadsheetFooterRow,
  SpreadsheetRowType,
  makeContentCell,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  GetPayAppForProgressDocument,
  MinimalIntegrationProperties,
  RetentionTrackingLevel,
  SovChangeSetProperties,
  useUpdateSovWithChangeSetMutation,
} from '../../../common/graphql/apollo-operations'
import {
  EditingSov,
  EditingSovLineItem,
  PreviewLineItemChange,
  PreviewLineItemGroupChange,
  getChangeSetLineItemUpdates,
  getContractBillingType,
  getInitialGroupingRows,
  getPreviewGroups,
  getPreviewLineItems,
  getSovColumns,
  useQuerySovForEditing,
} from '../../../common/util/ManageSov'
import { hasRevisedSovLineItems } from '../../../common/util/ManageUnitPriceSovColumn'
import { isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import { sovToEditingSov } from '../../../common/util/ProjectOnboarding'
import { usesStandardOrLineItemTracking } from '../../../common/util/Retention'
import {
  AddLineItemIcon,
  DeleteLineItemIcon,
  UpdateLineItemIcon,
  getLineItemRow,
  getTotalsRow,
  makePreviewLineItemGroup,
  makePreviewLineItemRow,
} from '../sov/ManageSovRow'
import { EMPTY_SOV } from '../sov/ProjectSov'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    height: '100vh',
    '& .dialogPopover': {
      height: '100%',
      position: 'relative',
    },
    '& .content': {
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      '& .revertWarning': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .spreadsheetWrapper': {
      height: '100%',
      overflow: 'auto',
      // A normal sized window will fit 15 rows perfectly. Reduce the height of this container
      // a bit so that a large SOV is more likely to be cut off mid-row instead of between rows,
      // as a visual hint that the user can scroll further down the SOV.
      maxHeight: 'calc(100% - 5px)',
    },
    '& .changeOrderCheck': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: colors.grey50,
    },
    '& .addLineItemIcon': {
      fontSize: 16,
      color: colors.green50,
    },
    '& .updateLineItemIcon': {
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        backgroundColor: colors.green50,
        width: 6,
        height: 6,
        borderRadius: '50%',
      },
    },
    '& .deleteLineItemIcon': {
      fontSize: 16,
      color: colors.red50,
    },
  },
  legend: {
    display: 'flex',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
      '&:not(:first-child)': {
        marginLeft: theme.spacing(3),
      },
    },
  },
  selectAll: {
    marginBottom: theme.spacing(0.5),
    alignSelf: 'flex-start',
  },
}))

type SovChangeSetLineItemAddition = SovChangeSetProperties['additions'][number]['new']

interface PreviewSovChangeSetDialogProps {
  integration: MinimalIntegrationProperties
  onlyChangeOrders: boolean
  changeSet: SovChangeSetProperties
  loading: boolean
  open: boolean
  onClose: () => void

  /** If called from the invoice page, include the pay app so the invoice can be updated */
  payAppId?: string
}

const i18nBase = 'projects.subcontractors.sov.preview_dialog'

function compareAdditionLineItem(
  addition: SovChangeSetLineItemAddition,
  existing: EditingSovLineItem
): boolean {
  return (
    addition.code === existing.code &&
    addition.name === existing.name &&
    addition.latestTotalValue === existing.latestTotalValue
  )
}

// Since the original changeSet includes the full contract (so we can display the whole thing), we
// should filter out any updates that aren't actual updates. By nature, all additions/deletions are
// actual modifications to the SOV but just because the update is listed doesn't mean it's an actual
// update to the SOV. Filter those out.
function getCleanUpdateChangeSet(params: {
  sovLineItems: EditingSovLineItem[]
  changeSet: SovChangeSetProperties
  timeZone: string
  includePreSitelineBillingColumn: boolean
  includeMaterialsInStorageColumn: boolean
  includeRetentionPercentColumn: boolean
  includePreSitelineRetentionColumns: boolean
  includeTaxGroupColumn: boolean
}): SovChangeSetProperties {
  const { sovLineItems, changeSet } = params
  const lineItemsWithChange = sovLineItems.filter((lineItem, index) => {
    const changeSetUpdate = changeSet.updates.find((update) => update.oldId === lineItem.id)
    // Check first if the sort order is updated, since a change to any one line item could cause
    // others to be updated
    const isSortOrderUpdated = changeSetUpdate && index + 1 !== changeSetUpdate.new.sortOrder
    if (isSortOrderUpdated) {
      return true
    }
    const { isLineItemUpdated } = getChangeSetLineItemUpdates({
      lineItem,
      update: changeSetUpdate,
      timeZone: params.timeZone,
      includePreSitelineBillingColumn: params.includePreSitelineBillingColumn,
      includeRetentionPercentColumn: params.includeRetentionPercentColumn,
      includePreSitelineRetentionColumns: params.includePreSitelineRetentionColumns,
      includeMaterialsInStorageColumn: params.includeMaterialsInStorageColumn,
      includeTaxGroupColumn: params.includeTaxGroupColumn,
    })
    return isLineItemUpdated
  })

  const updates = changeSet.updates.filter((update) =>
    lineItemsWithChange.some((lineItem) => lineItem.id === update.oldId)
  )
  return { ...changeSet, updates }
}

/** Dialog for previewing changes that may be imported to a project's SOV */
export function PreviewSovChangeSetDialog({
  integration,
  onlyChangeOrders,
  changeSet,
  loading,
  open,
  onClose,
  payAppId,
}: PreviewSovChangeSetDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id: projectId, timeZone } = useProjectContext()
  const { companyId } = useCompanyContext()
  const snackbar = useSitelineSnackbar()
  const { contract } = useQuerySovForEditing({ projectId, companyId, skip: !open })
  const [updateSovWithChangeSet, { loading: importing }] = useUpdateSovWithChangeSetMutation({
    refetchQueries: payAppId
      ? [
          {
            query: GetPayAppForProgressDocument,
            variables: { payAppId },
          },
        ]
      : [],
  })
  const integrationName = integration.shortName
  const canChooseLineItemsToImport =
    getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.ERP
  const billingType = contract && getContractBillingType(contract)
  const isUnitPrice = billingType === BillingType.UNIT_PRICE
  // Adjustments to unit price line items isn't yet supported by integrations
  const includeRevisedContractColumns =
    isUnitPrice &&
    contract !== undefined &&
    contract.sov !== null &&
    hasRevisedSovLineItems(contract.sov.lineItems)

  const sov = useMemo(() => {
    if (!contract || !contract.sov) {
      return EMPTY_SOV
    }
    return sovToEditingSov(
      [...contract.sov.lineItems],
      contract.pastPayAppCount > 0,
      timeZone,
      contract.sov.totalRetention
    )
  }, [contract, timeZone])

  const retentionTrackingLevel = contract?.retentionTrackingLevel ?? RetentionTrackingLevel.STANDARD
  const hasPastPayApps = !!contract && contract.pastPayAppCount > 0
  const includePreSitelineBillingColumn = hasPastPayApps
  const includeRetentionPercentColumn = usesStandardOrLineItemTracking(retentionTrackingLevel)
  const includePreSitelineRetentionColumns =
    hasPastPayApps && usesStandardOrLineItemTracking(retentionTrackingLevel)
  const includeTaxGroupColumn =
    contract?.taxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS &&
    supportsReadTaxCalculationType(integration.type, TaxCalculationType.MULTIPLE_TAX_GROUPS)
  const hasSignedPayApps = useMemo(() => {
    if (!contract?.payApps) {
      return false
    }
    return contract.payApps.some(
      (projectPayApp) => !isPayAppDraftOrSyncFailed(projectPayApp.status)
    )
  }, [contract?.payApps])

  const includeMaterialsInStorageColumn =
    !!contract &&
    contract.storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL &&
    hasPastPayApps

  const columns = useMemo(() => {
    if (!billingType) {
      return []
    }
    const dataColumns = getSovColumns(billingType, t, {
      includePreSitelineBillingColumn,
      includeMaterialsInStorageColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
      includeTotalBillingColumns: false,
      includeTotalRetainageColumn: false,
      includeChangeOrderColumn: false,
      includeChangeOrderApprovalColumn: true,
      includeRevisedContractColumns,
      taxGroups: includeTaxGroupColumn ? [...contract.company.taxGroups] : null,
      onAddTaxGroupForLineItemId: null,
      isEditable: false,
      timeZone,
    })

    // Add a first column for change set indicator icons
    return [
      {
        id: 'CHANGE_SET_CHANGE',
        heading: '',
        isEditable: false,
        dataType: SpreadsheetDataType.OTHER as const,
        align: 'left' as const,
        minWidth: 48,
        maxWidth: 48,
      },
      ...dataColumns,
    ]
  }, [
    billingType,
    t,
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    contract?.company.taxGroups,
    timeZone,
  ])

  // The full set of line items to show in order, including both the initial SOV line items and
  // added line items from the incoming change set
  const finalLineItems = useMemo(
    () =>
      getPreviewLineItems(sov.lineItems, changeSet, {
        includePreSitelineBillingColumn,
        includeMaterialsInStorageColumn,
        includeRetentionPercentColumn,
        includePreSitelineRetentionColumns,
        includeTaxGroupColumn,
        includeGroups: true,
        timeZone,
      }),
    [
      sov.lineItems,
      changeSet,
      includePreSitelineBillingColumn,
      includeMaterialsInStorageColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
      includeTaxGroupColumn,
      timeZone,
    ]
  )

  // The full set of groups to show in order, including both the initial SOV groups and
  // groups added/updated/deleted from the changeset
  const finalGroups = useMemo(() => {
    return getPreviewGroups({ existingGroups: sov.groups, changeSet })
  }, [sov.groups, changeSet])

  // If we are importing from an ERP, we need state to determine which line items to modify as we
  // give the user the ability to select which changes they want. For GC portals, this isn't needed.
  // This is set to all line items by default.
  const initialUpdateChangeSet = getCleanUpdateChangeSet({
    sovLineItems: sov.lineItems,
    changeSet,
    timeZone,
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeTaxGroupColumn,
  })
  const [updateChangeSet, setUpdateChangeSet] =
    useState<SovChangeSetProperties>(initialUpdateChangeSet)

  // Update the update change set any time the original one changes
  useEffect(() => {
    setUpdateChangeSet(initialUpdateChangeSet)
    // Disable lint because initialUpdateChangeSet is recalculated on every render, we only want
    // to call this when changeSet changes (when the dialog closes and you change to a different
    // project SOV)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSet])

  const totalsRow: SpreadsheetFooterRow | undefined = useMemo(() => {
    if (!billingType) {
      return undefined
    }
    // Only include line items from the final SOV in totals, i.e. updated and added line items
    const lineItemsForTotals = _.chain(finalLineItems)
      .filter(({ change }) => change !== PreviewLineItemChange.DELETED)
      .map(({ lineItem, change }): EditingSovLineItem => {
        if (change !== PreviewLineItemChange.UPDATED) {
          return lineItem
        }
        // If updating the line item, use the updated values in the totals
        const updated = changeSet.updates.find((update) => update.oldId === lineItem.id)
        return {
          ...lineItem,
          ...getChangeSetLineItemUpdates({
            lineItem,
            update: updated,
            timeZone,
            includePreSitelineBillingColumn,
            includeMaterialsInStorageColumn,
            includeRetentionPercentColumn,
            includePreSitelineRetentionColumns,
            includeTaxGroupColumn,
          }),
        }
      })
      .value()

    const sovForTotals: EditingSov = { ...sov, lineItems: lineItemsForTotals }
    const row = getTotalsRow(billingType, {
      sov: sovForTotals,
      includePreSitelineBillingColumn,
      includeMaterialsInStorageColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
      includeTotalBillingColumns: false,
      includeTotalRetainageColumn: false,
      includeChangeOrderColumn: false,
      includeChangeOrderApprovalColumn: true,
      includeRevisedContractColumns,
      isFirstInUngroupedBlock: false,
      includeTaxGroupColumn,
      t,
    })
    return {
      ...row,
      // Don't fix the footer, it won't work well inside the dialog
      isFixed: false,
      // Add an empty cell at the beginning for the change set indicator column
      cells: [makeContentCell(null, []), ...row.cells],
    }
  }, [
    billingType,
    finalLineItems,
    sov,
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    t,
    changeSet.updates,
    timeZone,
  ])

  const handleImport = useCallback(async () => {
    if (!contract?.sov) {
      return
    }
    const originalUpdateIds = changeSet.updates.map((update) => update.oldId)
    const selectedUpdateIds = updateChangeSet.updates.map((update) => update.oldId)
    const deselectedUpdateIds = _.difference(originalUpdateIds, selectedUpdateIds)

    const originalDeleteIds = changeSet.deletions.map((update) => update.oldId)
    const selectedDeleteIds = updateChangeSet.deletions.map((update) => update.oldId)
    const deselectedDeleteIds = _.difference(originalDeleteIds, selectedDeleteIds)

    // First rewrite deletion sort orders. Deletions are special in that they don't have a new
    // sortOrder proposed (we're expecting to delete them, but the user didn't want to). We will
    // rewrite these to the top and carry the offset forward with additions/updates. This is a very
    // rare edge case (ie. non-GC-Portal integration wants to delete but the user doesn't want to).
    const deleteSortOrderRewrites = _.chain(contract.sov.lineItems)
      .filter((lineItem) => deselectedDeleteIds.includes(lineItem.id))
      .orderBy((lineItem) => lineItem.sortOrder, 'asc')
      .map((item, index) => ({
        oldId: item.id,
        new: {
          sortOrder: index + 1,
        },
      }))
      .value()
    // Because we're rewriting the sort order of the lines that we aren't touching, we should add
    // that to the sortOrder's of the changes that we are bringing in from the integration. This
    // means that we can guarantee anything we didn't touch stays in the original order at the top
    // of the SOV and all changes come in afterwards.
    const minimumSortOrder = deleteSortOrderRewrites.length + 1

    // If any update is unchecked but the server has a new sortOrder, we should include just the
    // new sortOrder in the updateSovWithChangeSet call. If we don't, there's a chance that
    // sortOrders conflict with other changes.
    const newUpdateSortOrders = changeSet.updates
      .filter((update) => deselectedUpdateIds.includes(update.oldId))
      .filter((update) => update.new.sortOrder !== null)
      .map((update) => ({
        oldId: update.oldId,
        new: {
          // Casting to Number as we're filtering out null values above
          sortOrder: Number(update.new.sortOrder) + minimumSortOrder,
        },
      }))

    const additions = updateChangeSet.additions.map((addition) => ({
      new: {
        ..._.omit(addition.new, ['__typename']),
        previousStoredMaterials: addition.new.previousStoredMaterials ?? undefined,
        previousMaterialsInStorage: addition.new.previousMaterialsInStorage ?? undefined,
        // Make sure we account for minimumSortOrder here as those line items are going first
        sortOrder: addition.new.sortOrder ? addition.new.sortOrder + minimumSortOrder : undefined,
        sovLineItemGroupId: addition.new.sovLineItemGroupId ?? undefined,
        changeOrderApprovedDate: addition.new.changeOrderApprovedDate ?? undefined,
        changeOrderEffectiveDate: addition.new.changeOrderEffectiveDate ?? undefined,
        unitName: addition.new.unitName ?? undefined,
        unitPrice: addition.new.unitPrice ?? undefined,
        defaultRetentionPercent: addition.new.defaultRetentionPercent ?? undefined,
        preSitelineRetentionHeldOverride:
          addition.new.preSitelineRetentionHeldOverride ?? undefined,
        // If the tax group column is shown, it means the integration is providing tax groups per
        // line item, so we use the result in the update whether it's null or not. It's possible
        // that the integrations service provided an undefined value for the tax group and it would
        // be better for us to leave the current value, but we can't distinguish between null and
        // undefined in the change set from the API so we assume any nil value should clear it.
        taxGroupId: includeTaxGroupColumn ? addition.new.taxGroupId : undefined,
      },
    }))

    const updates = updateChangeSet.updates.map((update) => ({
      oldId: update.oldId,
      new: {
        code: update.new.code ?? undefined,
        name: update.new.name ?? undefined,
        costCode: update.new.costCode ?? undefined,
        latestTotalValue: update.new.latestTotalValue ?? undefined,
        originalTotalValue: update.new.originalTotalValue ?? undefined,
        previousBilled: update.new.previousBilled ?? undefined,
        isChangeOrder: update.new.isChangeOrder ?? undefined,
        previousStoredMaterials: update.new.previousStoredMaterials ?? undefined,
        previousMaterialsInStorage: update.new.previousMaterialsInStorage ?? undefined,
        // Make sure we account for minimumSortOrder here as those line items are going first
        sortOrder: update.new.sortOrder ? update.new.sortOrder + minimumSortOrder : undefined,
        sovLineItemGroupId: update.new.sovLineItemGroupId,
        changeOrderApprovedDate: update.new.changeOrderApprovedDate ?? undefined,
        changeOrderEffectiveDate: update.new.changeOrderEffectiveDate ?? undefined,
        unitName: update.new.unitName ?? undefined,
        unitPrice: update.new.unitPrice ?? undefined,
        defaultRetentionPercent: update.new.defaultRetentionPercent ?? undefined,
        preSitelineRetentionHeldOverride: update.new.preSitelineRetentionHeldOverride ?? undefined,
        // See logic above
        taxGroupId: includeTaxGroupColumn ? update.new.taxGroupId : undefined,
      },
    }))

    const deletions = updateChangeSet.deletions.map((deletion) => ({
      oldId: deletion.oldId,
    }))
    const groupAdditions = updateChangeSet.groupAdditions.map((addition) => ({
      newId: addition.newId,
      new: {
        code: addition.new.code,
        name: addition.new.name,
      },
    }))
    const groupUpdates = updateChangeSet.groupUpdates.map((update) => ({
      oldId: update.oldId,
      new: {
        code: update.new.code,
        name: update.new.name,
      },
    }))
    const groupDeletions = updateChangeSet.groupDeletions.map((deletion) => ({
      oldId: deletion.oldId,
    }))

    try {
      await updateSovWithChangeSet({
        variables: {
          input: {
            sovId: contract.sov.id,
            integrationId: integration.id,
            additions,
            updates: [...deleteSortOrderRewrites, ...newUpdateSortOrders, ...updates],
            deletions,
            groupAdditions,
            groupUpdates,
            groupDeletions,
          },
        },
      })
      snackbar.showSuccess(t(`${i18nBase}.import_success`, { integrationName }))
      onClose()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [
    updateSovWithChangeSet,
    contract?.sov,
    changeSet,
    updateChangeSet,
    onClose,
    snackbar,
    t,
    integrationName,
    integration.id,
    includeTaxGroupColumn,
  ])

  const handleAdditionRowChecked = useCallback(
    (lineItem: EditingSovLineItem, isChecked: boolean) => {
      if (isChecked) {
        const newAddition = changeSet.additions.find((addition) =>
          compareAdditionLineItem(addition.new, lineItem)
        )
        if (newAddition) {
          setUpdateChangeSet((changeSet) => ({
            ...changeSet,
            additions: [...changeSet.additions, newAddition],
          }))
        }
      } else {
        setUpdateChangeSet((changeSet) => ({
          ...changeSet,
          additions: changeSet.additions.filter(
            (addition) => !compareAdditionLineItem(addition.new, lineItem)
          ),
        }))
      }
    },
    [setUpdateChangeSet, changeSet]
  )
  const handleExistingRowChecked = useCallback(
    (lineItemId: string, isChecked: boolean) => {
      const existingUpdate = changeSet.updates.find((update) => update.oldId === lineItemId)
      const existingDelete = changeSet.deletions.find((update) => update.oldId === lineItemId)
      if (isChecked) {
        if (existingUpdate) {
          setUpdateChangeSet((changeSet) => ({
            ...changeSet,
            updates: [...changeSet.updates, existingUpdate],
          }))
        } else if (existingDelete) {
          setUpdateChangeSet((changeSet) => ({
            ...changeSet,
            deletions: [...changeSet.deletions, existingDelete],
          }))
        }
      } else {
        if (existingUpdate) {
          setUpdateChangeSet((changeSet) => ({
            ...changeSet,
            updates: changeSet.updates.filter((update) => update.oldId !== lineItemId),
          }))
        } else if (existingDelete) {
          setUpdateChangeSet((changeSet) => ({
            ...changeSet,
            deletions: changeSet.deletions.filter((update) => update.oldId !== lineItemId),
          }))
        }
      }
    },
    [setUpdateChangeSet, changeSet]
  )
  const isAdditionRowIncludedInChangeSet = useCallback(
    (lineItem: EditingSovLineItem) => {
      return updateChangeSet.additions.some((addition) =>
        compareAdditionLineItem(addition.new, lineItem)
      )
    },
    [updateChangeSet]
  )
  const isExistingRowIncludedInChangeSet = useCallback(
    (lineItemId: string) => {
      const existingUpdate = updateChangeSet.updates.some((update) => update.oldId === lineItemId)
      const existingDelete = updateChangeSet.deletions.some((update) => update.oldId === lineItemId)
      return existingUpdate || existingDelete
    },
    [updateChangeSet]
  )

  const lineItemRows = useMemo(() => {
    if (!billingType) {
      return []
    }
    const previewLineItems = finalLineItems.flatMap(({ lineItem, change }, index) => {
      const rows = []

      // Generate grouping rows for the line item
      const lineItemUpdate = changeSet.updates.find((update) => update.oldId === lineItem.id)
      const oldGroupId = lineItem.groupId
      const newGroupId = lineItemUpdate?.new.sovLineItemGroupId ?? null
      const wasRemovedFromGroup = oldGroupId && !newGroupId

      // If the line item was removed from a group, we want to display the old group in red
      // In other cases (added to group, group updated, group unchanged), we want to show the new
      // group, if this is the first line item in the group
      let groupIdToDisplay = wasRemovedFromGroup ? oldGroupId : null
      if (newGroupId) {
        const previousLineItem = index > 0 ? finalLineItems[index - 1] : null
        const previousLineItemUpdate = changeSet.updates.find(
          (update) => update.oldId === previousLineItem?.lineItem.id
        )
        const previousLineItemNewGroupId = previousLineItemUpdate?.new.sovLineItemGroupId ?? null
        if (!previousLineItem || previousLineItemNewGroupId !== newGroupId) {
          groupIdToDisplay = newGroupId
        }
      }

      const group = finalGroups.find(({ group }) => group.id === groupIdToDisplay)
      const groupChangeSetUpdate = changeSet.groupUpdates.find(
        (update) => update.oldId === groupIdToDisplay
      )
      const { rows: groupingRows, isFirstInUngroupedBlock } = getInitialGroupingRows(
        group?.group ?? null,
        index,
        finalLineItems.map(({ lineItem }) => lineItem),
        groupChangeSetUpdate ?? null,
        {
          numColumns: columns.length - 1,
          showWarningIfGroupEmpty: false,
          isGroupEditable: false,
          isGroupReorderable: false,
        }
      )

      // Transform the group headers to a preview row, leave the dividers as is
      rows.push(
        ...groupingRows.map((row) => {
          if (row.type === SpreadsheetRowType.DIVIDER) {
            return row
          }
          return makePreviewLineItemGroup({
            row,
            change: group?.change ?? PreviewLineItemGroupChange.UNCHANGED,
          })
        })
      )

      const isChecked =
        change === PreviewLineItemChange.ADDED
          ? isAdditionRowIncludedInChangeSet(lineItem)
          : isExistingRowIncludedInChangeSet(lineItem.id)
      const onRowChecked = (isChecked: boolean) => {
        if (change === PreviewLineItemChange.ADDED) {
          handleAdditionRowChecked(lineItem, isChecked)
        } else {
          handleExistingRowChecked(lineItem.id, isChecked)
        }
      }

      // If there is an update for this line item, the row will render cells with the appropriate style
      const changeSetUpdate = changeSet.updates.find((update) => update.oldId === lineItem.id)
      const lineItemRow = getLineItemRow(billingType, lineItem, {
        includePreSitelineBillingColumn,
        includeMaterialsInStorageColumn,
        includeRetentionPercentColumn,
        isRetentionPercentEditable: false,
        includePreSitelineRetentionColumns,
        includeTotalBillingColumns: false,
        includeTotalRetainageColumn: false,
        includeChangeOrderColumn: false,
        includeChangeOrderApprovalColumn: true,
        isChangeOrderApprovalDateEditable: false,
        includeRevisedContractColumns,
        includeTaxGroupColumn,
        roundRetention: contract.roundRetention,
        timeZone,
        isFirstInUngroupedBlock,
        showWarningIfEmpty: false,
        t,
        isEditable: false,
        isReorderable: false,
        isRowSelected: isChecked,
        changeSetUpdate,
      })

      rows.push(
        makePreviewLineItemRow({
          row: lineItemRow,
          change,
          canChooseLineItemsToImport,
          isChecked,
          onRowChecked,
        })
      )

      return rows
    })

    // Some rows (like line item groups) can be appear multiple times in the preview, so we need
    // to give them unique IDs.
    // See https://www.loom.com/share/d61ddf1c07c942118e6989bea1b8ca8c
    previewLineItems.forEach((row, rowIndex) => {
      row.id = `${row.id}-${rowIndex}`
    })

    return previewLineItems
  }, [
    billingType,
    finalLineItems,
    changeSet.updates,
    changeSet.groupUpdates,
    finalGroups,
    columns.length,
    isAdditionRowIncludedInChangeSet,
    isExistingRowIncludedInChangeSet,
    includePreSitelineBillingColumn,
    includeMaterialsInStorageColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeRevisedContractColumns,
    includeTaxGroupColumn,
    contract?.roundRetention,
    timeZone,
    t,
    canChooseLineItemsToImport,
    handleAdditionRowChecked,
    handleExistingRowChecked,
  ])

  const content = useMemo(() => {
    return {
      rows: lineItemRows,
      footerRows: totalsRow ? [totalsRow] : [],
      enableReorderRows: false,
    }
  }, [lineItemRows, totalsRow])

  let title = t(`${i18nBase}.import_sov`, {
    integrationName,
  })
  if (onlyChangeOrders) {
    title = t(`${i18nBase}.import_change_orders`, {
      integrationName,
    })
  }

  const [numProposedChanges, areAllRowsSelected] = useMemo(() => {
    let areAllRowsSelected = true
    // Because the backend fetches a full changeSet (regardless of whether or not we are changing
    // anything - this is used to render the full SOV), we need to see which ones are included in
    // the updateChangeSet
    const selectedLineItems = finalLineItems.filter(({ lineItem, change }) => {
      let shouldInclude = false
      switch (change) {
        case PreviewLineItemChange.ADDED:
          shouldInclude = updateChangeSet.additions.some((addition) =>
            compareAdditionLineItem(addition.new, lineItem)
          )
          break
        case PreviewLineItemChange.DELETED:
          shouldInclude = updateChangeSet.deletions.some(
            (deletion) => deletion.oldId === lineItem.id
          )
          break
        case PreviewLineItemChange.UPDATED:
          shouldInclude = updateChangeSet.updates.some((update) => update.oldId === lineItem.id)
          break
        case PreviewLineItemChange.UNCHANGED:
          break
      }
      // If any added, updated, or deleted line item isn't included in the final changeset,
      // all selectable rows are not currently selected
      if (!shouldInclude && change !== PreviewLineItemChange.UNCHANGED) {
        areAllRowsSelected = false
      }
      return shouldInclude
    })

    const changedGroups = finalGroups.filter(
      ({ change }) => change !== PreviewLineItemGroupChange.UNCHANGED
    )

    return [selectedLineItems.length + changedGroups.length, areAllRowsSelected]
  }, [
    finalGroups,
    finalLineItems,
    updateChangeSet.additions,
    updateChangeSet.deletions,
    updateChangeSet.updates,
  ])

  const handleSelectAll = useCallback(() => {
    finalLineItems.forEach(({ lineItem, change }) => {
      const shouldCheck = !areAllRowsSelected
      switch (change) {
        case PreviewLineItemChange.ADDED: {
          const isChecked = updateChangeSet.additions.some((addition) =>
            compareAdditionLineItem(addition.new, lineItem)
          )
          if (isChecked !== shouldCheck) {
            handleAdditionRowChecked(lineItem, shouldCheck)
          }
          break
        }
        case PreviewLineItemChange.UPDATED: {
          const isChecked = updateChangeSet.updates.some((update) => update.oldId === lineItem.id)
          if (isChecked !== shouldCheck) {
            handleExistingRowChecked(lineItem.id, shouldCheck)
          }
          break
        }
        case PreviewLineItemChange.DELETED: {
          const isChecked = updateChangeSet.deletions.some(
            (deletion) => deletion.oldId === lineItem.id
          )
          if (isChecked !== shouldCheck) {
            handleExistingRowChecked(lineItem.id, shouldCheck)
          }
          break
        }
        case PreviewLineItemChange.UNCHANGED:
          break
      }
    })
  }, [
    finalLineItems,
    areAllRowsSelected,
    handleAdditionRowChecked,
    handleExistingRowChecked,
    updateChangeSet,
  ])

  // If the user can choose which line items to import, show the number of line items selected.
  // If importing all line items, show a legend with icons that show how a line item will be
  // modified.
  let subscript
  if (canChooseLineItemsToImport) {
    subscript = t(`${i18nBase}.num_changes`, { count: numProposedChanges })
  } else {
    subscript = (
      <div className={classes.legend}>
        <div>
          <AddLineItemIcon />
          <SitelineText variant="label" color="grey70">
            {t(`${i18nBase}.added_line_item`)}
          </SitelineText>
        </div>
        <div>
          <UpdateLineItemIcon />
          <SitelineText variant="label" color="grey70">
            {t(`${i18nBase}.updated_value`)}
          </SitelineText>
        </div>
        <div>
          <DeleteLineItemIcon />
          <SitelineText variant="label" color="grey70">
            {t(`${i18nBase}.removed_line_item`)}
          </SitelineText>
        </div>
      </div>
    )
  }

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      onSubmit={handleImport}
      submitting={importing}
      submitLabel={t(`${i18nBase}.import`)}
      title={title}
      subtitle={
        <SitelineText variant="body1" color="grey50" className="subtitle">
          {t(`${i18nBase}.review_changes`, {
            integrationName,
          })}
        </SitelineText>
      }
      disableSubmit={numProposedChanges === 0}
      disableSubmitTooltip={t(`${i18nBase}.submit_disabled`)}
      className={classes.root}
      popoverClassName="dialogPopover"
      subscript={subscript}
      maxWidth="xl"
    >
      <div className="content">
        {hasSignedPayApps && !onlyChangeOrders && (
          <SitelineAlert severity="warning" className="revertWarning">
            {t(`${i18nBase}.signed_pay_apps`)}
          </SitelineAlert>
        )}
        {canChooseLineItemsToImport && (
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={handleSelectAll}
            className={classes.selectAll}
            disabled={importing}
          >
            <SitelineText variant="label">
              {areAllRowsSelected
                ? t('common.actions.deselect_all')
                : t('common.actions.select_all')}
            </SitelineText>
          </Button>
        )}
        <div className="spreadsheetWrapper">
          <Spreadsheet columns={columns} content={content} loading={loading} blurOnClickAway />
        </div>
      </div>
    </SitelineDialog>
  )
}
