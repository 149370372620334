import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, evictWithGc, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { SitelineDialog } from '../../common/components/SitelineDialog'
import { useProjectContext } from '../../common/contexts/ProjectContext'
import {
  GetPayAppForCollectionsDocument,
  PayApp,
  PayAppActivityDocument,
  PayAppStatus,
  useMarkPayAppAsSubmittedMutation,
  useMarkPayAppAsSyncedInGcPortalMutation,
  useUpdatePayAppSubmittedAtMutation,
  useUpdatePayAppSyncedAtMutation,
} from '../../common/graphql/apollo-operations'
import { ContractForPayApps } from '../../components/billing/PayAppDetails'
import { ContractForProjectHome } from '../../components/billing/home/ProjectHome'
import { SendEmailDialogRow } from '../../components/billing/submit-dialog/SendEmailDialogRow'
import { trackMarkAsSubmitted } from '../util/MetricsTracking'
import { getMetricsForPayApp } from '../util/PayApp'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from './DatePickerInput'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .inputContainer': {
      gap: theme.spacing(4),
    },
    '& .submitRow': {
      '&.withBody': {
        marginTop: theme.spacing(3),
      },
      '& .datePicker': {
        marginTop: theme.spacing(0.5),
      },
    },
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.mark_as_submitted'

interface MarkAsSubmittedDialogProps {
  open: boolean
  onClose: () => void
  // Pick more variables than we explicitly use bc we need more for metrics
  payApp: Pick<
    PayApp,
    | 'id'
    | 'status'
    | 'statusChangedAt'
    | 'balanceToFinish'
    | 'retentionOnly'
    | 'payAppNumber'
    | 'billingType'
  >
  regenerateUnconditionalId?: string
  contract: ContractForProjectHome | ContractForPayApps
  gcPortalIntegrationId: string | null
  actionType: 'submitted' | 'synced'
}

/** A dialog for setting the submission date for a pay app and marking it as submitted */
export function MarkAsSubmittedDialog({
  open,
  onClose,
  payApp,
  contract,
  gcPortalIntegrationId,
  actionType,
}: MarkAsSubmittedDialogProps) {
  const classes = useStyles()
  const { timeZone } = useProjectContext()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()
  const [updatePayAppSubmittedAt] = useUpdatePayAppSubmittedAtMutation()
  const [updatePayAppSyncedAt] = useUpdatePayAppSyncedAtMutation()
  const isEditingDate =
    payApp.status === PayAppStatus.PROPOSED || payApp.status === PayAppStatus.SYNCED
  const initialDate = useMemo(
    () =>
      payApp.status === PayAppStatus.PROPOSED || payApp.status === PayAppStatus.SYNCED
        ? moment.tz(payApp.statusChangedAt, timeZone)
        : moment.tz(timeZone),
    [payApp.status, payApp.statusChangedAt, timeZone]
  )
  const initialSubmittedAtValue = useMemo(() => makeDatePickerValue(initialDate), [initialDate])
  const [submittedAtValue, setSubmittedAtValue] = useState<DatePickerValue>(initialSubmittedAtValue)
  const [markAsSubmitted, { loading: markingAsSubmitted }] = useMarkPayAppAsSubmittedMutation()
  const [markAsSyncedInGcPortal, { loading: markingAsSyncedInGcPortal }] =
    useMarkPayAppAsSyncedInGcPortalMutation()

  const action =
    actionType === 'submitted' ? t(`${i18nBase}.submitted_action`) : t(`${i18nBase}.synced_action`)

  const handleSubmit = useCallback(async () => {
    const { date: submittedAt } = submittedAtValue
    if (submittedAt === null) {
      return
    }

    if (actionType === 'submitted') {
      // If the pay app is already submitted, only need to update the date and then close the dialog
      if (payApp.status === PayAppStatus.PROPOSED) {
        try {
          await updatePayAppSubmittedAt({
            variables: {
              input: {
                payAppId: payApp.id,
                submittedAt: submittedAt.toISOString(),
              },
            },
            refetchQueries: [
              { query: GetPayAppForCollectionsDocument, variables: { payAppId: payApp.id } },
            ],
          })
          onClose()
        } catch (err) {
          snackbar.showError(err.message)
        }
        return
      }
      try {
        await markAsSubmitted({
          variables: {
            input: {
              payAppId: payApp.id,
              submittedAt: submittedAt.toISOString(),
            },
          },
          refetchQueries: [
            {
              query: PayAppActivityDocument,
              variables: {
                payAppId: payApp.id,
              },
            },
            { query: GetPayAppForCollectionsDocument, variables: { payAppId: payApp.id } },
          ],
          update(cache) {
            // If this is a final pay app, refetch fully billed contracts for the homepage since this
            // contract may now be included
            evictWithGc(cache, (evict) => {
              if (payApp.balanceToFinish === 0) {
                evict({ id: 'ROOT_QUERY', fieldName: 'fullyBilledContracts' })
                evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
              }
              evict({ id: cache.identify(contract), fieldName: 'firstPayAppBillingEnd' })
              evict({ id: cache.identify(contract), fieldName: 'hasStartedBilling' })
              evict({ id: cache.identify(contract), fieldName: 'progressRemaining' })
              evict({ id: cache.identify(contract), fieldName: 'totalOpenInvoiceAmount' })
              evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
              evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
              evict({ id: 'ROOT_QUERY', fieldName: 'aggregateBillingForecast' })
            })
          },
        })
        trackMarkAsSubmitted({
          ...getMetricsForPayApp(payApp, contract.project.id, contract.project.name),
          action: 'submitted',
        })
      } catch (err) {
        snackbar.showError(err.message)
      }
    } else {
      if (!gcPortalIntegrationId) {
        throw new Error('gcPortalIntegrationId is required to mark as synced')
      }
      // If the pay app is already synced, only need to update the date and then close the dialog
      if (payApp.status === PayAppStatus.SYNCED) {
        try {
          await updatePayAppSyncedAt({
            variables: {
              input: {
                payAppId: payApp.id,
                syncedAt: submittedAt.toISOString(),
              },
            },
            refetchQueries: [
              { query: GetPayAppForCollectionsDocument, variables: { payAppId: payApp.id } },
            ],
          })
          onClose()
        } catch (err) {
          snackbar.showError(err)
        }
        return
      }
      try {
        await markAsSyncedInGcPortal({
          variables: {
            input: {
              payAppId: payApp.id,
              integrationId: gcPortalIntegrationId,
              syncedAt: submittedAt.toISOString(),
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            markPayAppAsSyncedInGcPortal: {
              __typename: 'PayApp',
              id: payApp.id,
              status: PayAppStatus.SYNCED,
              statusChangedAt: submittedAt.toISOString(),
            },
          },
          refetchQueries: [
            {
              query: PayAppActivityDocument,
              variables: {
                payAppId: payApp.id,
              },
            },
            { query: GetPayAppForCollectionsDocument, variables: { payAppId: payApp.id } },
          ],
          update(cache) {
            // If this is a final pay app, refetch fully billed contracts for the homepage since this
            // contract may now be included
            evictWithGc(cache, (evict) => {
              if (payApp.balanceToFinish === 0) {
                evict({ id: 'ROOT_QUERY', fieldName: 'fullyBilledContracts' })
                evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
              }
              evict({ id: cache.identify(contract), fieldName: 'firstPayAppBillingEnd' })
              evict({ id: cache.identify(contract), fieldName: 'hasStartedBilling' })
              evict({ id: cache.identify(contract), fieldName: 'progressRemaining' })
              evict({ id: cache.identify(contract), fieldName: 'totalOpenInvoiceAmount' })
              evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
              evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
              evict({ id: 'ROOT_QUERY', fieldName: 'aggregateBillingForecast' })
            })
          },
        })
        trackMarkAsSubmitted({
          ...getMetricsForPayApp(payApp, contract.project.id, contract.project.name),
          action: 'submitted',
        })
      } catch (err) {
        snackbar.showError(err.message)
      }
    }

    onClose()
  }, [
    submittedAtValue,
    actionType,
    onClose,
    payApp,
    updatePayAppSubmittedAt,
    snackbar,
    markAsSubmitted,
    contract,
    gcPortalIntegrationId,
    updatePayAppSyncedAt,
    markAsSyncedInGcPortal,
  ])

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitting={markingAsSubmitted || markingAsSyncedInGcPortal}
      disableSubmit={isMissingDate(submittedAtValue)}
      title={
        isEditingDate
          ? t(`${i18nBase}.editing_title`, { action })
          : t(`${i18nBase}.dialog_title`, {
              payAppNumber: payApp.payAppNumber,
              action,
            })
      }
      submitLabel={isEditingDate ? t('common.actions.save') : t(`${i18nBase}.button`, { action })}
      maxWidth="sm"
    >
      <div className={classes.root}>
        {!isEditingDate && (
          <SitelineText variant="body1" color="grey50">
            {t(`${i18nBase}.dialog_description`)}
          </SitelineText>
        )}
        <div className="inputContainer">
          <SendEmailDialogRow
            label={t(`${i18nBase}.submitted_on`, {
              action: _.capitalize(action),
            })}
            alignLabel="center"
            className={clsx('submitRow', { withBody: !isEditingDate })}
          >
            <DatePickerInput
              value={submittedAtValue}
              onChange={setSubmittedAtValue}
              timeZone={timeZone}
              className="datePicker"
              maxDate={moment.tz(timeZone)}
            />
          </SendEmailDialogRow>
        </div>
      </div>
    </SitelineDialog>
  )
}
