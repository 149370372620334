import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import { Menu, MenuItem, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import {
  Dispatch,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import {
  SitelineTooltip,
  colors,
  fuseSearch,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { themeSpacing } from '../../themes/Main'
import { launchImmediateStateUpdate } from '../../util/State'
import { useSitelineConfirmation } from '../SitelineConfirmation'
import { cellTextColor } from '../SitelineTable.lib'
import { SPREADSHEET_BORDER_PADDING, SPREADSHEET_CELL_SIDE_PADDING } from './Spreadsheet'
import {
  SpreadsheetSelectColumnAddOption,
  SpreadsheetSelectColumnOption,
  SpreadsheetValue,
  ValidationFunction,
} from './Spreadsheet.lib'
import { useSpreadsheetContext } from './SpreadsheetContext'
import { SpreadsheetAction } from './SpreadsheetReducer'
import { CellWidthUpdate } from './SpreadsheetSizingReducer'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    width: '100%',
    '&.isEditable': {
      padding: SPREADSHEET_CELL_SIDE_PADDING,
    },
  },
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
  },
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
  cellContent: {
    // Match secondary style on SitelineText
    ...theme.typography.body2,
    color: colors.grey90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    '&.grey50': {
      color: colors.grey50,
    },
    '&.isBold': {
      fontWeight: 600,
    },
    '&.isStrikethrough': {
      textDecoration: 'line-through',
    },
    '&.isItalic': {
      fontStyle: 'italic',
    },
    '& .MuiSvgIcon-root': {
      marginLeft: theme.spacing(1.5),
      color: colors.grey50,
    },
  },
  dropdownIcon: {
    // Animate the arrow icon flipping around when the menu opens
    transition: theme.transitions.create('transform'),
    '&.menuOpen': {
      // Flip the dropdown arrow upside down when the menu opens
      transform: 'rotate(-180deg)',
    },
  },
  searchItem: {
    '&.MuiMenuItem-root.MuiButtonBase-root': {
      position: 'relative',
      padding: `${theme.spacing(1, 1, 0.5, 1)} !important`,
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      '& input': {
        fontSize: theme.typography.body2.fontSize,
        backgroundColor: colors.grey20,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.5, 1.5, 1.5, 4),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .searchIcon': {
        position: 'absolute',
        bottom: 0,
        top: theme.spacing(0.5),
        left: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        color: colors.grey40,
      },
    },
  },
  menu: {
    marginTop: SPREADSHEET_BORDER_PADDING,
  },
  menuInner: {
    '& .selectOption': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& .divider': {
      backgroundColor: colors.grey30,
      height: 1,
    },
    '& .menuItem': {
      // Override default menu item padding, which already has the !important flag
      padding: `${themeSpacing(1.5)}px ${themeSpacing(2)}px !important`,
      '&.isSelected': {
        backgroundColor: colors.blue50,
        color: colors.white,
      },
    },
    '& .optionsList': {
      maxHeight: 220,
      overflow: 'auto',
    },
  },
}))

const MIN_SEARCH_OPTIONS = 5

interface SpreadsheetSelectCellProps {
  rowId: string
  value: SpreadsheetValue
  columnId: string
  onCellWidthChange: (cellWidth: CellWidthUpdate) => void
  dispatch: Dispatch<SpreadsheetAction>
  isEditing: boolean
  isSelected: boolean
  validate?: ValidationFunction
  isGroupHeaderRow?: boolean
  initialValue?: string
  bold?: boolean
  strikethrough?: boolean
  italic?: boolean
  className?: string
  color?: 'grey90' | 'grey50' | 'grey70' | 'red50'
  textAlign?: 'left' | 'center' | 'right'
  tooltipTitle?: string
  onBeforeSave?: (params: {
    onSave: () => void
    onCancel: () => void
    rowId: string
    columnId: string
    fromValue: SpreadsheetValue
    toValue: SpreadsheetValue
  }) => void
  isGrowColumn: boolean
  isEditable: boolean
  options: SpreadsheetSelectColumnOption[]
  addOption?: SpreadsheetSelectColumnAddOption
  shouldHideFromNewOptions?: (option: SpreadsheetSelectColumnOption) => boolean
  searchPlaceholder?: string
  allowEmpty?: boolean
}

/** A cell in a spreadsheet that allows selecting from options in a dropdown */
export const SpreadsheetSelectCell = memo(function SpreadsheetSelectCell(
  props: SpreadsheetSelectCellProps
) {
  const {
    rowId,
    value,
    columnId,
    onCellWidthChange,
    dispatch,
    isEditing,
    isSelected,
    validate,
    isGroupHeaderRow,
    initialValue,
    bold,
    strikethrough,
    italic,
    className,
    color,
    tooltipTitle,
    onBeforeSave,
    isGrowColumn,
    isEditable,
    options,
    addOption,
    searchPlaceholder,
    allowEmpty,
  } = props
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()
  const { t } = useTranslation()
  const { pauseFocus, resumeFocus } = useSpreadsheetContext()
  const initialCurrentOptionId = useMemo(() => {
    const option = options.find((option) => option.id === value)
    return option ? option.id : null
  }, [options, value])
  const [currentOptionId, setCurrentOptionId] = useState<string | null>(initialCurrentOptionId)
  const currentOptionLabel = useMemo(() => {
    const option = options.find((option) => option.id === currentOptionId)
    if (option) {
      return option.label
    }
    return t('common.none')
  }, [currentOptionId, options, t])
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [search, setSearch] = useState<string>('')

  // Update the current value if a new value is passed in
  useEffect(() => {
    setCurrentOptionId(initialCurrentOptionId)
  }, [initialCurrentOptionId])

  // Update the current value if a new initial value is provided
  useEffect(() => {
    if (initialValue) {
      const option = options.find((option) => option.id === initialValue)
      if (option) {
        launchImmediateStateUpdate(() => setCurrentOptionId(option.id))
      }
    }
  }, [initialValue, snackbar, t, options])

  const cellRef = useRef<HTMLDivElement | null>(null)

  const closeMenu = useCallback(() => {
    setAnchorEl(null)
    // Wait a moment before resetting the menu and selecting an option so there isn't a
    // jumpy visual change in the menu before it closes
    setTimeout(() => {
      setSearch('')
    }, 200)
  }, [])

  // If switching to edit mode (e.g. because the user clicked Enter while the cell was selected),
  // open the menu
  useEffect(() => {
    if (isEditing && cellRef.current) {
      setAnchorEl(cellRef.current)
    } else if (!isSelected) {
      closeMenu()
    }
  }, [closeMenu, isEditing, isSelected])

  const handleReset = useCallback(
    () => setCurrentOptionId(initialCurrentOptionId),
    [initialCurrentOptionId]
  )

  // Show the search row if there are a minimum number of options and a placeholder is provided
  const enableSearch = options.length >= MIN_SEARCH_OPTIONS && searchPlaceholder

  const filteredOptions = useMemo(() => {
    const optionsWithoutHidden = options.filter(
      (option) => option.id === currentOptionId || !option.shouldHideFromOptions
    )
    if (!enableSearch || !search) {
      return optionsWithoutHidden
    }
    return fuseSearch(optionsWithoutHidden, search, ['label'], { ignoreLocation: true })
  }, [currentOptionId, enableSearch, options, search])

  const handleEditValue = useCallback(
    (toOptionId: string) => {
      const option = options.find((option) => option.id === toOptionId)
      setCurrentOptionId(option ? option.id : null)
      if (onBeforeSave) {
        onBeforeSave({
          onSave: () => {
            dispatch({ type: 'EDITED_CELL', toValue: toOptionId })
          },
          onCancel: () => {
            handleReset()
            dispatch({ type: 'STOP_EDITING' })
          },
          rowId,
          fromValue: value,
          toValue: toOptionId,
          columnId,
        })
        return
      }
      dispatch({ type: 'EDITED_CELL', toValue: toOptionId })
    },
    [options, onBeforeSave, dispatch, rowId, value, columnId, handleReset]
  )

  const handleSave = useCallback(
    (saveOptionId?: string) => {
      // The empty string is a valid option value, representing no option selected
      let toOptionId = saveOptionId ?? currentOptionId ?? ''
      if (validate) {
        const validated = validate(toOptionId)
        if (validated !== null) {
          switch (validated.type) {
            case 'error': {
              const { message } = validated
              // Wrap the error snackbar in a timeout so it shows up even when the save is triggered
              // by a click event. Otherwise, the blur event triggers before the click event, and the
              // click is caught by the snackbar as a clickaway, closing the snackbar immediately.
              setTimeout(() => {
                snackbar.showError(message)
              }, 500)
              handleReset()
              dispatch({ type: 'STOP_EDITING' })
              return
            }
            case 'confirm': {
              const { title, details, confirmationType, confirmLabel } = validated
              pauseFocus()
              confirm({
                title,
                details,
                confirmationType,
                confirmLabel,
                callback: (confirmed) => {
                  resumeFocus()
                  if (confirmed) {
                    handleEditValue(toOptionId)
                  } else {
                    handleReset()
                    dispatch({ type: 'STOP_EDITING' })
                  }
                },
              })
              return
            }
            case 'override': {
              toOptionId = validated.value as string
              break
            }
          }
        }
      }
      handleEditValue(toOptionId)
    },
    [
      currentOptionId,
      snackbar,
      dispatch,
      handleReset,
      validate,
      handleEditValue,
      confirm,
      pauseFocus,
      resumeFocus,
    ]
  )

  const lastWidth = useRef<number>()
  const cellSizing = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        const size = element.getBoundingClientRect()
        if (size.width !== lastWidth.current) {
          onCellWidthChange({ rowId, columnId, width: size.width })
          lastWidth.current = size.width
        }
      }
    },
    [columnId, onCellWidthChange, rowId]
  )

  const handleOptionChange = useCallback(
    (optionId: string) => {
      handleSave(optionId)
      closeMenu()
      // We use flush sync to ensure the state change happens immediately and React doesn't try to
      // batch it with later state changes
      launchImmediateStateUpdate(() => setCurrentOptionId(optionId))
    },
    [closeMenu, handleSave]
  )

  const contentStyle = useMemo(
    () => ({ color: currentOptionId ? cellTextColor(color) : colors.grey50 }),
    [color, currentOptionId]
  )

  const content = (
    // Avoid using SitelineText here as a performance optimization
    <span
      className={clsx(classes.cellContent, className, {
        isBold: isGroupHeaderRow || bold,
        isStrikethrough: strikethrough,
        isItalic: italic,
      })}
      style={contentStyle}
    >
      <span>{currentOptionLabel}</span>
      {isEditable && (
        <ArrowDropDownIcon
          className={clsx(classes.dropdownIcon, { menuOpen: anchorEl !== null })}
        />
      )}
    </span>
  )

  // For click events, we do not want to stop event propagation, as the event is also used
  // by the spreadsheet for selecting and de-selecting the cell
  const handleClickCell = useCallback(
    (evt: MouseEvent<HTMLDivElement>) => setAnchorEl(evt.currentTarget),
    []
  )

  const handleAddOptionClick = useCallback(() => {
    if (!addOption) {
      return
    }
    addOption.onClick(rowId)
    setAnchorEl(null)
  }, [addOption, rowId])

  return (
    <>
      <div
        ref={cellRef}
        className={clsx(classes.root, { isEditable })}
        onClick={isEditable ? handleClickCell : undefined}
      >
        <SitelineTooltip title={tooltipTitle} placement="top">
          {content}
        </SitelineTooltip>
      </div>
      {isEditable && (
        <Menu
          className={classes.menu}
          open={anchorEl !== null}
          onClose={closeMenu}
          anchorEl={anchorEl}
        >
          <div className={classes.menuInner}>
            {enableSearch && (
              <MenuItem
                className={classes.searchItem}
                // Don't focus this menu item, since the input inside already claims focus
                tabIndex={-1}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={searchPlaceholder}
                  autoFocus={isDesktop}
                  value={search}
                  onChange={(ev) => setSearch(ev.target.value)}
                />
                <div className="searchIcon">
                  <SearchIcon />
                </div>
              </MenuItem>
            )}
            <div className="optionsList">
              {allowEmpty && (
                <MenuItem
                  onClick={() => handleOptionChange('')}
                  className={clsx('menuItem', { isSelected: !currentOptionId })}
                >
                  {t('common.none')}
                </MenuItem>
              )}
              {filteredOptions.map(({ id, label }) => (
                <MenuItem
                  key={id}
                  onClick={() => handleOptionChange(id)}
                  className={clsx('menuItem', { isSelected: id === currentOptionId })}
                >
                  <div className="selectOption">{label}</div>
                </MenuItem>
              ))}
            </div>
            {addOption && (
              <>
                <div className="divider" />
                <MenuItem className="menuItem" onClick={handleAddOptionClick}>
                  <div className="addOption">
                    <AddIcon />
                    {addOption.label}
                  </div>
                </MenuItem>
              </>
            )}
          </div>
        </Menu>
      )}
      {/* Hidden content for sizing the cell */}
      {!isGrowColumn && (
        <div
          // Make sure we cell sizing function is called any time the option changes so the
          // spreadsheet sizing can adjust
          key={currentOptionId}
          ref={cellSizing}
          className={classes.hidden}
        >
          {content}
        </div>
      )}
    </>
  )
})
