import { TFunction } from 'i18next'
import {
  decimalToPercent,
  percentToDecimal,
  TAX_RATE_DECIMAL_PRECISION,
  TAX_RATE_PERCENT_PRECISION,
  type integrationTypes,
} from 'siteline-common-all'
import {
  BulkCreateTaxGroupInput,
  CompanyIntegrationProperties,
  TaxGroupProperties,
} from '../graphql/apollo-operations'
import {
  ImportTemplateDataType,
  ImportTemplateHeaderToColumnConfig,
  jsonToImportMetadata,
} from './ImportFromTemplate'

/**
 * Given a tax group and a list of company integrations, return the company integrations that the
 * tax group has a corresponding integration mapping for
 */
export function getTaxGroupIntegrations<T extends Pick<CompanyIntegrationProperties, 'id'>>(
  taxGroup: TaxGroupProperties,
  companyIntegrations: T[]
): T[] {
  const mappings = taxGroup.integrationMappings as integrationTypes.TaxGroupIntegrationMappings
  return companyIntegrations.filter((integration) =>
    mappings.integrations.some(
      (mappingIntegration) => mappingIntegration.companyIntegrationId === integration.id
    )
  )
}

/** Convert a tax rate percent to decimal with the necessary precision */
export function taxGroupPercentToDecimal(percent: number): number {
  return percentToDecimal(percent, TAX_RATE_DECIMAL_PRECISION)
}

/** Given a tax group, creates a label with its name and percentage. If null, returns 'none' */
export function formatTaxGroupLabel(taxGroup: TaxGroupProperties | null, t: TFunction): string {
  if (taxGroup === null) {
    return t('common.none')
  }
  return `${taxGroup.name} (${decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION)}%)`
}

enum TaxGroupsImportHeader {
  NAME = 'Name',
  TAX_RATE = 'Tax rate (%)',
}

const headersToColumnConfig = (): ImportTemplateHeaderToColumnConfig<
  TaxGroupsImportHeader,
  BulkCreateTaxGroupInput
> => ({
  [TaxGroupsImportHeader.NAME]: {
    metadataKey: 'name',
    isRequired: true,
    dataType: ImportTemplateDataType.STRING,
  },
  [TaxGroupsImportHeader.TAX_RATE]: {
    metadataKey: 'taxPercent',
    isRequired: true,
    dataType: ImportTemplateDataType.PERCENT,
  },
})

/** Converts a JSON object to a change order log */
export function jsonToTaxGroups(
  json: (string | number | undefined)[][],
  t: TFunction
): BulkCreateTaxGroupInput[] {
  return jsonToImportMetadata({
    json,
    headersToColumnConfig: headersToColumnConfig(),
    t,
  })
}
