import AddIcon from '@mui/icons-material/Add'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button, MenuItem, Select, SelectProps, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Permission,
  SitelineText,
  colors,
  evictWithGc,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import {
  DatePickerInput,
  DatePickerValue,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { RouterLink } from '../../../common/components/RouterLink'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  ContractPaymentTermsType,
  useUpdateContractMutation,
} from '../../../common/graphql/apollo-operations'
import { formatLocationOneLineWithName } from '../../../common/util/Location'
import { CompanySettingsTab, getCompanySettingsPath } from '../../settings/Settings.lib'
import { ContractForProjectHome } from '../home/ProjectHome'
import { EditablePaymentTerms } from '../onboarding/EditablePaymentTerms'
import { AddContractFilesDialog } from './AddContractFilesDialog'
import { InlineContractFilesPreview } from './InlineContractFilesPreview'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(-1, 0),
  },
  textInput: {
    overflow: 'hidden',
    flexGrow: 1,
  },
  error: {
    marginTop: theme.spacing(1.5),
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      maxWidth: 'initial',
    },
  },
  addressesRow: {
    width: '100%',
    overflow: 'hidden',
    padding: theme.spacing(1, 0),
    margin: theme.spacing(-1, 0),
  },
  addresses: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(-1, 0),
    overflow: 'hidden',
    '& .select': {
      marginRight: theme.spacing(2),
      flexGrow: 1,
    },
    '& .editInSettings': {
      marginLeft: theme.spacing(2),
      whiteSpace: 'nowrap',
    },
  },
  numberInput: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    margin: theme.spacing(-1, 0),
    '& input': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      height: theme.spacing(5),
      width: 60,
      ...theme.typography.body1,
    },
  },
  contractFilesRow: {
    width: '100%',
    '& .editingContractFiles': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .uploadMoreButton': {
      marginBottom: theme.spacing(2),
      width: 'fit-content',
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.contract'

const menuProps: SelectProps['MenuProps'] = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  transformOrigin: {
    horizontal: 'left',
    vertical: 'top',
  },
}

interface ContractProps {
  contract?: ContractForProjectHome
}

/** Shows a project's contract and allows you to edit some parts of it. */
export function Contract({ contract }: ContractProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const { isContractActive, timeZone } = useProjectContext()
  const { company, permissions } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  const [updateContractMutation] = useUpdateContractMutation()

  const locations = company?.locations ?? []

  const initialSelectedAddressId = useMemo(
    () => contract?.selectedAddress.id ?? '',
    [contract?.selectedAddress.id]
  )
  const [selectedAddressId, setSelectedAddressId] = useState<string>(initialSelectedAddressId)
  const selectedAddress = locations.find((location) => location.id === selectedAddressId)

  const initialContractNumber = useMemo(
    () => contract?.contractNumber ?? '',
    [contract?.contractNumber]
  )
  const [contractNumber, setContractNumber] = useState<string>(initialContractNumber)

  const initialContractDateValue = useMemo(() => {
    if (!contract?.contractDate) {
      return makeDatePickerValue(null)
    }
    const date = moment.tz(contract.contractDate, timeZone)
    return makeDatePickerValue(date)
  }, [contract?.contractDate, timeZone])

  const [contractDateValue, setContractDateValue] =
    useState<DatePickerValue>(initialContractDateValue)

  const initialPaymentTermsType = useMemo(
    () => contract?.paymentTermsType ?? null,
    [contract?.paymentTermsType]
  )
  const [paymentTermsType, setPaymentTermsType] = useState<ContractPaymentTermsType | null>(
    initialPaymentTermsType
  )

  const initialPaymentTerms = useMemo(
    () => contract?.paymentTerms ?? null,
    [contract?.paymentTerms]
  )
  const [paymentTerms, setPaymentTerms] = useState<number | null>(initialPaymentTerms)

  const initialVendorNumber = useMemo(
    () => contract?.vendorNumber ?? null,
    [contract?.vendorNumber]
  )
  const [vendorNumber, setVendorNumber] = useState<string | null>(initialVendorNumber)

  const [isContractDialogOpen, handleOpenContractDialog, handleCloseContractDialog] = useToggle()

  const resetState = useCallback(() => {
    setSelectedAddressId(initialSelectedAddressId)
    setPaymentTermsType(initialPaymentTermsType)
    setPaymentTerms(initialPaymentTerms)
    setVendorNumber(initialVendorNumber)
    setContractNumber(initialContractNumber)
    setContractDateValue(initialContractDateValue)
    setHasEdited(false)
  }, [
    initialContractDateValue,
    initialContractNumber,
    initialPaymentTerms,
    initialPaymentTermsType,
    initialSelectedAddressId,
    initialVendorNumber,
  ])

  const handleSave = useCallback(async () => {
    const { date: contractDate } = contractDateValue
    if (!contract) {
      return
    }

    const updatedVendorNumber = vendorNumber === '' ? null : vendorNumber
    const updatedContractNumber = contractNumber === '' ? null : contractNumber

    // Only update contract if different
    const didUpdateAddress = selectedAddressId !== contract.selectedAddress.id
    const didUpdatePaymentTermsType = paymentTermsType !== contract.paymentTermsType
    const didUpdatePaymentTerms = paymentTerms !== contract.paymentTerms
    const didUpdateVendorNumber = updatedVendorNumber !== contract.vendorNumber
    const didUpdateContractNumber = updatedContractNumber !== contract.contractNumber
    const didUpdateContractDate = (contractDate?.toISOString() ?? null) !== contract.contractDate

    if (
      !didUpdateAddress &&
      !didUpdatePaymentTermsType &&
      !didUpdatePaymentTerms &&
      !didUpdateVendorNumber &&
      !didUpdateContractNumber &&
      !didUpdateContractDate
    ) {
      return
    }

    try {
      await updateContractMutation({
        variables: {
          input: {
            id: contract.id,
            selectedAddressId: didUpdateAddress ? selectedAddressId : undefined,
            paymentTermsType: didUpdatePaymentTermsType ? paymentTermsType : undefined,
            paymentTerms: didUpdatePaymentTerms ? paymentTerms : undefined,
            vendorNumber: didUpdateVendorNumber ? updatedVendorNumber : undefined,
            contractDate: didUpdateContractDate ? (contractDate?.toISOString() ?? null) : undefined,
            contractNumber: didUpdateContractNumber ? updatedContractNumber : undefined,
          },
        },
        update(cache, { data }) {
          if (!data) {
            return
          }
          evictWithGc(cache, (evict) => {
            contract.payApps.forEach((payApp) => {
              evict({ id: cache.identify(payApp), fieldName: 'collectionsNotifications' })
            })
          })
        },
      })
      snackbar.showSuccess(t(`${i18nBase}.updated`))
      setHasEdited(false)
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [
    contractDateValue,
    contract,
    vendorNumber,
    contractNumber,
    selectedAddressId,
    paymentTermsType,
    paymentTerms,
    updateContractMutation,
    snackbar,
    t,
  ])

  // Update the state when the contract fields change
  useEffect(() => {
    setSelectedAddressId(initialSelectedAddressId)
    setPaymentTermsType(initialPaymentTermsType)
    setPaymentTerms(initialPaymentTerms)
    setVendorNumber(initialVendorNumber)
    setContractNumber(initialContractNumber)
    setContractDateValue(initialContractDateValue)
  }, [
    initialContractDateValue,
    initialContractNumber,
    initialPaymentTerms,
    initialPaymentTermsType,
    initialSelectedAddressId,
    initialVendorNumber,
  ])

  const contractFiles = useMemo(() => {
    if (!contract) {
      return []
    }
    return contract.files
  }, [contract])

  const hasContractFiles = !!contract && contractFiles.length > 0

  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
    }),
    [handleSave, hasEdited, resetState]
  )

  const paymentTermsText = useMemo(() => {
    switch (paymentTermsType) {
      case ContractPaymentTermsType.NET_PAYMENT:
        // `paymentTerms` should always be non-null if `paymentTermsType` is `NET_PAYMENT`
        return t(`${i18nBase}.net_days`, { count: paymentTerms ?? 0 })
      case ContractPaymentTermsType.PAY_WHEN_PAID: {
        if (paymentTerms !== null) {
          return t(`${i18nBase}.pay_when_paid_days_expected`, { count: paymentTerms })
        } else {
          return t(`${i18nBase}.pay_when_paid`)
        }
      }
      case ContractPaymentTermsType.PAY_IF_PAID: {
        if (paymentTerms !== null) {
          return t(`${i18nBase}.pay_if_paid_days_expected`, { count: paymentTerms })
        } else {
          return t(`${i18nBase}.pay_if_paid`)
        }
      }
      case null:
        return undefined
    }
  }, [paymentTerms, paymentTermsType, t])

  const handlePaymentTermsChange = ({
    paymentTermsType,
    paymentTerms,
  }: {
    paymentTermsType?: ContractPaymentTermsType | null
    paymentTerms?: number | null
  }) => {
    if (paymentTermsType !== undefined) {
      setPaymentTermsType(paymentTermsType)
    }
    if (paymentTerms !== undefined) {
      setPaymentTerms(paymentTerms)
    }
    setHasEdited(true)
  }

  const editablePaymentTerms = useMemo(
    () => ({ paymentTermsType, paymentTerms }),
    [paymentTerms, paymentTermsType]
  )

  const hasCollectionsReminders = !contract || contract.collectionsNotifications.length > 0
  const isMissingPaymentTerms =
    (paymentTermsType === ContractPaymentTermsType.NET_PAYMENT && !paymentTerms) ||
    (hasCollectionsReminders && !paymentTerms)

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.title`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={bulkSaveProps}
        disableSave={isMissingPaymentTerms}
      />
      <SettingsRow
        label={t(`${i18nBase}.subcontractor_address`)}
        isLoading={!contract}
        value={selectedAddress ? formatLocationOneLineWithName(selectedAddress) : null}
        isEditing={isEditing}
        innerClassName={classes.addressesRow}
        editingValue={
          <div className={classes.addresses}>
            <Select
              variant="outlined"
              value={selectedAddressId}
              onChange={(event) => {
                setSelectedAddressId(event.target.value as string)
                setHasEdited(true)
              }}
              className={clsx(classes.input, classes.textInput)}
              MenuProps={menuProps}
            >
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id} className={classes.menuItem}>
                  {formatLocationOneLineWithName(location)}
                </MenuItem>
              ))}
            </Select>
            <RouterLink
              underline="none"
              {...getCompanySettingsPath({ tab: CompanySettingsTab.OFFICES })}
            >
              <Button
                variant="text"
                color="secondary"
                endIcon={<LaunchIcon fontSize="small" />}
                className="editInSettings"
              >
                <SitelineText variant="button" color="grey50">
                  {t(`${i18nBase}.edit_in_settings`)}
                </SitelineText>
              </Button>
            </RouterLink>
          </div>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.contract_number`)}
        isLoading={!contract}
        value={contractNumber || null}
        isEditing={isEditing}
        editingValue={
          <>
            <TextField
              className={classes.input}
              variant="outlined"
              value={contractNumber}
              onChange={(ev) => {
                setContractNumber(ev.target.value)
                setHasEdited(true)
              }}
            />
          </>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.contract_date`)}
        isLoading={!contract}
        value={
          contract?.contractDate
            ? moment.tz(contract.contractDate, timeZone).format('MMM D, YYYY')
            : null
        }
        isEditing={isEditing}
        editingValue={
          contract && (
            <div className={classes.input}>
              <DatePickerInput
                value={contractDateValue}
                onChange={(dateValue) => {
                  setContractDateValue(dateValue)
                  setHasEdited(true)
                }}
                timeZone={timeZone}
                isEmptyValueValid
              />
            </div>
          )
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.payment_terms`)}
        isLoading={!contract}
        value={paymentTermsText && <SitelineText variant="body1">{paymentTermsText}</SitelineText>}
        isEditing={isEditing}
        editingValue={
          <div className={classes.input}>
            <EditablePaymentTerms
              paymentTerms={editablePaymentTerms}
              onPaymentTermsChange={handlePaymentTermsChange}
              isMissingPaymentTerms={false}
              required={!contract || contract.collectionsNotifications.length > 0}
            />
          </div>
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.vendor_number`)}
        isLoading={!contract}
        value={vendorNumber}
        isEditing={isEditing}
        editingValue={
          <TextField
            className={clsx(classes.input, classes.textInput)}
            variant="outlined"
            value={vendorNumber ?? ''}
            onChange={(ev) => {
              setVendorNumber(ev.target.value)
              setHasEdited(true)
            }}
          />
        }
      />
      <SettingsRow
        label={t(`${i18nBase}.contract_files`)}
        isLoading={!contract}
        isEditing={isEditing}
        innerClassName={classes.contractFilesRow}
        value={
          hasContractFiles ? (
            <InlineContractFilesPreview contract={contract} canEdit={isEditing} />
          ) : undefined
        }
        editingValue={
          <div className="editingContractFiles">
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleOpenContractDialog}
              className={clsx(classes.input, 'uploadMoreButton')}
            >
              {hasContractFiles ? t(`${i18nBase}.upload_more`) : t(`${i18nBase}.upload`)}
            </Button>
            {hasContractFiles && (
              <InlineContractFilesPreview canEdit={isEditing} contract={contract} />
            )}
          </div>
        }
      />
      {contract && (
        <AddContractFilesDialog
          contract={contract}
          open={isContractDialogOpen}
          onClose={handleCloseContractDialog}
        />
      )}
    </div>
  )
}
