import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import EditIcon from '@mui/icons-material/Edit'
import { Button, IconButton, Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useParams } from '@tanstack/react-router'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BillingType, formatCentsToDollars, safeDivide } from 'siteline-common-all'
import {
  ButtonLabelSpinner,
  Row,
  SitelineSearch,
  SitelineText,
  colors,
  makeStylesFast,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { DollarNumberFormat } from '../../common/components/NumberFormat'
import { Prompt } from '../../common/components/Prompt'
import { Sidesheet } from '../../common/components/Sidesheet'
import { useSitelineConfirmation } from '../../common/components/SitelineConfirmation'
import { useSpreadsheetContext } from '../../common/components/Spreadsheet/SpreadsheetContext'
import { StatusBanner } from '../../common/components/StatusBanner'
import { useBillingWorksheetContext } from '../../common/contexts/BillingWorksheetContext'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import { useUserRoleContext } from '../../common/contexts/UserRoleContext'
import {
  Permission,
  useGetContractForPayAppQuery,
  useGetPayAppForProgressQuery,
  useUpdateWorksheetLineItemsMutation,
} from '../../common/graphql/apollo-operations'
import {
  EditingWorksheet,
  editingWorksheetToUpdateWorksheetInput,
  sovLineItemToEditingWorksheetSovLineItem,
  worksheetLineItemsFromWorksheet,
} from '../../common/util/BillingWorksheet'
import { trackEditedWorksheet, trackWorksheetViewed } from '../../common/util/MetricsTracking'
import { getMetricsForPayApp } from '../../common/util/PayApp'
import { FieldGuestInvoice } from '../field-worker-app/pay-app/FieldGuestInvoice'

export const BILLING_WORKSHEET_SIDEBAR_WIDTH_PERCENT = 50
const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .statusBanner': {
      marginBottom: theme.spacing(2),
    },
    '& .topContent': {
      margin: theme.spacing(2, 0, 4),
      // Add a min height of the size of the edit buttons so the sidebar doesn't shift when
      // switching to edit mode
      minHeight: 40,
    },
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '& .itemSwitcher': {
        whiteSpace: 'nowrap',
      },
      '& .nameAndEdit': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      },
      '& .lineItemCode': {
        color: colors.grey50,
      },
      '& .editButtons': {
        display: 'flex',
        gap: theme.spacing(1),
      },
    },
    '& .spreadsheetContainer': {
      '& .searchBar': {
        width: '100%',
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      margin: theme.spacing(0, -2),
    },
  },
}))

const SIDEBAR_WORKSHEET_SPREADSHEET_ID = 'worksheetSpreadsheet'
const i18nBase = 'projects.subcontractors.worksheet'

export function BillingWorksheetSidesheet() {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()
  const { companyId, permissions } = useCompanyContext()
  const { focusSpreadsheet, removeSpreadsheet } = useSpreadsheetContext()
  const { userRole } = useUserRoleContext()
  const { viewingSovLineItemId, onViewSovLineItemWorksheet, onCloseSidebar } =
    useBillingWorksheetContext()
  const { projectId, payAppId } = useParams({
    strict: false,
    select: (params) => {
      if (!params.projectId) {
        throw new Error('Missing projectId in URL params')
      }
      return {
        projectId: params.projectId,
        payAppId: params.payAppId,
      }
    },
  })

  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const [clearSearch, setClearSearch] = useState<number>(1)
  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [hasTrackedPageView, setHasTrackedPageView] = useState<boolean>(false)

  const [updateWorksheet] = useUpdateWorksheetLineItemsMutation()

  const { data, loading: loadingContract } = useGetContractForPayAppQuery({
    variables: { input: { projectId, companyId } },
  })

  const { data: payAppData, loading: loadingPayApp } = useGetPayAppForProgressQuery({
    variables: { payAppId: payAppId ?? '' },
    skip: !payAppId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  // When the sidebar is open, we want the worksheet spreadsheet to be in focus
  const isSidebarOpen = viewingSovLineItemId !== null
  const contract = data?.contractByProjectId
  const payApp = payAppData?.payApp ?? null
  const loading = loadingContract || loadingPayApp
  const isPayAppRetentionOnly = payApp?.retentionOnly
  // If the user is editing a pay app that is retention only, they should not be able to edit
  // worksheet progress. They also can't edit if the don't have the permission to edit
  const canEdit = permissions.includes(Permission.EDIT_INVOICE) && !isPayAppRetentionOnly

  const sovLineItems = useMemo(() => {
    if (!contract?.sov || !viewingSovLineItemId) {
      return []
    }
    // If a pay app is provided, then we're viewing the worksheet from a pay app invoice. In that
    // case, we filter the SOV line items to only include ones that have a progress on this pay app
    // (so it's not possible to navigate to the worksheet for a change order that doesn't exist
    // on this pay app). If no pay app is provided, we're viewing the SOV and show all line items.
    const sovLineItemIdsWithProgress = new Set(
      payApp ? payApp.progress.map((progressLineItem) => progressLineItem.sovLineItem.id) : []
    )
    return _.chain([...contract.sov.lineItems])
      .filter((lineItem) => {
        // If we're viewing a pay app, we only want to show the worksheet for SOV line items that
        // have progress on this pay app. If there's no pay app, we include all SOV line items.
        if (payAppId && !sovLineItemIdsWithProgress.has(lineItem.id)) {
          return false
        }
        // If we're viewing a pay app, exclude line items that don't have worksheet line items since
        // there will be nothing to bill in the sidebar.
        if (payAppId && lineItem.worksheetLineItems.length === 0) {
          return false
        }
        return true
      })
      .orderBy((lineItem) => lineItem.sortOrder)
      .value()
  }, [contract?.sov, payApp, payAppId, viewingSovLineItemId])

  const sovLineItemIndex = useMemo(
    () => sovLineItems.findIndex((item) => item.id === viewingSovLineItemId),
    [sovLineItems, viewingSovLineItemId]
  )

  const sovLineItem = useMemo(
    () => (sovLineItemIndex !== -1 ? sovLineItems[sovLineItemIndex] : null),
    [sovLineItemIndex, sovLineItems]
  )

  useEffect(() => {
    if (isSidebarOpen) {
      focusSpreadsheet(SIDEBAR_WORKSHEET_SPREADSHEET_ID)
    } else {
      removeSpreadsheet(SIDEBAR_WORKSHEET_SPREADSHEET_ID)
    }
  }, [focusSpreadsheet, isSidebarOpen, removeSpreadsheet])

  // Track landing on the page
  useEffect(() => {
    if (isSidebarOpen && contract !== undefined && !hasTrackedPageView) {
      const { billingType, project } = contract
      const { name: projectName, id: projectId } = project
      const payAppMetrics = payApp
        ? getMetricsForPayApp(payApp, contract.project.id, contract.project.name)
        : undefined
      const sidesheetType = payApp === null ? ('sov' as const) : ('invoice' as const)

      trackWorksheetViewed({
        payAppMetrics,
        projectId,
        projectName,
        billingType,
        userRole,
        isSidesheet: true,
        sidesheetType,
      })
      setHasTrackedPageView(true)
    }
  }, [isSidebarOpen, contract, hasTrackedPageView, payApp, userRole])

  const handleLastItem = useCallback(
    () => onViewSovLineItemWorksheet(sovLineItems[sovLineItemIndex - 1].id),
    [onViewSovLineItemWorksheet, sovLineItemIndex, sovLineItems]
  )
  const handleNextItem = useCallback(
    () => onViewSovLineItemWorksheet(sovLineItems[sovLineItemIndex + 1].id),
    [onViewSovLineItemWorksheet, sovLineItemIndex, sovLineItems]
  )

  // Only show a column for pre-Siteline billing if there were past pay apps before the project was
  // onboarded to Siteline. If this is the first pay app, there shouldn't be any previous billing.
  const hasPastPayApps = contract !== undefined && contract.pastPayAppCount > 0

  // We only allow editing the worksheet itself (i.e. the worksheet line item metadata)
  // when viewing the SOV. When viewing the sidebar from a pay app invoice, we only allow
  // editing the progress billed.
  const isSovWorksheet = payApp === null
  const hasWorksheetLineItems = !!sovLineItem && sovLineItem.worksheetLineItems.length > 0
  const isLineItemBilled = !!sovLineItem?.billedToDate
  // Users can either add progress to the invoice itself, or add a worksheet and handle progress in
  // the worksheet. They cannot do both. This check prevents users from adding sov worksheet items
  // if progress on the invoice has already been billed by ensuring that the line item either a) does
  // not have progress billed, or b) does have progress billed, but also already has a worksheet.
  const canEditSovLineItemWorksheet = isSovWorksheet && (hasWorksheetLineItems || !isLineItemBilled)

  const initialWorksheet = useMemo(() => {
    if (!contract?.sov) {
      return []
    }
    return _.chain([...contract.sov.lineItems])
      .filter((lineItem) => !viewingSovLineItemId || lineItem.id === viewingSovLineItemId)
      .orderBy((lineItem) => lineItem.sortOrder)
      .map(sovLineItemToEditingWorksheetSovLineItem)
      .value()
  }, [contract?.sov, viewingSovLineItemId])

  const [worksheet, setWorksheet] = useState<EditingWorksheet>(initialWorksheet)
  const [editMode, setEditMode] = useState<'worksheet' | 'progress'>('progress')

  // If the contract SOV changes, update the worksheet shown
  useEffect(() => {
    setWorksheet(initialWorksheet)
    // On unmount, make sure we've reset state
    return () => {
      setEditMode('progress')
      setHasEdited(false)
    }
  }, [initialWorksheet])

  const { scheduledValue, formattedUnits } = useMemo(() => {
    let scheduledValue = null
    let formattedUnits = null

    if (sovLineItem && contract?.billingType === BillingType.LUMP_SUM) {
      scheduledValue = sovLineItem.originalTotalValue
    }

    if (
      sovLineItem &&
      sovLineItem.unitPrice !== null &&
      contract?.billingType === BillingType.UNIT_PRICE
    ) {
      const { originalTotalValue, unitPrice } = sovLineItem
      const scheduledUnits = safeDivide(originalTotalValue, unitPrice, 0).toFixed(2)
      const formattedUnitPrice = formatCentsToDollars(unitPrice, true)
      formattedUnits = t(`${i18nBase}.units_overview`, {
        numUnits: scheduledUnits,
        unitPrice: formattedUnitPrice,
      })
      scheduledValue = originalTotalValue
    }

    return { formattedUnits, scheduledValue }
  }, [contract?.billingType, sovLineItem, t])

  const handleClearSearch = useCallback(() => {
    onSearch('')
    setClearSearch((current) => current + 1)
  }, [onSearch])

  const handleCancelEditing = useCallback(() => {
    if (hasEdited) {
      confirm({
        title: t(`${i18nBase}.cancel_confirmation.cancel_title`),
        details: t(`${i18nBase}.cancel_confirmation.details`),
        callback: (confirmed: boolean) => {
          if (confirmed) {
            setWorksheet(initialWorksheet)
            setEditMode('progress')
            setHasEdited(false)
          }
        },
      })
    } else {
      setWorksheet(initialWorksheet)
      setEditMode('progress')
    }
  }, [hasEdited, confirm, t, initialWorksheet])

  const handleEnterWorksheetEditMode = useCallback(() => {
    setEditMode('worksheet')
  }, [])

  const handleSaveWorksheet = useCallback(async () => {
    if (!contract?.sov) {
      return
    }

    setIsSaving(true)

    const initialWorksheetLineItems = worksheetLineItemsFromWorksheet(initialWorksheet)
    const updateWorksheetInput = editingWorksheetToUpdateWorksheetInput({
      worksheet,
      initialWorksheetLineItems,
      sovId: contract.sov.id,
    })

    try {
      await updateWorksheet({ variables: { input: updateWorksheetInput } })

      snackbar.showSuccess(t(`${i18nBase}.saved_worksheet`))
      setHasEdited(false)
      setEditMode('progress')
      trackEditedWorksheet({
        projectId: contract.project.id,
        projectName: contract.project.name,
        companyId,
      })
    } catch (err) {
      snackbar.showError(err.message)
    }
    setIsSaving(false)
  }, [
    companyId,
    contract?.project.id,
    contract?.project.name,
    contract?.sov,
    initialWorksheet,
    snackbar,
    t,
    updateWorksheet,
    worksheet,
  ])

  const handleClose = useCallback(() => {
    if (editMode === 'progress' || !hasEdited) {
      setEditMode('progress')
      onCloseSidebar()
      return
    }

    confirm({
      title: t(`${i18nBase}.cancel_confirmation.leave_title`),
      details: t(`${i18nBase}.cancel_confirmation.details`),
      callback: (confirmed: boolean) => {
        if (confirmed) {
          setWorksheet(initialWorksheet)
          setEditMode('progress')
          setHasEdited(false)
          onCloseSidebar()
        }
      },
    })
  }, [confirm, editMode, hasEdited, initialWorksheet, onCloseSidebar, t])

  const handleWorksheetChange = useCallback(
    (toWorksheet: EditingWorksheet) => {
      if (!isSovWorksheet) {
        // Extra check, even though callers should already be checking this if the trigger is enabled
        return
      }
      // Make sure we are in the worksheet editing mode. It's possible that we are entering edit mode
      // due to a change, e.g. adding a line item.
      setEditMode('worksheet')
      setHasEdited(true)
      setWorksheet(toWorksheet)
    },
    [isSovWorksheet]
  )

  if (!contract) {
    return null
  }

  const isOpen = viewingSovLineItemId !== null

  return (
    <>
      <Prompt when={hasEdited} message={t(`${i18nBase}.cancel_confirmation.prompt`)} />
      <Sidesheet isOpen={isOpen} onClose={handleClose} className={classes.root}>
        <div className="topContent">
          <div className="header">
            {sovLineItem && (
              <div className="nameAndEdit">
                <SitelineText variant="h2" bold>
                  <span className="lineItemCode">{sovLineItem.code}</span>
                  {/* Space between the code and name */}
                  <span> </span>
                  {sovLineItem.name}
                </SitelineText>
                {editMode === 'progress' && isSovWorksheet && hasWorksheetLineItems && (
                  // We show an edit button for entering edit mode if:
                  // 1. We're not already editing the worksheet (editMode === 'progress')
                  // 2. Editing the worksheet is allowed in this view, meaning this isn't the progress
                  // billing view (isSovWorksheet)
                  // 3. There are worksheet line items to edit. If not, the "add line item" button will
                  // already be shown and this button wouldn't do anything additional (hasWorksheetLineItems).
                  <IconButton onClick={() => setEditMode('worksheet')} size="small">
                    <EditIcon />
                  </IconButton>
                )}
              </div>
            )}
            {!sovLineItem && <Skeleton variant="rectangular" height={28} width={300} />}
            {editMode === 'progress' && (
              <div className="itemSwitcher">
                <IconButton
                  disabled={sovLineItemIndex === 0 || loading}
                  onClick={handleLastItem}
                  size="small"
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  disabled={sovLineItemIndex === sovLineItems.length - 1 || loading}
                  onClick={handleNextItem}
                  size="small"
                >
                  <ArrowRightIcon />
                </IconButton>
              </div>
            )}
            {editMode === 'worksheet' && (
              <div className="editButtons">
                <Button variant="outlined" color="secondary" onClick={handleCancelEditing}>
                  {t('common.actions.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveWorksheet}
                  disabled={isSaving}
                  startIcon={isSaving ? <ButtonLabelSpinner /> : undefined}
                >
                  {t('common.actions.save')}
                </Button>
              </div>
            )}
          </div>
          {scheduledValue !== null && (
            <Row gap={8}>
              <SitelineText variant="body1" color="grey50">
                {t(`${i18nBase}.scheduled_value`)}
              </SitelineText>
              <SitelineText variant="h4">
                <DollarNumberFormat value={scheduledValue} />
              </SitelineText>
              {formattedUnits && (
                <SitelineText variant="body1" color="grey90">
                  {formattedUnits}
                </SitelineText>
              )}
            </Row>
          )}
        </div>
        {isPayAppRetentionOnly && (
          <div className="statusBanner">
            <StatusBanner
              title={t(`${i18nBase}.existing_progress_status_banner.title`)}
              subtitle={t(`${i18nBase}.retention_only_permission`)}
              actionType="link"
              color="yellow"
            />
          </div>
        )}
        {/* Only display one permission banner at a time, since both would say "Cannot edit worksheet" */}
        {!isPayAppRetentionOnly && isSovWorksheet && !canEditSovLineItemWorksheet && (
          <div className="statusBanner">
            <StatusBanner
              title={t(`${i18nBase}.existing_progress_status_banner.title`)}
              subtitle={t(`${i18nBase}.existing_progress_status_banner.subtitle`)}
              actionType="link"
              color="yellow"
            />
          </div>
        )}
        <div className="spreadsheetContainer">
          <div className="searchBar">
            <SitelineSearch
              initialSearch={search}
              onSearchChanged={onSearch}
              placeholder={t(`${i18nBase}.search_placeholder`)}
              clearSearch={clearSearch}
              showClearButton
              disabled={sovLineItem === null || sovLineItem.worksheetLineItems.length === 0}
            />
          </div>
          <FieldGuestInvoice
            spreadsheetId={SIDEBAR_WORKSHEET_SPREADSHEET_ID}
            sovLineItemId={viewingSovLineItemId}
            focusSpreadsheetOnMount={false}
            worksheet={worksheet}
            onWorksheetChange={isSovWorksheet ? handleWorksheetChange : undefined}
            payApp={payApp}
            contract={contract}
            searchQuery={debouncedSearch}
            includePreSitelineBilling={hasPastPayApps}
            billingType={contract.billingType}
            loading={loading}
            editMode={editMode}
            onClearSearch={handleClearSearch}
            canEditInvoice={canEdit}
            onEditSovWorksheet={
              canEditSovLineItemWorksheet ? handleEnterWorksheetEditMode : undefined
            }
          />
        </div>
      </Sidesheet>
    </>
  )
}
