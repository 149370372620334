import { gql } from '@apollo/client'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  GetContractForVendorsProjectHomeDocument,
  GetProjectLienWaiversByMonthDocument,
  SubcontractorLienWaiverTemplateSetInput,
  useClearVendorLienWaiverFormsMutation,
  useUpdateVendorLienWaiverTemplatesMutation,
} from '../../../common/graphql/apollo-operations'
import {
  buildLienWaiverTemplateSets,
  CompanyWithForms,
  deriveVendorLienWaiverTemplatesFromContract,
} from '../../../common/util/Forms'
import {
  deriveFormSelectionStatusFromContract,
  getClearOnboardingFormsRefetchQuery,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import { OnboardingFormsSection } from './OnboardingFormsSection'
import { ContractForProjectOnboarding } from './OnboardingTaskList'

gql`
  mutation clearVendorLienWaiverForms($contractId: ID!) {
    clearVendorLienWaiverForms(contractId: $contractId) {
      id
      lowerTierLienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${fragments.minimalSubcontractorLienWaiverTemplateSet}
  ${fragments.onboardedProjectContractStatus}
`

const i18nBase = 'projects.subcontractors.settings.forms'

const FORM_TYPE = ProjectOnboardingFormType.VENDOR_LIEN_WAIVER

interface VendorLienWaiverFormsTaskProps {
  contract: ContractForProjectOnboarding
  company: CompanyWithForms
  isEditing?: boolean
  shouldConfirmFormDeletion?: boolean
}

/** Under the onboarding checklist Forms task, section for selecting vendor lien waiver forms */
export function VendorLienWaiverFormsSection({
  contract,
  company,
  isEditing = true,
  shouldConfirmFormDeletion,
}: VendorLienWaiverFormsTaskProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { id: projectId } = useProjectContext()
  const { companyId } = useCompanyContext()

  // Refetch vendor lien waiver query so we don't show the empty state once lien waivers are selected
  const vendorFormsRefetchQuery = useMemo(
    () => ({
      refetchQueries: [
        {
          query: GetProjectLienWaiversByMonthDocument,
          variables: { input: { projectIds: [projectId], companyId } },
        },
        {
          query: GetContractForVendorsProjectHomeDocument,
          variables: { input: { projectId, companyId } },
        },
      ],
    }),
    [projectId, companyId]
  )
  const clearVendorFormsRefetchQuery = useMemo(() => {
    const clearFormRefetchQuery = getClearOnboardingFormsRefetchQuery(companyId)
    return {
      refetchQueries: [
        ...vendorFormsRefetchQuery.refetchQueries,
        ...clearFormRefetchQuery.refetchQueries,
      ],
    }
  }, [vendorFormsRefetchQuery.refetchQueries, companyId])

  const [clearForms] = useClearVendorLienWaiverFormsMutation({
    ...clearVendorFormsRefetchQuery,
    update: (cache) => {
      // OnboardedStatus.selectedVendorLienWaivers might change
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const [updateVendorLienWaiverFormTemplates] = useUpdateVendorLienWaiverTemplatesMutation()

  const companyVendorLienWaiverTemplateSets = useMemo(() => {
    return buildLienWaiverTemplateSets([...company.vendorLienWaiverTemplateSets])
  }, [company.vendorLienWaiverTemplateSets])

  const formTemplates = useMemo(
    () => deriveVendorLienWaiverTemplatesFromContract(contract),
    [contract]
  )

  const isProcessing = useMemo(
    () => deriveFormSelectionStatusFromContract(FORM_TYPE, contract).isProcessingForms,
    [contract]
  )

  const handleClearForms = useCallback(async () => {
    try {
      await clearForms({ variables: { contractId: contract.id } })
      snackbar.showSuccess(t(`${i18nBase}.cleared_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [clearForms, contract.id, snackbar, t])

  const handleRemoveForm = useCallback(
    async (formTemplateId: string) => {
      const { lowerTierLienWaiverTemplates: templates } = contract
      const newSet: SubcontractorLienWaiverTemplateSetInput = {
        conditionalFinalVariantId:
          templates?.conditionalFinalVariant?.template.id === formTemplateId
            ? null
            : templates?.conditionalFinalVariant?.id,
        conditionalProgressVariantId:
          templates?.conditionalProgressVariant?.template.id === formTemplateId
            ? null
            : templates?.conditionalProgressVariant?.id,
        unconditionalFinalVariantId:
          templates?.unconditionalFinalVariant?.template.id === formTemplateId
            ? null
            : templates?.unconditionalFinalVariant?.id,
        unconditionalProgressVariantId:
          templates?.unconditionalProgressVariant?.template.id === formTemplateId
            ? null
            : templates?.unconditionalProgressVariant?.id,
      }

      try {
        await updateVendorLienWaiverFormTemplates({
          variables: {
            input: {
              contractId: contract.id,
              templates: newSet,
            },
          },
        })
        snackbar.showSuccess(t(`${i18nBase}.removed_form`))
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract, snackbar, t, updateVendorLienWaiverFormTemplates]
  )

  return (
    <OnboardingFormsSection
      onboardingFormType={FORM_TYPE}
      formTemplates={formTemplates}
      companyTemplateSets={companyVendorLienWaiverTemplateSets}
      onClearForms={handleClearForms}
      onDeleteForm={isEditing ? handleRemoveForm : undefined}
      isProcessingForms={isProcessing}
      contract={contract}
      selectFormsRefetchQuery={vendorFormsRefetchQuery}
      isEditing={isEditing}
      shouldConfirmFormDeletion={shouldConfirmFormDeletion}
    />
  )
}
