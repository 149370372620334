import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, useSitelineSnackbar, useToggle } from 'siteline-common-web'
import {
  NestedDropdownMenu,
  NestedDropdownMenuItemType,
} from '../../../common/components/NestedDropdownMenu'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  MinimalChangeOrderRequestProperties,
  ProjectOnboardingFormType,
  useGetCompanyForFormsQuery,
} from '../../../common/graphql/apollo-operations'
import {
  buildChangeOrderTemplateSets,
  deriveChangeOrderLogTemplateFromContract,
} from '../../../common/util/Forms'
import { deriveFormSelectionStatusFromContract } from '../../../common/util/ProjectOnboarding'
import { getChangeOrdersPath } from '../Billing.lib'
import { ContractForProjectHome } from '../home/ProjectHome'
import {
  FormSelectionType,
  SelectFormTemplatesDialog,
} from '../onboarding/SelectFormTemplatesDialog'
import { ExportChangeOrderLogDialog, ExportType } from './ExportChangeOrderLogDialog'

const i18nBase = 'projects.subcontractors.change_order_requests.co_log_forms'

const EMPTY_CHANGE_ORDER_REQUESTS: MinimalChangeOrderRequestProperties[] = []
const FORM_TYPE = ProjectOnboardingFormType.CHANGE_ORDER_LOG

interface ChangeOrderLogAddtiionalOptionsMenuProps {
  contract: ContractForProjectHome
  changeOrderRequests?: MinimalChangeOrderRequestProperties[]
  loadingChangeOrderRequests?: boolean
}

/**
 * Three dot menu displayed on the ProjectHomeHeader component, visible when the user is on the change
 * order log. Opens to a dropdown menu with options for importing & exporting the change order log.
 */
export function ChangeOrderLogAddtiionalOptionsMenu({
  contract,
  changeOrderRequests = EMPTY_CHANGE_ORDER_REQUESTS,
  loadingChangeOrderRequests = false,
}: ChangeOrderLogAddtiionalOptionsMenuProps) {
  const { t } = useTranslation()
  const { companyId } = useCompanyContext()
  const { id: projectId } = useProjectContext()
  const navigate = useNavigate()
  const snackbar = useSitelineSnackbar()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [exportType, setExportType] = useState<ExportType | null>(null)
  const [isLoadingFormsDialogOpen, handleOpenLoadingDialog, handleCloseLoadingDialog] = useToggle()
  const [isSuccessDialogOpen, handleOpenSuccessDialog, handleCloseSuccessDialog] = useToggle()
  const [isSelectFormsDialogOpen, handleOpenSelectFormsDialog, handleCloseSelectFormsDialog] =
    useToggle()

  const { data: companyFormsData } = useGetCompanyForFormsQuery({ variables: { companyId } })

  const changeOrderLogTemplateSets = useMemo(() => {
    if (
      companyFormsData === undefined ||
      companyFormsData.company.defaultChangeOrderLogTemplate === null
    ) {
      return []
    }
    return buildChangeOrderTemplateSets(companyFormsData.company.defaultChangeOrderLogTemplate)
  }, [companyFormsData])

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleImportClick = useCallback(() => {
    handleCloseMenu()
    navigate(getChangeOrdersPath({ projectId, importChangeOrderLog: true }))
  }, [handleCloseMenu, navigate, projectId])

  const handleCloseExportDialog = useCallback(() => {
    setExportType(null)
  }, [])

  const handleFormSelectSuccess = useCallback(
    (selectionType: FormSelectionType) => {
      if (selectionType === FormSelectionType.UPLOAD) {
        handleOpenSuccessDialog()
      } else {
        snackbar.showSuccess(
          t(`projects.subcontractors.settings.forms.added_forms_success.${FORM_TYPE}`)
        )
      }
    },
    [handleOpenSuccessDialog, snackbar, t]
  )

  const menuItems: NestedDropdownMenuItemType[] = useMemo(() => {
    const template = deriveChangeOrderLogTemplateFromContract(contract)
    const { isProcessingForms } = deriveFormSelectionStatusFromContract(FORM_TYPE, contract)
    const hasForms = !!template
    return [
      {
        label: t(`${i18nBase}.import_co_log`),
        onClick: handleImportClick,
      },
      {
        label: t(`${i18nBase}.download_co_log`),
        direction: 'left',
        items: [
          {
            label: t(`${i18nBase}.export_excel`),
            onClick: () => setExportType(ExportType.EXCEL),
          },
          {
            label: hasForms ? t(`${i18nBase}.download_pdf`) : t(`${i18nBase}.select_template`),
            onClick: () => {
              if (isProcessingForms) {
                handleOpenLoadingDialog()
              } else if (hasForms) {
                setExportType(ExportType.PDF)
              } else {
                handleOpenSelectFormsDialog()
              }
            },
          },
        ],
      },
    ]
  }, [contract, handleImportClick, handleOpenLoadingDialog, handleOpenSelectFormsDialog, t])

  return (
    <>
      <IconButton color="secondary" onClick={handleOpenMenu} disabled={loadingChangeOrderRequests}>
        <MoreVertIcon />
      </IconButton>
      <NestedDropdownMenu onClose={handleCloseMenu} anchorEl={anchorEl} items={menuItems} />
      <ExportChangeOrderLogDialog
        exportType={exportType}
        onClose={handleCloseExportDialog}
        contract={contract}
        changeOrderRequests={changeOrderRequests}
      />
      <SelectFormTemplatesDialog
        open={isSelectFormsDialogOpen}
        onClose={handleCloseSelectFormsDialog}
        selectTemplateTypeTitle={t(`${i18nBase}.select_forms`)}
        formType={FORM_TYPE}
        companyTemplateSets={changeOrderLogTemplateSets}
        onSelectionSubmitted={handleFormSelectSuccess}
        contract={contract}
        location="changeOrderLog"
      />
      <SitelineDialog
        onSubmit={handleCloseSuccessDialog}
        title={t(`${i18nBase}.forms_submitted`)}
        submitLabel={t('common.actions.close')}
        open={isSuccessDialogOpen}
        maxWidth="sm"
      >
        <SitelineText variant="body1" color="grey50">
          {t(`${i18nBase}.forms_submitted_subtitle`)}
        </SitelineText>
      </SitelineDialog>
      <SitelineDialog
        onSubmit={handleCloseLoadingDialog}
        title={t(`${i18nBase}.forms_still_loading`)}
        submitLabel={t('common.actions.close')}
        open={isLoadingFormsDialogOpen}
        maxWidth="sm"
      >
        <SitelineText variant="body1" color="grey50">
          {t(`${i18nBase}.forms_still_processing_subtitle`)}
        </SitelineText>
      </SitelineDialog>
    </>
  )
}
