import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FEE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import {
  Column,
  ContractRateTableFee,
  Row,
  SitelineText,
  colors,
  makeStylesFast,
  useToggle,
} from 'siteline-common-web'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import {
  BillingType,
  RateTableFeeProperties,
  RateTableGroupProperties,
  RateTableProperties,
  RateTableTaxGroupProperties,
  TaxGroupProperties,
} from '../../../common/graphql/apollo-operations'
import {
  getMappedRateTableFees,
  getMappedRateTableTaxGroups,
  getSortedRateTableGroups,
} from '../../../common/util/Pricing'
import { formatTaxGroupLabel } from '../../../common/util/TaxGroup'
import { ContractForProjectHome } from '../home/ProjectHome'
import { SettingsRow } from '../settings/SettingsRow'
import { AddOrEditFee, AddOrEditFeeDialog, createEmptyFee } from './AddOrEditFeeDialog'
import { ContractForProjectOnboarding } from './OnboardingTaskList'
import { RateTableTaxGroupInput } from './RateTableTaxGroupInput'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& button': {
      whiteSpace: 'nowrap',
    },
    '& .addNewButton': {
      // This smidge of margin prevents a wonky transition on collapse/expand of the section
      // where the top part of the border disappears before everything else
      marginTop: theme.spacing(0.5),
    },
    '& .section': {
      marginBottom: theme.spacing(2),
    },
    '& .categoryFeesSubtitle': {
      // This subtitle gets pushed down because of button padding
      marginTop: theme.spacing(-0.5),
    },
    '& .advancedButton': {
      marginLeft: theme.spacing(-1),
    },
    '& .expandIcon': {
      // Animate the arrow icon flipping around when the menu opens
      transition: theme.transitions.create('transform'),
      '&.expanded': {
        // Flip the dropdown arrow upside down when the menu opens
        transform: 'rotate(-180deg)',
      },
    },
    '& .category': {
      marginTop: theme.spacing(3),
      '&:not(:last-of-type)': {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${colors.grey20}`,
      },
    },
    '& .categoryTitle': {
      marginBottom: theme.spacing(3),
      fontSize: 16,
    },
    '& .groupFees': {
      marginTop: 0,
    },
    '& .feeTableRow': {
      borderBottom: `1px solid ${colors.grey20}`,
      borderRadius: 0,
      padding: 0,
      width: '100%',
      '&:first-of-type': {
        borderTop: `1px solid ${colors.grey20}`,
      },
      '& .editIcon': {
        opacity: 0,
        color: colors.grey50,
        transition: theme.transitions.create('opacity'),
      },
      '&:hover': {
        backgroundColor: colors.grey10,
        '& .editIcon': {
          opacity: 1,
        },
      },
      '&:disabled': {
        color: colors.grey70,
      },
    },
    '& .taxGroupValue:not(.isEditing)': {
      marginLeft: theme.spacing(1.5),
    },
    '& .taxGroupValue.isEditing': {
      marginTop: theme.spacing(-0.75),
    },
    '& .wide': {
      width: '100%',
    },
    '& .feeTable': {
      marginTop: theme.spacing(-0.75),
    },
    '& .feeTableRowDescription': {
      flexGrow: 1,
      fontSize: 16,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1.5),
    },
    '& .feeTableRowPercent': {
      width: 100,
      fontSize: 16,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(1.5),
      borderLeft: `1px solid ${colors.grey20}`,
    },
    '& .noFees': {
      fontStyle: 'italic',
      color: colors.grey50,
      marginLeft: theme.spacing(1.5),
    },
    '& .taxCheckbox': {
      margin: theme.spacing(-0.5, 0, 0, -0.5),
      padding: theme.spacing(0.5),
    },
  },
}))

const i18nBase = 'projects.onboarding.checklist'

interface TaxesProps {
  usesTaxes: boolean
  defaultTaxGroup: TaxGroupProperties | null
  onUpdateTaxes: (checked: boolean, defaultTaxGroupId: string | null) => void
  onUpdateCategoryTaxes: (
    rateTableGroupId: string,
    overrideId: string | null,
    updatedTaxGroupId: string | null
  ) => void
  rateTableTaxGroups: RateTableTaxGroupProperties[]
  companyTaxGroups: TaxGroupProperties[]
}

interface FeeTablesProps {
  rateTable: RateTableProperties | null
  contract: ContractForProjectOnboarding | ContractForProjectHome
  isEditing?: boolean
  /** Used for metrics */
  location: 'projectOnboarding' | 'projectSettings'
  /** Pass null if taxes UI should not be displayed (i.e. if we're on the onboarding page) */
  taxesProps: TaxesProps | null
}

/** Displays breakdown on taxes & fees on the rate table task card */
export function FeeTables({
  contract,
  rateTable,
  isEditing = true,
  location,
  taxesProps,
}: FeeTablesProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isAdvancedFeesOpen, handleOpenAdvanced, handleCloseAdvanced] = useToggle()
  const [addOrEditFee, setAddOrEditFee] = useState<AddOrEditFee | null>(null)

  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS
  const shouldDisplayTaxes = taxesProps !== null
  const hasTaxGroups = taxesProps && !!taxesProps.companyTaxGroups.length
  const shouldDisplayEditingTaxes = shouldDisplayTaxes && isEditing
  const shouldDisplayEditingTaxSettings = shouldDisplayEditingTaxes && taxesProps.usesTaxes
  const shouldDisplayReadOnlyTaxes = shouldDisplayTaxes && taxesProps.usesTaxes && !isEditing

  const rateTableGroups = useMemo(
    () => (rateTable ? getSortedRateTableGroups({ rateTable }) : []),
    [rateTable]
  )

  const rateTableFees = useMemo(() => [...contract.rateTableFees], [contract.rateTableFees])

  const { globalFees, globalFeeIds, feeIdsByGroupId, sortedRateTableFees } = useMemo(
    () => getMappedRateTableFees({ rateTableGroups, rateTableFees }),
    [rateTableFees, rateTableGroups]
  )

  const rateTableTaxGroupsByRateTableGroupId = useMemo(() => {
    if (taxesProps === null) {
      return {}
    }
    const { defaultTaxGroup, rateTableTaxGroups } = taxesProps
    return getMappedRateTableTaxGroups({
      rateTableDefaultTaxGroup: defaultTaxGroup,
      rateTableTaxGroups,
      rateTableGroups,
    })
  }, [rateTableGroups, taxesProps])

  const handleToggleAdvanced = useCallback(() => {
    if (isAdvancedFeesOpen) {
      handleCloseAdvanced()
    } else {
      handleOpenAdvanced()
    }
  }, [handleCloseAdvanced, handleOpenAdvanced, isAdvancedFeesOpen])

  const handleToggleTaxes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (taxesProps === null) {
        return
      }
      const { onUpdateTaxes, defaultTaxGroup } = taxesProps
      const { checked } = event.target
      const updatedDefaultTaxGroupId = checked ? (defaultTaxGroup?.id ?? null) : null
      onUpdateTaxes(checked, updatedDefaultTaxGroupId)
    },
    [taxesProps]
  )

  const handleOpenNewFeeDialog = useCallback(() => {
    const emptyFee = createEmptyFee(null)
    setAddOrEditFee(emptyFee)
  }, [])

  const handleOpenNewCategoryFeeDialog = useCallback(() => {
    if (!rateTable) {
      console.error('Cannot add a category fee if a rate table has not been selected')
      return
    }
    if (rateTable.groups.length === 0) {
      console.error('Cannot add category fee to rate table with no groups')
      return
    }
    const defaultGroup = rateTable.groups[0]
    const emptyFee = createEmptyFee(defaultGroup)
    setAddOrEditFee(emptyFee)
  }, [rateTable])

  const handleOpenEditFeeDialog = useCallback(
    (fee: AddOrEditFee, group?: RateTableGroupProperties) => {
      if (fee.id === null) {
        console.error('Fee id should not be null')
        return
      }
      const isCategoryFee = group !== undefined
      const derivesFromGlobalFee = globalFeeIds.has(fee.id)
      const isOverride = !!fee.overridesFee
      const shouldOverride = isCategoryFee && derivesFromGlobalFee
      const isFeeDeleted = isOverride && fee.percent === 0
      setAddOrEditFee({
        ...fee,
        group: group ?? null,
        shouldOverride,
        isFeeDeleted,
      })
    },
    [globalFeeIds]
  )

  const handleFeeDialogClose = useCallback(() => {
    setAddOrEditFee(null)
  }, [])

  const handleUpdateDefaultTaxGroup = useCallback(
    (taxGroupId: string | null) => {
      if (taxesProps === null) {
        return
      }
      const { usesTaxes, onUpdateTaxes } = taxesProps
      onUpdateTaxes(usesTaxes, taxGroupId)
    },
    [taxesProps]
  )

  const shouldShowAdvanced = rateTable && rateTable.groups.length > 0

  return (
    <div className={classes.root}>
      <Column className="section" gap={24}>
        <Column>
          <Row justifyContent="space-between" alignItems="center">
            <SitelineText variant="h4" bold>
              {shouldDisplayTaxes
                ? t(`${i18nBase}.general_fees_taxes`)
                : t(`${i18nBase}.general_fees`)}
            </SitelineText>
            {isEditing && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon fontSize="small" />}
                onClick={handleOpenNewFeeDialog}
                className="addNewButton"
              >
                {t(`${i18nBase}.add_fee`)}
              </Button>
            )}
          </Row>
          <SitelineText variant="body2" color="grey50">
            {isTimeAndMaterials
              ? t(`${i18nBase}.general_fees_subtitle`)
              : t(`${i18nBase}.general_fees_subtitle_cor`)}
          </SitelineText>
        </Column>
        {globalFees.length > 0 && (
          <Column>
            {globalFees.map((fee) => {
              return (
                <Button
                  key={`global - ${fee.id}`}
                  variant="text"
                  color="secondary"
                  onClick={() => handleOpenEditFeeDialog(fee as RateTableFeeProperties)}
                  className="feeTableRow"
                  disabled={!isEditing}
                >
                  <SitelineText
                    variant="body2"
                    className="feeTableRowDescription"
                    endIcon={<EditIcon className="editIcon" fontSize="small" />}
                  >
                    {fee.description}
                  </SitelineText>
                  <SitelineText variant="body2" className="feeTableRowPercent">
                    {`${decimalToPercent(fee.percent, FEE_PERCENT_PRECISION)}%`}
                  </SitelineText>
                </Button>
              )
            })}
          </Column>
        )}
        {!isEditing && shouldDisplayTaxes && (
          <SitelineText variant="body1">
            {taxesProps.defaultTaxGroup
              ? formatTaxGroupLabel(taxesProps.defaultTaxGroup, t)
              : t(`${i18nBase}.no_taxes`)}
          </SitelineText>
        )}
        {shouldDisplayEditingTaxes && (
          <Row alignItems="flex-start" gap={4} className="wide">
            <SitelineCheckbox
              checked={taxesProps.usesTaxes}
              value={taxesProps.usesTaxes}
              onChange={handleToggleTaxes}
              className="taxCheckbox"
            />
            <Column gap={4} className="wide">
              <SitelineText variant="body1">{t(`${i18nBase}.calculate_taxes`)}</SitelineText>
              <Collapse in={taxesProps.usesTaxes} unmountOnExit>
                <Column gap={12}>
                  <SitelineText variant="body2" color="grey50">
                    {t(`${i18nBase}.calculate_taxes_subtitle`)}
                  </SitelineText>
                  <RateTableTaxGroupInput
                    taxGroups={taxesProps.companyTaxGroups}
                    selectedTaxGroupId={taxesProps.defaultTaxGroup?.id ?? null}
                    onSelectTaxGroup={handleUpdateDefaultTaxGroup}
                  />
                </Column>
              </Collapse>
            </Column>
          </Row>
        )}
      </Column>
      {shouldShowAdvanced && (
        <div className="section">
          <Button variant="text" className="advancedButton" onClick={handleToggleAdvanced}>
            <SitelineText
              variant="h4"
              color="grey90"
              endIcon={
                <ExpandMoreIcon
                  fontSize="small"
                  className={clsx('expandIcon', { expanded: isAdvancedFeesOpen })}
                />
              }
            >
              {shouldDisplayTaxes
                ? t(`${i18nBase}.category_fees_and_taxes`)
                : t(`${i18nBase}.category_fees`)}
            </SitelineText>
          </Button>
          <Collapse in={isAdvancedFeesOpen}>
            <div>
              <Row
                justifyContent="space-between"
                alignItems="center"
                className="categoryFeesSubtitle"
              >
                {isEditing && (
                  <>
                    <SitelineText variant="body2" color="grey50">
                      {rateTableFees.length
                        ? t(`${i18nBase}.category_fees_subtitle_click`)
                        : t(`${i18nBase}.category_fees_subtitle`)}
                    </SitelineText>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={handleOpenNewCategoryFeeDialog}
                      className="addNewButton"
                    >
                      {t(`${i18nBase}.add_group_fee`)}
                    </Button>
                  </>
                )}
              </Row>
              <Column className="groupFees">
                {rateTableGroups.map((group) => {
                  const groupFees = feeIdsByGroupId[group.id]
                  const categoryTaxGroup = _.get(
                    rateTableTaxGroupsByRateTableGroupId,
                    group.id,
                    null
                  )
                  const taxGroup = categoryTaxGroup?.taxGroup ?? taxesProps?.defaultTaxGroup ?? null
                  const overrideId = categoryTaxGroup?.overrideId ?? null
                  const noFees = groupFees.size === 0

                  const feeContent = noFees ? (
                    <SitelineText variant="body2">{t(`${i18nBase}.no_fees_yet`)}</SitelineText>
                  ) : (
                    <Column className="feeTable">
                      {sortedRateTableFees.map((fee) => {
                        if (groupFees.has(fee.id)) {
                          return (
                            <Button
                              key={`${group.id} - ${fee.id}`}
                              variant="text"
                              color="secondary"
                              onClick={() =>
                                handleOpenEditFeeDialog(fee as ContractRateTableFee, group)
                              }
                              className="feeTableRow"
                              disabled={!isEditing}
                            >
                              <SitelineText
                                variant="body1"
                                className="feeTableRowDescription"
                                endIcon={<EditIcon className="editIcon" fontSize="small" />}
                              >
                                {fee.description}
                              </SitelineText>
                              <SitelineText variant="body1" className="feeTableRowPercent">
                                {`${decimalToPercent(fee.percent, FEE_PERCENT_PRECISION)}%`}
                              </SitelineText>
                            </Button>
                          )
                        }
                        return null
                      })}
                    </Column>
                  )

                  return (
                    <Column key={group.id} className="category">
                      <SitelineText variant="body2" bold className="categoryTitle">
                        {group.name}
                      </SitelineText>
                      <SettingsRow
                        label={t(`${i18nBase}.fees`)}
                        labelWidth={100}
                        isLoading={false}
                        isEditing={isEditing}
                        editingValue={feeContent}
                        value={feeContent}
                        innerClassName={clsx('wide', { noFees })}
                      />
                      {(shouldDisplayEditingTaxSettings || shouldDisplayReadOnlyTaxes) &&
                        hasTaxGroups && (
                          <SettingsRow
                            label={t(`${i18nBase}.tax_group`)}
                            labelWidth={100}
                            isLoading={false}
                            isEditing={isEditing}
                            value={formatTaxGroupLabel(taxGroup, t)}
                            innerClassName={clsx('wide', 'taxGroupValue', { isEditing })}
                            editingValue={
                              <RateTableTaxGroupInput
                                taxGroups={taxesProps.companyTaxGroups}
                                selectedTaxGroupId={taxGroup?.id ?? null}
                                onSelectTaxGroup={(taxGroupId) => {
                                  taxesProps.onUpdateCategoryTaxes(group.id, overrideId, taxGroupId)
                                }}
                                isOverride={overrideId !== null}
                              />
                            }
                          />
                        )}
                    </Column>
                  )
                })}
              </Column>
            </div>
          </Collapse>
        </div>
      )}
      <AddOrEditFeeDialog
        addOrEditFee={addOrEditFee}
        onClose={handleFeeDialogClose}
        groups={rateTableGroups}
        contract={contract}
        location={location}
      />
    </div>
  )
}
