import { Skeleton, Switch } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Permission, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  ProjectOnboardingFormType,
  useGetCompanyForFormsQuery,
  useGetContractForOnboardingQuery,
  useUpdateContractMutation,
} from '../../../common/graphql/apollo-operations'
import { getOnboardingFormRequirements } from '../../../common/util/ProjectOnboarding'
import { ContractForProjectHome } from '../home/ProjectHome'
import { ChangeOrderLogFormsSection } from '../onboarding/ChangeOrderLogFormsSection'
import { ChangeOrderRequestFormsSection } from '../onboarding/ChangeOrderRequestFormsSection'
import { PayAppFormsSection } from '../onboarding/PayAppFormsSection'
import { PrimaryLienWaiverFormsSection } from '../onboarding/PrimaryLienWaiverFormsSection'
import { VendorLienWaiverFormsSection } from '../onboarding/VendorLienWaiverFormsSection'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
}))

const FormsSectionSkeleton = () => <Skeleton variant="rectangular" width="100%" height={100} />

const i18nBase = 'projects.subcontractors.settings.forms'

interface FormsProps {
  contract: ContractForProjectHome
}

/** Contract forms settings (audit page, etc) */
export function Forms({ contract }: FormsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isContractActive } = useProjectContext()
  const { id: contractId } = contract
  const { companyId, permissions } = useCompanyContext()
  const snackbar = useSitelineSnackbar()

  const { data: formData } = useGetContractForOnboardingQuery({ variables: { contractId } })
  const { data: companyFormsData } = useGetCompanyForFormsQuery({ variables: { companyId } })
  const [updateContractMutation] = useUpdateContractMutation()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const showAudit = contract.showAuditItemOnSignedPackages

  const canEdit = useMemo(
    () => permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive,
    [isContractActive, permissions]
  )

  const integrations = useMemo(
    () => contract.integrations.map((integration) => integration.type),
    [contract.integrations]
  )

  const formTypes = useMemo(
    () => getOnboardingFormRequirements(integrations, contract.billingType),
    [contract.billingType, integrations]
  )

  const formTypesSet = useMemo(() => new Set(formTypes), [formTypes])

  const hasFinishedLoading = !!formData && !!companyFormsData

  const handleToggleShowAudit = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        await updateContractMutation({
          variables: {
            input: {
              id: contract.id,
              showAuditItemOnSignedPackages: event.target.checked,
            },
          },
        })
        snackbar.showSuccess(t(`${i18nBase}.updated`))
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract, snackbar, t, updateContractMutation]
  )

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.title`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={null}
      />
      <SettingsRow
        label={t(`${i18nBase}.show_audit`)}
        isLoading={!contract}
        value={showAudit ? t(`${i18nBase}.on`) : t(`${i18nBase}.off`)}
        editingValue={
          <div className={classes.switch}>
            <Switch checked={showAudit} onChange={handleToggleShowAudit} />
          </div>
        }
        isEditing={isEditing}
      />
      {formTypesSet.has(ProjectOnboardingFormType.PAY_APP) &&
        (hasFinishedLoading ? (
          <PayAppFormsSection
            isEditing={isEditing}
            contract={formData.contract}
            company={companyFormsData.company}
            shouldConfirmFormDeletion
          />
        ) : (
          <FormsSectionSkeleton />
        ))}
      {formTypesSet.has(ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER) &&
        (hasFinishedLoading ? (
          <PrimaryLienWaiverFormsSection
            isEditing={isEditing}
            contract={formData.contract}
            company={companyFormsData.company}
            shouldConfirmFormDeletion
          />
        ) : (
          <FormsSectionSkeleton />
        ))}
      {formTypesSet.has(ProjectOnboardingFormType.VENDOR_LIEN_WAIVER) &&
        (hasFinishedLoading ? (
          <VendorLienWaiverFormsSection
            isEditing={isEditing}
            contract={formData.contract}
            company={companyFormsData.company}
            shouldConfirmFormDeletion
          />
        ) : (
          <FormsSectionSkeleton />
        ))}
      {formTypesSet.has(ProjectOnboardingFormType.CHANGE_ORDER_REQUEST) &&
        (hasFinishedLoading ? (
          <ChangeOrderRequestFormsSection
            isEditing={isEditing}
            contract={formData.contract}
            company={companyFormsData.company}
            shouldConfirmFormDeletion
          />
        ) : (
          <FormsSectionSkeleton />
        ))}
      {formTypesSet.has(ProjectOnboardingFormType.CHANGE_ORDER_LOG) &&
        (hasFinishedLoading ? (
          <ChangeOrderLogFormsSection
            isEditing={isEditing}
            contract={formData.contract}
            company={companyFormsData.company}
            shouldConfirmFormDeletion
            shouldDisplayIncludeCoLogToggle
          />
        ) : (
          <FormsSectionSkeleton />
        ))}
    </div>
  )
}
