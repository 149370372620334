import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button, Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'
import { colors, makeStylesFast, SitelineText, Spacer } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: colors.blue10,
    borderRadius: theme.shape.borderRadius,
    '& .infoIcon': {
      color: colors.blue50,
      marginRight: theme.spacing(1.5),
    },
    '& .noButton': {
      color: colors.grey50,
    },
  },
  bold: { fontWeight: 'bold' },
}))

const i18nBase = 'projects.subcontractors.pay_app.submit.new_contact'

interface SuggestEmailBannerProps {
  suggestedEmail: string | null
  onYes: () => void
  onNo: () => void
  className?: string
}

/** Banner displayed on contact forms suggesting an update to the user-entered email address */
export function SuggestEmailBanner({
  suggestedEmail,
  onYes,
  onNo,
  className,
}: SuggestEmailBannerProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Collapse in={suggestedEmail !== null}>
      <div className={clsx(classes.root, className)}>
        <InfoOutlinedIcon className="infoIcon" />
        <SitelineText variant="body2" color="grey70">
          <Trans
            i18nKey={`${i18nBase}.did_you_mean_email`}
            values={{ email: suggestedEmail ?? '' }}
            components={{ bold: <span className={classes.bold} /> }}
          />
        </SitelineText>
        <Spacer />
        <Button variant="text" color="secondary" onClick={onNo} className="noButton">
          {t('common.no')}
        </Button>
        <Button variant="text" color="primary" onClick={onYes}>
          {t('common.yes')}
        </Button>
      </div>
    </Collapse>
  )
}
