import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pdfTypes } from 'siteline-common-all'
import { generatePdfWithMetadata, Row, SitelineText } from 'siteline-common-web'
import { FilterDropdownMenu } from '../../../common/components/FilterDropdownMenu'
import { PdfPreviewDialog } from '../../../common/components/Pdf/PdfPreviewDialog'
import { SameGcChip } from '../../../common/components/SameGcChip'
import {
  ContractForVendorsProjectHome,
  GetContractForOnboardingQuery,
  ProjectOnboardingFormType,
} from '../../../common/graphql/apollo-operations'
import { NonEmptyArray } from '../../../common/util/Array'
import { CompanyTemplateCollection, CompanyTemplateSet } from '../../../common/util/Forms'
import { ContractOnboardingFormType } from '../../../common/util/ProjectOnboarding'
import { ContractForProjectHome } from '../home/ProjectHome'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'

const i18nBase = 'projects.subcontractors.settings.forms'

export type ContractForProjectOnboarding = GetContractForOnboardingQuery['contract']
export type ContractForCompanyFormSetSelection =
  | ContractForProjectOnboarding
  | ContractForVendorsProjectHome
  | ContractForProjectHome

export type CompanyTemplateSets = {
  type: 'templateSet'
  groups: CompanyTemplateSet[]
}

export type CompanyTemplateCollections = {
  type: 'templateCollection'
  groups: CompanyTemplateCollection[]
}

interface PreviewCompanyTemplatesDialogProps {
  previewingGroupId: string
  companyTemplateGroups: CompanyTemplateSets | CompanyTemplateCollections
  onSubmit: (includeCoLogOnPayApps?: boolean) => void
  onClose: (fromButton: boolean) => void
  formTypes: NonEmptyArray<ContractOnboardingFormType>
  contract: ContractForCompanyFormSetSelection
  open: boolean
  submitting: boolean
  supertitle: string
  shouldDisplayConditionalFormsMessage: boolean
}

/** Dialog for previewing either company project form kits or form sets during onboarding */
export function PreviewCompanyTemplatesDialog({
  previewingGroupId,
  companyTemplateGroups,
  onSubmit,
  onClose,
  formTypes,
  contract,
  open,
  submitting,
  supertitle,
  shouldDisplayConditionalFormsMessage,
}: PreviewCompanyTemplatesDialogProps) {
  const { t } = useTranslation()

  const [viewingFormType, setViewingFormType] = useState<ContractOnboardingFormType>(formTypes[0])
  const [loading, setLoading] = useState<boolean>(false)
  const [pdf, setPdf] = useState<Blob | null>(null)
  const [metadata, setMetadata] = useState<pdfTypes.PageMetadata[]>([])
  const [includeCoLogOnPayApps, setIncludeCoLogOnPayApps] = useState<boolean>(true)

  const previewingGroup = useMemo(() => {
    if (!previewingGroupId) {
      return null
    }
    return companyTemplateGroups.groups.find((group) => group.id === previewingGroupId)
  }, [companyTemplateGroups.groups, previewingGroupId])

  const viewFormTypeDropdownOptions = useMemo(() => {
    return formTypes.map((formType) => {
      switch (formType) {
        case ProjectOnboardingFormType.PAY_APP:
          return { label: t(`${i18nBase}.pay_app_forms`), value: formType }
        case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
          return { label: t(`${i18nBase}.change_order_forms`), value: formType }
        case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
          return { label: t(`${i18nBase}.change_order_log_forms`), value: formType }
        case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
          return { label: t(`${i18nBase}.primary_lien_waiver_forms`), value: formType }
        case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
          return { label: t(`${i18nBase}.vendor_lien_waiver_forms`), value: formType }
      }
    })
  }, [formTypes, t])

  const [viewingFormTemplates, isProcessingForms] = useMemo(() => {
    const previewingTemplateGroup = companyTemplateGroups.groups.find(
      (group) => group.id === previewingGroupId
    )

    // Shouldn't be possible to hit this condition, just for type checking
    if (!previewingTemplateGroup) {
      return [[], false]
    }

    if (companyTemplateGroups.type === 'templateSet') {
      const previewingTemplateSet = previewingTemplateGroup as CompanyTemplateSet
      // Note: previewingTemplateSet.isProcessingForms will be false here - it's not possible to
      // preview a template set (i.e. view this dialog) if any forms in the set are processing
      return [previewingTemplateSet.formTemplates, previewingTemplateSet.isProcessingForms]
    }

    const previewingTemplateCollection = previewingTemplateGroup as CompanyTemplateCollection
    const forms = previewingTemplateCollection.templateSets[viewingFormType]
    const formTemplates = forms?.formTemplates ?? []
    return [formTemplates, forms?.isProcessingForms ?? false]
  }, [companyTemplateGroups.groups, companyTemplateGroups.type, previewingGroupId, viewingFormType])

  const shouldDisplayIncludeCoLogToggle = useMemo(
    () => formTypes.includes(ProjectOnboardingFormType.CHANGE_ORDER_LOG),
    [formTypes]
  )

  const handleReset = useCallback(() => {
    setViewingFormType(formTypes[0])
    setPdf(null)
    setMetadata([])
  }, [formTypes])

  const handleSubmit = useCallback(
    () => onSubmit(shouldDisplayIncludeCoLogToggle ? includeCoLogOnPayApps : undefined),
    [onSubmit, shouldDisplayIncludeCoLogToggle, includeCoLogOnPayApps]
  )

  // Generate the PDF to preview form groups
  useEffect(() => {
    setLoading(true)
    setPdf(null)
    setMetadata([])

    if (viewingFormTemplates.length === 0) {
      setLoading(false)
      return
    }

    async function generateFormsPdf() {
      const formsPdf = await generatePdfWithMetadata({
        type: 'templatesPreview',
        formTemplateVersionIds: _.uniq(viewingFormTemplates.map((template) => template.versionId)),
        formTemplateVariantIds: _.uniq(viewingFormTemplates.map((template) => template.variantId)),
        contractId: contract.id,
      })
      setPdf(formsPdf.blob)
      setMetadata(formsPdf.metadata)
      setLoading(false)
    }

    generateFormsPdf()
  }, [viewingFormTemplates, contract.id, open])

  return (
    <PdfPreviewDialog
      title={
        <Row alignItems="center" justifyContent="space-between">
          <Row alignItems="center" justifyContent="flex-start">
            <SitelineText variant="h2" color="grey90">
              {previewingGroup?.name ?? ''}
            </SitelineText>
            <SameGcChip
              currentContract={contract}
              otherContract={undefined}
              generalContractorId={previewingGroup?.generalContractor?.id}
            />
          </Row>
          {formTypes.length > 1 && (
            <Row alignItems="center" justifyContent="flex-end" gap={8}>
              <SitelineText variant="body1">{t(`${i18nBase}.preview`)}</SitelineText>
              <FilterDropdownMenu
                title={null}
                options={viewFormTypeDropdownOptions}
                selectedOption={viewingFormType}
                onSelectedOptionChange={setViewingFormType}
                required
              />
            </Row>
          )}
        </Row>
      }
      subscript={
        shouldDisplayIncludeCoLogToggle ? (
          <IncludeChangeOrderLogInPayAppToggle
            shouldInclude={includeCoLogOnPayApps}
            onChange={setIncludeCoLogOnPayApps}
            disabled={false}
            shouldShowDisabledTooltip={false}
          />
        ) : undefined
      }
      supertitle={supertitle}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      onResetDialog={handleReset}
      cancelLabel={t('common.actions.back')}
      submitLabel={t('common.actions.select')}
      submitting={submitting}
      fixedTopPosition={0}
      file={pdf}
      metadata={metadata}
      loading={loading}
      isProcessingForms={isProcessingForms}
    >
      {shouldDisplayConditionalFormsMessage && (
        <SitelineText variant="body1" color="grey50">
          {t(`${i18nBase}.conditional_forms`)}
        </SitelineText>
      )}
    </PdfPreviewDialog>
  )
}
