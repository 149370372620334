import { Switch } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getIntegrationName, supportsWriteTaxCalculation } from 'siteline-common-all'
import { Permission, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  BillingType,
  TaxCalculationType,
  TaxGroupProperties,
  useUpdateContractMutation,
  useUpdateTaxCalculationTypeMutation,
} from '../../../common/graphql/apollo-operations'
import { trackUpdateTaxCalculationType } from '../../../common/util/MetricsTracking'
import { ContractForProjectHome } from '../home/ProjectHome'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'
import { TaxCalculationOptions } from './TaxCalculationOptions'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
  divider: {
    width: '100%',
    backgroundColor: colors.grey20,
    height: 1,
    margin: theme.spacing(2, 0, 2, 0),
  },
  notSupported: {
    marginBottom: theme.spacing(2),
  },
}))

const i18nBase = 'projects.subcontractors.settings.taxes'

interface TaxesSettingsProps {
  contract?: ContractForProjectHome
}

/** Shows a project's tax settings and allows editing */
export function TaxesSettings({ contract }: TaxesSettingsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const { name: projectName, isContractActive } = useProjectContext()
  const { permissions } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  const [updateTaxCalculationType] = useUpdateTaxCalculationTypeMutation()
  const [updateContractMutation] = useUpdateContractMutation()

  const [initialCalculationType, initialDefaultTaxGroup] = useMemo(() => {
    if (!contract) {
      return [TaxCalculationType.NONE, null]
    }
    return [contract.taxCalculationType, contract.defaultTaxGroup]
  }, [contract])
  const [selectedCalculationType, setSelectedCalculationType] =
    useState<TaxCalculationType>(initialCalculationType)
  const [defaultTaxGroup, setDefaultTaxGroup] = useState<TaxGroupProperties | null>(
    initialDefaultTaxGroup
  )

  const initialAppliesTaxToRetention = useMemo(
    () => contract?.appliesTaxToRetention ?? false,
    [contract?.appliesTaxToRetention]
  )
  const [appliesTaxToRetention, setAppliesTaxToRetention] = useState<boolean>(
    initialAppliesTaxToRetention
  )

  const taxGroups = useMemo(() => (contract ? [...contract.company.taxGroups] : []), [contract])

  const resetState = useCallback(() => {
    setSelectedCalculationType(initialCalculationType)
    setDefaultTaxGroup(initialDefaultTaxGroup)
    setAppliesTaxToRetention(initialAppliesTaxToRetention)
    setHasEdited(false)
  }, [initialCalculationType, initialDefaultTaxGroup, initialAppliesTaxToRetention])

  // If the contract loads or its tax setting changes, update the state
  useEffect(() => {
    setSelectedCalculationType(initialCalculationType)
    setDefaultTaxGroup(initialDefaultTaxGroup)
    setAppliesTaxToRetention(initialAppliesTaxToRetention)
  }, [initialCalculationType, initialDefaultTaxGroup, initialAppliesTaxToRetention])

  const handleSave = useCallback(async () => {
    if (!contract) {
      return
    }

    try {
      if (initialAppliesTaxToRetention !== appliesTaxToRetention) {
        await updateContractMutation({
          variables: {
            input: {
              id: contract.id,
              appliesTaxToRetention,
            },
          },
        })
      }

      if (
        initialCalculationType !== selectedCalculationType ||
        initialDefaultTaxGroup?.id !== defaultTaxGroup?.id
      ) {
        await updateTaxCalculationType({
          variables: {
            input: {
              contractId: contract.id,
              taxCalculationType: selectedCalculationType,
              defaultTaxGroupId: defaultTaxGroup?.id ?? null,
            },
          },
        })
      }

      snackbar.showSuccess(t(`${i18nBase}.success`))
      setHasEdited(false)
      trackUpdateTaxCalculationType({
        contractId: contract.id,
        projectName,
        fromTaxCalculationType: initialCalculationType,
        toTaxCalculationType: selectedCalculationType,
      })
    } catch (err) {
      snackbar.showError(err.message)
      resetState()
      setHasEdited(false)
    }
  }, [
    contract,
    initialAppliesTaxToRetention,
    appliesTaxToRetention,
    initialCalculationType,
    selectedCalculationType,
    initialDefaultTaxGroup?.id,
    defaultTaxGroup?.id,
    snackbar,
    t,
    projectName,
    updateContractMutation,
    updateTaxCalculationType,
    resetState,
  ])

  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
      confirmSaveMessage:
        contract?.billingType === BillingType.QUICK
          ? t('projects.subcontractors.settings.confirm.quick_details')
          : undefined,
    }),
    [contract?.billingType, handleSave, hasEdited, resetState, t]
  )

  const disableSave = useMemo(() => {
    switch (selectedCalculationType) {
      case TaxCalculationType.NONE:
      case TaxCalculationType.MULTIPLE_TAX_GROUPS:
        return false
      case TaxCalculationType.SINGLE_TAX_GROUP:
        return !defaultTaxGroup
    }
  }, [selectedCalculationType, defaultTaxGroup])

  const handleSelectedCalculationTypeChange = useCallback(
    (calculationType: TaxCalculationType) => {
      if (calculationType !== initialCalculationType) {
        setHasEdited(true)
      }
      setSelectedCalculationType(calculationType)
    },
    [initialCalculationType]
  )

  const handleDefaultTaxGroupChange = useCallback(
    (taxGroup: TaxGroupProperties | null) => {
      if (taxGroup?.id !== initialDefaultTaxGroup?.id) {
        setHasEdited(true)
      }
      setDefaultTaxGroup(taxGroup)
    },
    [initialDefaultTaxGroup?.id]
  )

  // Build a list of integrations that don't support taxes
  const noTaxIntegrations = useMemo(() => {
    const integrations = contract?.integrations ?? []
    // Tax group mappings are used for writing back to the integration, so we check whether any
    // integrations will support writing invoices with tax groups
    return integrations.filter(
      ({ type, isActive }) => !supportsWriteTaxCalculation(type) && isActive
    )
  }, [contract?.integrations])

  // Build a list of integrations that support writing only a single tax code per invoice
  const oneTaxIntegrations = useMemo(() => {
    const integrations = contract?.integrations ?? []
    const activeIntegrations = integrations.filter(({ isActive }) => isActive)
    const integrationsThatSupportWritingSingleTaxCode = activeIntegrations.filter(({ type }) =>
      supportsWriteTaxCalculation(type, TaxCalculationType.SINGLE_TAX_GROUP)
    )
    const integrationsThatOnlySupportSingleTaxCode =
      integrationsThatSupportWritingSingleTaxCode.filter(
        ({ type }) => !supportsWriteTaxCalculation(type, TaxCalculationType.MULTIPLE_TAX_GROUPS)
      )
    return integrationsThatOnlySupportSingleTaxCode
  }, [contract?.integrations])

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={t(`${i18nBase}.taxes`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        bulkSaveProps={bulkSaveProps}
        disableSave={disableSave}
      />
      {noTaxIntegrations.length > 0 && (
        <SitelineAlert severity="info" className={classes.notSupported}>
          {t(`${i18nBase}.not_supported_by_integration`, {
            integrationName: getIntegrationName(noTaxIntegrations[0].type),
          })}
        </SitelineAlert>
      )}
      {oneTaxIntegrations.length > 0 && (
        <SitelineAlert severity="info" className={classes.notSupported}>
          {t(`${i18nBase}.does_not_support_multiple_tax_codes`, {
            integrationName: getIntegrationName(oneTaxIntegrations[0].type),
          })}
        </SitelineAlert>
      )}
      <TaxCalculationOptions
        isEditing={isEditing}
        canEdit={canEdit}
        selectedCalculationType={selectedCalculationType}
        onSelectedCalculationTypeChange={handleSelectedCalculationTypeChange}
        defaultTaxGroup={defaultTaxGroup}
        onDefaultTaxGroupChange={handleDefaultTaxGroupChange}
        taxGroups={taxGroups}
        location="settings"
      />
      <div className={classes.divider} />
      <SettingsRow
        label={t(`${i18nBase}.apply_to_retention`)}
        isLoading={!contract}
        value={appliesTaxToRetention ? t(`${i18nBase}.on`) : t(`${i18nBase}.off`)}
        editingValue={
          <Switch
            checked={appliesTaxToRetention}
            onChange={(event) => {
              setAppliesTaxToRetention(event.target.checked)
              setHasEdited(true)
            }}
            className={classes.switch}
          />
        }
        isEditing={isEditing}
      />
    </div>
  )
}
