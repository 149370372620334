import { ApolloCache } from '@apollo/client'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useMemo } from 'react'
import {
  LegalDocumentStatus,
  LienWaiverCategory,
  MAX_EMAIL_MESSAGE_CHARACTERS,
  dollarsToCents,
  formatLienWaiverAmount,
  pdfTypes,
} from 'siteline-common-all'
import { IntegrationVendorInvoice, evictWithGc, getPayAppMonth } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { PayAppForProjectHome } from '../../components/billing/home/ProjectHome'
import { getLienWaiverStatus } from '../../components/vendors/Vendors.lib'
import { ContractForTemplates } from '../../components/vendors/vendor-lien-waivers/UploadLienWaiverDialog'
import { PayApp } from '../graphql/Fragments'
import {
  ContractForVendorsProjectHome,
  FormTemplateAnnotationMetadataFieldType,
  FormTemplateAnnotationValueInput,
  GetProjectLienWaiversByMonthDocument,
  GetProjectLienWaiversByMonthQuery,
  GetVendorLienWaiversByMonthDocument,
  LienWaiverFormTemplateProperties,
  LienWaiverProperties,
  LienWaiverType,
  MinimalSovProperties,
  VendorContactProperties,
  VendorProperties,
  useCreateVendorLienWaiverMutation,
  useGetVendorsQuery,
} from '../graphql/apollo-operations'
import { getLienWaiverInvoiceAmountForCategory } from './IntegrationVendorInvoices'
import { LienWaiverTypeForI18n } from './PayApp'

export const conditionalLienWaiverTypes = [
  LienWaiverType.CONDITIONAL_FINAL_PAYMENT,
  LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT,
]

export const unconditionalLienWaiverTypes = [
  LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT,
  LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT,
]

export function filterLienWaiversByType<T extends Pick<LienWaiverProperties, 'type'>>(
  lienWaivers: readonly T[] | T[],
  type: LienWaiverCategory
): T[] {
  switch (type) {
    case LienWaiverCategory.CONDITIONAL:
      return lienWaivers.filter((lien) => conditionalLienWaiverTypes.includes(lien.type))
    case LienWaiverCategory.UNCONDITIONAL:
      return lienWaivers.filter((lien) => unconditionalLienWaiverTypes.includes(lien.type))
  }
}

export const getLienWaiverCategoryForType = (type: LienWaiverType) =>
  conditionalLienWaiverTypes.includes(type)
    ? LienWaiverCategory.CONDITIONAL
    : LienWaiverCategory.UNCONDITIONAL

export const getLienWaiverCategoryForI18n = (type: LienWaiverType) => {
  const category = getLienWaiverCategoryForType(type)
  switch (category) {
    case LienWaiverCategory.CONDITIONAL:
      return 'conditional' as const
    case LienWaiverCategory.UNCONDITIONAL:
      return 'unconditional' as const
  }
}

export const getLienWaiverTypeForI18n = (type: LienWaiverType): LienWaiverTypeForI18n =>
  [LienWaiverType.CONDITIONAL_FINAL_PAYMENT, LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT].includes(
    type
  )
    ? 'final'
    : 'progress'

export function lienWaiverCategoryText(category: LienWaiverCategory, t: TFunction) {
  switch (category) {
    case LienWaiverCategory.CONDITIONAL:
      return t('vendor_home.lien_waiver_category.conditional')
    case LienWaiverCategory.UNCONDITIONAL:
      return t('vendor_home.lien_waiver_category.unconditional')
  }
}

/**
 * Returns a lien waiver for the primary subcontractor. This means any lien waivers that are
 * attached to a lower-tier are filtered out and not returned.
 * @param lienWaivers The lien waivers from which we're looking for a primary lien waiver
 * @param type The type of lien waiver we're looking for
 */
export function findPrimaryLienWaiverByType<
  T extends { type: LienWaiverType; vendorContract: { id: string } | null | undefined },
>(lienWaivers: T[], type: LienWaiverCategory): T | undefined {
  // Remove any vendor-based lien waiver requests from the list of lien waivers
  const nonVendorLienWaivers = lienWaivers.filter((lienWaiver) => !lienWaiver.vendorContract)

  let lienWaiver = undefined
  switch (type) {
    case LienWaiverCategory.CONDITIONAL:
      lienWaiver = nonVendorLienWaivers.find((lien) =>
        conditionalLienWaiverTypes.includes(lien.type)
      )
      break
    case LienWaiverCategory.UNCONDITIONAL:
      lienWaiver = nonVendorLienWaivers.find((lien) =>
        unconditionalLienWaiverTypes.includes(lien.type)
      )
      break
  }
  return lienWaiver
}

/**
 * Returns a list of vendor lien waivers on a PayApp, removing the primary lien waivers from the
 * list.
 */
export function getVendorLienWaivers(payApp: PayApp): PayApp['lienWaivers'] {
  return payApp.lienWaivers.filter((lienWaiver) => lienWaiver.vendorContract)
}

export function getLienWaiverOfType<T extends Pick<LienWaiverProperties, 'type'>>(
  lienWaivers: readonly T[] | T[],
  type: LienWaiverCategory
): T | undefined {
  if (type === LienWaiverCategory.CONDITIONAL) {
    return lienWaivers.find((lienWaiver) => conditionalLienWaiverTypes.includes(lienWaiver.type))
  }
  return lienWaivers.find((lienWaiver) => unconditionalLienWaiverTypes.includes(lienWaiver.type))
}

export function filterLienWaiverRequestsByType<T extends { lienWaiver: { type: LienWaiverType } }>(
  requests: readonly T[] | T[],
  type?: LienWaiverCategory
): T[] {
  let filteredRequests = [...requests]
  if (type === LienWaiverCategory.CONDITIONAL) {
    filteredRequests = requests.filter((request) =>
      conditionalLienWaiverTypes.includes(request.lienWaiver.type)
    )
  } else if (type === LienWaiverCategory.UNCONDITIONAL) {
    filteredRequests = requests.filter((request) =>
      unconditionalLienWaiverTypes.includes(request.lienWaiver.type)
    )
  }
  return filteredRequests
}

export type ThroughDatesByVendor = { [vendorId: string]: moment.Moment[] }

export type PartialVendorContact = Pick<
  VendorContactProperties,
  'id' | 'fullName' | 'email' | 'phoneNumber' | 'jobTitle'
> & {
  companyName: string
  vendorId?: string
}

export function makeEmptyPartialVendorContact(
  vendor?: Pick<VendorProperties, 'id' | 'name'>
): PartialVendorContact {
  return {
    id: uuidv4(),
    fullName: '',
    email: '',
    companyName: vendor?.name ?? '',
    vendorId: vendor?.id ?? '',
    phoneNumber: null,
    jobTitle: null,
  }
}

/** Returns true if all required info exists for a vendor contact */
export function isCompleteVendorContact(vendorContact: PartialVendorContact) {
  return !!(vendorContact.fullName && vendorContact.email && vendorContact.companyName)
}

export function useVendors(companyId: string | undefined) {
  const { data: vendorsData, loading } = useGetVendorsQuery({
    variables: {
      input: {
        companyId: companyId ?? '',
      },
    },
    skip: !companyId,
  })
  const vendors = useMemo(() => [...(vendorsData?.vendors ?? [])], [vendorsData?.vendors])
  return { vendors, loading }
}

export function getAllowedLowerTierLienWaiverTypes(contract?: ContractForTemplates) {
  const allowedLienWaiverTypes = []
  // We are guaranteed to have at least 1 template if the templates are set. Allow each type if a
  // template exists and is ready for use.
  if (contract && contract.lowerTierLienWaiverTemplates) {
    const templates = contract.lowerTierLienWaiverTemplates
    if (templates.conditionalFinalVariant?.template.isCustomerReady) {
      allowedLienWaiverTypes.push(LienWaiverType.CONDITIONAL_FINAL_PAYMENT)
    }
    if (templates.conditionalProgressVariant?.template.isCustomerReady) {
      allowedLienWaiverTypes.push(LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT)
    }
    if (templates.unconditionalFinalVariant?.template.isCustomerReady) {
      allowedLienWaiverTypes.push(LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT)
    }
    if (templates.unconditionalProgressVariant?.template.isCustomerReady) {
      allowedLienWaiverTypes.push(LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT)
    }
  }

  return allowedLienWaiverTypes
}

/**
 * Gets a list of available lien waiver types depending on the category and whether a template for
 * that lien waiver type exists.
 */
export function getAvailableLienWaiverTypes(
  hasSentRequest: boolean,
  allLienWaivers: LienWaiverProperties[],
  lienWaiver: LienWaiverProperties,
  date: moment.Moment,
  contract?: ContractForTemplates
): LienWaiverType[] {
  let category: LienWaiverCategory | undefined
  if (!hasSentRequest) {
    const matchingLienWaivers = allLienWaivers.filter(
      (otherLienWaiver) =>
        otherLienWaiver.contract.id === lienWaiver.contract.id &&
        otherLienWaiver.vendorContract?.id === lienWaiver.vendorContract?.id &&
        moment.tz(otherLienWaiver.date, otherLienWaiver.timeZone).isSame(date, 'month') &&
        otherLienWaiver.id !== lienWaiver.id
    )
    const hasConditional = matchingLienWaivers.some(
      (matchingLienWaiver) =>
        getLienWaiverCategoryForType(matchingLienWaiver.type) === LienWaiverCategory.CONDITIONAL
    )
    const hasUnconditional = matchingLienWaivers.some(
      (matchingLienWaiver) =>
        getLienWaiverCategoryForType(matchingLienWaiver.type) === LienWaiverCategory.UNCONDITIONAL
    )
    if (hasConditional) {
      category = LienWaiverCategory.UNCONDITIONAL
    } else if (hasUnconditional) {
      category = LienWaiverCategory.CONDITIONAL
    }
  }

  const allowedLienWaiverTypes: LienWaiverType[] = getAllowedLowerTierLienWaiverTypes(contract)

  let availableTypes: LienWaiverType[] = []
  if (category === LienWaiverCategory.CONDITIONAL) {
    availableTypes = allowedLienWaiverTypes.filter((type) =>
      conditionalLienWaiverTypes.includes(type)
    )
  } else if (category === LienWaiverCategory.UNCONDITIONAL) {
    availableTypes = allowedLienWaiverTypes.filter((type) =>
      unconditionalLienWaiverTypes.includes(type)
    )
  } else {
    availableTypes = allowedLienWaiverTypes
  }

  // Catch all scenario in case the category doesn't match up with the available template types.
  if (availableTypes.length === 0) {
    availableTypes = allowedLienWaiverTypes
  }

  return availableTypes
}

type LienWaiverForCache = {
  type: LienWaiverType
  contract: { id: string }
  vendorContract?: { id: string; vendor: { id: string } } | null
  date: string
  timeZone: string
}
export function clearVendorTrackerLienWaiverCache(
  cache: ApolloCache<unknown>,
  lienWaiver: LienWaiverForCache
): void {
  const month = moment.tz(lienWaiver.date, lienWaiver.timeZone)

  // Refresh summary cells in the tracker for both the contract and vendor
  evictWithGc(cache, (evict) => {
    evict({
      id: 'ROOT_QUERY',
      fieldName: 'lienWaiversMonthSummary',
      args: {
        input: {
          category: getLienWaiverCategoryForType(lienWaiver.type),
          month: month.month(),
          year: month.year(),
          contractId: lienWaiver.contract.id,
        },
      },
    })
    if (lienWaiver.vendorContract) {
      evict({
        id: 'ROOT_QUERY',
        fieldName: 'lienWaiversMonthSummary',
        args: {
          input: {
            category: getLienWaiverCategoryForType(lienWaiver.type),
            month: month.month(),
            year: month.year(),
            vendorId: lienWaiver.vendorContract.vendor.id,
          },
        },
      })
      evict({
        id: 'ROOT_QUERY',
        fieldName: 'lienWaiversMonth',
        args: {
          input: {
            category: getLienWaiverCategoryForType(lienWaiver.type),
            month: month.month(),
            year: month.year(),
            vendorContractId: lienWaiver.vendorContract.id,
          },
        },
      })
    }
  })
}

/** Returns a hook for creating a vendor lien waiver from the lien waiver tracker */
export function useCreateVendorLienWaiver({
  companyId,
  vendorIds,
  projectIds,
}: {
  companyId: string
  vendorIds?: string[]
  projectIds?: string[]
}) {
  const refetchQueries = [
    ...(vendorIds && vendorIds.length > 0
      ? [
          {
            query: GetVendorLienWaiversByMonthDocument,
            variables: { input: { vendorIds, companyId } },
          },
        ]
      : []),
    ...(projectIds && projectIds.length > 0
      ? [
          {
            query: GetProjectLienWaiversByMonthDocument,
            variables: { input: { projectIds, companyId } },
          },
        ]
      : []),
  ]
  const [createLienWaiver, { loading, error }] = useCreateVendorLienWaiverMutation({
    // Wait until refetches are complete to show snackbar so the lien waiver is removed from the page
    awaitRefetchQueries: true,
    refetchQueries,
    update(cache, { data }) {
      if (data?.createVendorLienWaiver) {
        clearVendorTrackerLienWaiverCache(cache, data.createVendorLienWaiver)
      }
    },
  })
  return {
    loading,
    error,
    createLienWaiver,
    refetchQueries,
  }
}

/**
 * Checks to see if the passed in data should result in a final lien waiver or not. By default,
 * we should look at the SOV as there are cases where a specific pay app may think it's the final
 * but there are change orders that exist afterwards, which means the contract is still ongoing.
 */
export function requiresFinalLienWaiver(
  payApp?: Pick<PayAppForProjectHome, 'balanceToFinish' | 'totalRetention'> | null,
  sov?: Pick<MinimalSovProperties, 'totalBilled' | 'totalValue'> | null
): boolean {
  if (!payApp) {
    return false
  }

  // To determine if the final lien waiver is needed:
  //  - if SOV is not complete, it's never a final lien waiver
  //  - otherwise, look at the pay app passed in
  if (sov && sov.totalValue !== sov.totalBilled) {
    return false
  } else {
    return payApp.balanceToFinish + payApp.totalRetention === 0
  }
}

/** Returns a LIEN_WAIVER_AMOUNT annotation if one exists on this lien waiver */
export function findLienWaiverAmountAnnotations(
  metadata: Pick<pdfTypes.PageMetadata, 'annotations'>[]
): pdfTypes.Annotation[] {
  return _.chain(metadata)
    .flatMap((singleMetadata) => singleMetadata.annotations)
    .filter((annotation) => isLienWaiverAmountAnnotation(annotation))
    .value()
}

/** Returns whether a lien waiver annotation is of fieldType=LIEN_WAIVER_AMOUNT */
export function isLienWaiverAmountAnnotation(
  annotation: Pick<pdfTypes.Annotation, 'fieldType'>
): boolean {
  return annotation.fieldType === FormTemplateAnnotationMetadataFieldType.LIEN_WAIVER_AMOUNT
}

/**
 * Checks to see if a given lien waiver contains an amount annotation
 */
export function isAmountRequiredForLienWaiver(
  lienWaiver: LienWaiverFormTemplateProperties | null
): boolean {
  const annotations = lienWaiver?.latestVersion?.annotations ?? []
  return annotations.some((annotation) => isLienWaiverAmountAnnotation(annotation))
}

/**
 * Finds the number value from a lien waiver amount input.
 * Note that the lien waiver input is of type string, and the user can enter anything they want.
 * The most likely case of the string being a non-number value is if the input defaults to something like
 * "3.00 - paid to date". This is a value that we apply to the input if the user checks the "paid to date"
 * box in the RequestLienWaiversDialog.
 *
 *
 * Returns number if exists
 * Returns undefined if an amount was not entered
 * Returns undefined if the string is "paid to date" (this suggests that the user has not entered an amount)
 * Returns null if the string cannot be parsed
 *
 * @example
 * getLienWaiverAmountFromTextEntry('3.00 - paid to date') // 300
 * getLienWaiverAmountFromTextEntry('3.00 - hello') // 300
 * getLienWaiverAmountFromTextEntry('3.00 and any other note') // 300
 * getLienWaiverAmountFromTextEntry('3.00') // 300
 * getLienWaiverAmountFromTextEntry('-3.00') // -300
 * getLienWaiverAmountFromTextEntry('-$3.00') // -300
 * getLienWaiverAmountFromTextEntry('$-3.00') // -300
 * getLienWaiverAmountFromTextEntry('') // undefined
 * getLienWaiverAmountFromTextEntry('paid to date') // undefined
 * getLienWaiverAmountFromTextEntry('hello') // null
 */
export function getLienWaiverAmountFromTextEntry(value: string): number | null | undefined {
  // We add "paid to date" or " - paid to date" to the text entry field if the user checks the "paid to date" in the
  // request lien waivers dialog. Treat these as non-entered amounts and return null.

  // Remove all dollar signs:
  const withoutDollars = value.replace(/\$/g, '').trim()
  // Remove all commas:
  const withoutCommas = withoutDollars.replace(/,/g, '')
  // Trim empty spaces:
  const trimmed = withoutCommas.trim()
  if (trimmed === '') {
    return undefined
  }
  if (trimmed.toLowerCase() === 'paid to date') {
    return undefined
  }

  // Match a valid number with an optional leading negative sign
  const match = trimmed.match(/-?[0-9]+(\.[0-9]*)?/)
  if (!match) {
    return null
  }

  const parsed = parseFloat(match[0])
  if (isNaN(parsed)) {
    return null
  }

  const amount = dollarsToCents(parsed)
  return amount
}

/**
 * Finds a LIEN_WAIVER_AMOUNT value on a completed lien-waiver.
 * Returns undefined if no LIEN_WAIVER_AMOUNT annotation exists, no value is found for that annotation,
 * or the value cannot be parsed to a number.
 *
 * Amount is returned as cents and rounded if necessary.
 */
export function getLienWaiverAmount(
  metadata: pdfTypes.PageMetadata[],
  formValuesInput: FormTemplateAnnotationValueInput[]
): number | null {
  const annotations = findLienWaiverAmountAnnotations(metadata)
  for (const annotation of annotations) {
    const formValueInput = formValuesInput.find(
      (input) => input.formTemplateAnnotationId === annotation.id
    )
    if (!formValueInput) {
      continue
    }

    // Remove suffix that we add when formatting the lien waiver amount with text
    // Try to find any number value within the text.
    const amount = getLienWaiverAmountFromTextEntry(formValueInput.value)
    if (amount !== undefined && amount !== null) {
      return amount
    }
  }
  return null
}

/**
 * Returns true if a month in the lien waiver tracker should be considered "not billing",
 * which means the project is opted out of billing for the month AND there are no lien waivers
 * for the month.
 */
export function isNotBillingLienWaivers<K, T extends { lienWaivers: readonly K[] }>(
  skippedMonth: boolean,
  lienWaiverGrouping: readonly T[]
) {
  return (
    skippedMonth && _.flatMap(lienWaiverGrouping.map(({ lienWaivers }) => lienWaivers)).length === 0
  )
}

export type ProjectLienWaiversByMonth =
  GetProjectLienWaiversByMonthQuery['getProjectLienWaiversByMonth']
export type LienWaiversByMonth = ProjectLienWaiversByMonth[number]['lienWaiversByMonth'][number]
export type PreSitelinePayApp = ContractForVendorsProjectHome['preSitelinePayApps'][number]
export type PreSitelinePayAppWithBillingEnd = PreSitelinePayApp & {
  billingEnd: string
}

/**
 * Determine the earliest month to show in the lien waivers page, depending on the pay apps,
 * pre-siteline pay apps, and existing lien waivers
 */
export function getEarliestLienWaiverMonth({
  contract,
  lienWaiversByMonth,
  timeZone,
}: {
  contract: ContractForVendorsProjectHome
  lienWaiversByMonth: LienWaiversByMonth[]
  timeZone: string
}) {
  // Earliest siteline pay app
  const payAppMonths = contract.payApps.map((payApp) => getPayAppMonth(payApp, timeZone))
  const earliestPayAppMonth = _.minBy(payAppMonths, (month) => month.unix()) ?? moment.tz(timeZone)

  // Earliest pre-siteline pay app month:
  //  - If there is no pre-siteline pay app, use the earliest pay app month minus the number of
  //    past pay apps
  //  - If there is a pre-siteline pay app, use the earliest pre-siteline pay app month minus the
  //    number of pay apps before it
  const preSitelinePayAppsWithBillingEnd = contract.preSitelinePayApps.filter(
    (payApp): payApp is PreSitelinePayAppWithBillingEnd => Boolean(payApp.billingEnd)
  )
  const earliestPreSitelinePayApp = _.minBy(preSitelinePayAppsWithBillingEnd, (payApp) =>
    getPayAppMonth(payApp, timeZone)
  )
  let earliestPreSitelinePayAppMonth: Moment
  if (earliestPreSitelinePayApp) {
    const numPayAppsBeforeEarliestPreSitelinePayApp = earliestPreSitelinePayApp.payAppNumber - 1
    earliestPreSitelinePayAppMonth = getPayAppMonth(earliestPreSitelinePayApp, timeZone).subtract(
      numPayAppsBeforeEarliestPreSitelinePayApp,
      'months'
    )
  } else {
    earliestPreSitelinePayAppMonth = earliestPayAppMonth
      .clone()
      .subtract(contract.pastPayAppCount, 'months')
  }

  // Earliest lien waiver
  const lienWaiversMonths = lienWaiversByMonth.map((lienWaiver) =>
    // Moment-timezone doesn't have a year/month/day constructor, so we have to use moment directly.
    // We use day 15 so that timezones don't matter.
    // eslint-disable-next-line momentjs/no-moment-constructor
    moment({ year: lienWaiver.year, month: lienWaiver.month, day: 15 }).tz(timeZone)
  )
  const earliestLienWaiverMonth =
    _.minBy(lienWaiversMonths, (month) => month.unix()) ?? moment.tz(timeZone)

  // Take the min of all of these
  return moment.min(earliestPayAppMonth, earliestPreSitelinePayAppMonth, earliestLienWaiverMonth)
}

/** Given two Moment objects - earliest lien waiver month and today - returns an array of months */
export function getLienWaiverMonths({
  earliestMonth,
  today,
}: {
  earliestMonth: Moment
  today: Moment
}) {
  const month = earliestMonth.clone()
  const allMonths = [month.clone()]
  while (month.isBefore(today, 'month')) {
    month.add(1, 'month')
    allMonths.push(month.clone())
  }
  return allMonths.reverse()
}

/**
 * Given a lien waiver and a set of other lien waivers, finds the matching conditional lien waiver
 * (i.e. a conditional lien waiver for the same vendor contract and month) and returns its amount
 */
export function getLienWaiverAmountFromConditional(
  lienWaiver: LienWaiverProperties,
  projectLienWaivers: LienWaiverProperties[]
): number | null {
  const vendorContractId = lienWaiver.vendorContract?.id
  const requestingDate = moment.tz(lienWaiver.date, lienWaiver.timeZone)
  if (!vendorContractId) {
    return null
  }
  const conditionalLienWaiver = projectLienWaivers.find((projectLienWaiver) => {
    const hasSameVendorContract = projectLienWaiver.vendorContract?.id === vendorContractId
    const lienWaiverDate = moment.tz(projectLienWaiver.date, projectLienWaiver.timeZone)
    const hasSameDate = requestingDate.isSame(lienWaiverDate, 'month')
    const isConditional = [
      LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT,
      LienWaiverType.CONDITIONAL_FINAL_PAYMENT,
    ].includes(projectLienWaiver.type)
    return hasSameVendorContract && isConditional && hasSameDate
  })
  if (!conditionalLienWaiver) {
    return null
  }
  return conditionalLienWaiver.amount
}

/** Returns the default list of vendor contact recipients for a lien waiver request */
export function getDefaultLienWaiverRequestRecipients(
  lienWaiver: LienWaiverProperties,
  projectLienWaivers: LienWaiverProperties[]
): VendorContactProperties[] {
  // If the lien waiver has a list of default recipients, use that. We check if the list has been
  // updated by the user, and if so we use that list (which may be empty).
  if (lienWaiver.hasSelectedDefaultVendorContacts) {
    return [...lienWaiver.defaultVendorContacts]
  }

  let allLienWaiverRecipients = [...(lienWaiver.vendorContract?.vendor.contacts ?? [])]

  // Check to see if we've ever sent a lien waiver to this vendor for this project. If we have,
  // then use the most recent request's contacts as our base recipients.
  if (lienWaiver.vendorContract) {
    const vendorContractId = lienWaiver.vendorContract.id
    const latestSentContacts = _.chain(projectLienWaivers)
      // Match the vendorContract
      .filter((existingLienWaiver) => vendorContractId === existingLienWaiver.vendorContract?.id)
      // Make sure the lien waiver has existing requests
      .filter((existingLienWaiver) => existingLienWaiver.lienWaiverRequests.length > 0)
      // Find the most recent lien waiver with the request that was most recently sent
      .orderBy(
        (existingLienWaiver) =>
          _.chain(existingLienWaiver.lienWaiverRequests)
            .orderBy((request) => moment.tz(request.createdAt, lienWaiver.timeZone), 'desc')
            .slice(0, 1)
            .value(),
        'desc'
      )
      // Now look at just the first lien waiver
      .slice(0, 1)
      // Get all of the lien waiver requests from the most recently sent lien waiver
      .flatMap((existingLienWaiver) => existingLienWaiver.lienWaiverRequests)
      // Pull out just the contact
      .map((existingRequest) => existingRequest.vendorContact)
      .value()
    // If we actually found anyone, reassign the pool of all recipients to them
    if (latestSentContacts.length > 0) {
      allLienWaiverRecipients = latestSentContacts
    }
  }

  // If not a sub-tier, use only first-tier vendor contacts
  let lienWaiverRecipients = allLienWaiverRecipients.filter((contact) =>
    _.isNil(contact.lowerTierTo)
  )
  if (lienWaiver.vendorContract?.lowerTierTo) {
    // If this is a sub-tier vendor, use vendor contacts for the corresponding second-tier vendor
    // if they exist
    const lowerTierToVendorId = lienWaiver.vendorContract.lowerTierTo.vendor.id
    const subTierContacts = allLienWaiverRecipients.filter((contact) => {
      return contact.lowerTierTo?.id === lowerTierToVendorId
    })
    if (subTierContacts.length > 0) {
      // Only overwrite the recipients if there are contacts specially added for this sub-tier
      // relationship. If not, leave the default contacts that exist for this vendor.
      lienWaiverRecipients = subTierContacts
    }
  }
  return lienWaiverRecipients
}

/** Returns true if a lien waiver has previously been requested */
export function hasSentLienWaiverRequest(lienWaiver: LienWaiverProperties) {
  const legalDocumentStatus = getLienWaiverStatus(
    lienWaiver,
    lienWaiver.lienWaiverRequests,
    lienWaiver.timeZone
  )
  return legalDocumentStatus.status !== LegalDocumentStatus.NOT_REQUESTED
}

/** Given a code and amount, applies dollar format to the amount and returns one string representing both values */
export function createLienWaiverNoteFromInvoiceCode(code: string, amount: number): string {
  const formattedAmount = formatLienWaiverAmount(amount)
  return `${code} - $${formattedAmount}`
}

/** Given a list of invoices, generate an email message listing the invoices and their amounts */
export function createLienWaiverNoteFromInvoices(
  invoices: IntegrationVendorInvoice[],
  category: LienWaiverCategory
): string {
  return invoices
    .map((invoice) => {
      const invoiceAmount = getLienWaiverInvoiceAmountForCategory(invoice, category)
      return createLienWaiverNoteFromInvoiceCode(invoice.code, invoiceAmount)
    })
    .join('\n')
}

/**
 * Given a lien waiver message, returns the message truncated at the max email character length.
 * If the message is a list of invoice amounts (ex: the result of `createLienWaiverNoteFromInvoices`),
 * the message will be cut off at the last whole invoice line.
 */
export function truncateLienWaiverMessage(message: string) {
  return _.truncate(message, { length: MAX_EMAIL_MESSAGE_CHARACTERS, separator: '\n' })
}

/**
 * This is used when deciding which lien waiver type to create based on the user-selected
 * category (i.e. conditional/unconditional) and their available form types. So long as they
 * have the progress form template, we will default to progress lien waivers.
 */
export function getDefaultLienWaiverTypeFromFormsAndCategory(
  category: LienWaiverCategory,
  typesWithForms: LienWaiverType[]
) {
  switch (category) {
    case LienWaiverCategory.CONDITIONAL:
      return typesWithForms.includes(LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT)
        ? LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT
        : LienWaiverType.CONDITIONAL_FINAL_PAYMENT
    case LienWaiverCategory.UNCONDITIONAL:
      return typesWithForms.includes(LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT)
        ? LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT
        : LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT
  }
}

/**
 * This is used by our dialog that creates lien waivers. We reference this util to ensure that
 * we don't create duplicate lien waivers of the same vendor contract/month/lien waiver category/type.
 * Given a lien waiver category, we return a list of vendor contract ids for which that lien waiver
 * has already been created.
 */
export function getVendorContractIdsWithLienWaiverCategory(
  monthLienWaivers: LienWaiverProperties[],
  category: LienWaiverCategory
) {
  return _.chain(monthLienWaivers)
    .filter((lienWaiver) => getLienWaiverCategoryForType(lienWaiver.type) === category)
    .map((lienWaiver) => lienWaiver.vendorContract?.id)
    .compact()
    .value()
}

/*
 * Returns true if a string matches the format of the note we generate based on a list of
 * integration invoices
 */
export function isLienWaiverNoteGeneratedFromInvoices(note: string): boolean {
  // This regex expression matches one or more lines, each matching the format:
  // <one or more characters> - $<one or more characters>
  return note.match(/^((.+ - \$.+)(\n)?)+$/) !== null
}
