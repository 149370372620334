import { Button, Fade, Popper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { colors, Column, Row, SitelineText } from 'siteline-common-web'
import { Z_INDEX } from '../../themes/Main'

const StyledPopper = styled(Popper)(() => ({ zIndex: Z_INDEX.popover }))
const StyledSpreadsheetHint = styled(Column)(({ theme }) => ({
  maxWidth: 275,
  backgroundColor: colors.white,
  boxShadow: theme.shadows[10],
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  '& .MuiButton-root': {
    margin: theme.spacing(0, 0, -1, -1),
    height: 32,
    minWidth: 0,
    padding: theme.spacing(1),
    '&.skipButton': {
      color: colors.grey50,
    },
  },
}))

export type SpreadsheetHintContent = {
  body: string
  action: string
  onAccept: () => void
  onShown: () => void
  onDismissed: () => void
}

export type SpreadsheetHint = SpreadsheetHintContent & {
  anchorEl: HTMLElement
  // If a hint with the same ID is already visible, a new hint will not be shown (and the popover
  // will not move to the new location). If a hint with a different ID is visible, it will be closed
  // and this hint will be shown.
  hintId: string
}

const HINT_TIMEOUT = 6000

interface SpreadsheetHintProps {
  hint: SpreadsheetHint
  isOpen: boolean
  onClose: () => void
}

/**
 * A temporary popoover on a spreadsheet cell for giving the user a contextual hint or suggestion
 */
export function SpreadsheetHint({ isOpen, onClose, hint }: SpreadsheetHintProps) {
  const { t } = useTranslation()
  const timer = useRef<NodeJS.Timeout | null>(null)

  // The hint automatically closes after several seconds if not interacted with
  useEffect(() => {
    if (isOpen) {
      timer.current = setTimeout(onClose, HINT_TIMEOUT)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [isOpen, onClose])

  // Call the `onShown` callback when the hint becomes visible (used for metrics)
  useEffect(() => {
    if (isOpen) {
      hint.onShown()
    }
  }, [hint, isOpen])

  const handleActionClick = useCallback(() => {
    hint.onAccept()
    onClose()
    if (timer.current) {
      clearTimeout(timer.current)
    }
  }, [hint, onClose])

  const handleClose = useCallback(() => {
    onClose()
    if (timer.current) {
      clearTimeout(timer.current)
    }
    hint.onDismissed()
  }, [hint, onClose])

  return (
    <StyledPopper open={isOpen} anchorEl={hint.anchorEl} placement="top-start" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <div>
            <StyledSpreadsheetHint gap={4} alignItems="flex-start">
              <SitelineText variant="smallText">{hint.body}</SitelineText>
              <Row gap={8}>
                <Button
                  variant="text"
                  size="small"
                  className="actionButton"
                  onClick={handleActionClick}
                >
                  {hint.action}
                </Button>
                <Button variant="text" size="small" className="skipButton" onClick={handleClose}>
                  {t('common.spreadsheet.no_thanks')}
                </Button>
              </Row>
            </StyledSpreadsheetHint>
          </div>
        </Fade>
      )}
    </StyledPopper>
  )
}
