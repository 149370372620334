import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormTemplateOnboardingSignatureType,
  FormTemplateType,
  MAX_ATTACHMENT_NOTES_LENGTH,
} from 'siteline-common-all'
import {
  Column,
  ProjectOnboardingFormType,
  SitelineText,
  Spacer,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { FormUploadBestPracticesHelperText } from '../../../common/components/FormUploadBestPracticesHelperText'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useUploadCompanyFormTemplatesMutation } from '../../../common/graphql/apollo-operations'
import { trackSelectedProjectForms } from '../../../common/util/MetricsTracking'
import {
  ContractOnboardingFormType,
  OnboardingFormsInstructions,
  PendingUploadFile,
} from '../../../common/util/ProjectOnboarding'
import { FileDragUpload, PendingFile } from '../backup/attachments/FileDragUpload'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .examples': {
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(-1),
    },
    '& .fileUpload': {
      marginTop: theme.spacing(2),
    },
    '& .formOnboardingSection': {
      gap: theme.spacing(1),
    },
    '& .signatureType': {
      '& .MuiCheckbox-root': {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0, 0.5),
      },
    },
  },
  menuItem: {
    // Override default max width set in `Main.ts`
    '&.MuiMenuItem-root': {
      maxWidth: 'unset',
    },
  },
}))

const i18nBase = 'projects.onboarding.checklist.form_upload_dialog'

const SLOT_PROPS = { htmlInput: { maxLength: MAX_ATTACHMENT_NOTES_LENGTH } }

const SORTED_SIGNATURE_TYPES = [
  FormTemplateOnboardingSignatureType.DIGITAL_SIGNATURE,
  FormTemplateOnboardingSignatureType.WET_SIGNATURE,
  FormTemplateOnboardingSignatureType.NONE,
]

enum FormSetType {
  PAY_APP_LUMP_SUM = 'PAY_APP_LUMP_SUM',
  PAY_APP_UNIT_PRICE = 'PAY_APP_UNIT_PRICE',
  PAY_APP_TIME_AND_MATERIALS = 'PAY_APP_TIME_AND_MATERIALS',
  PRIMARY_LIEN_WAIVER = 'PRIMARY_LIEN_WAIVER',
  VENDOR_LIEN_WAIVER = 'VENDOR_LIEN_WAIVER',
  CHANGE_ORDER_REQUEST = 'CHANGE_ORDER_REQUEST',
  CHANGE_ORDER_LOG = 'CHANGE_ORDER_LOG',
}

const DEFAULT_SIGNATURE_TYPE = SORTED_SIGNATURE_TYPES[0]
const DEFAULT_FORM_SET_TYPE = FormSetType.PAY_APP_LUMP_SUM

type FormInputInstructionSection =
  | 'formValues'
  | 'repeatingInputs'
  | 'specialConditions'
  | 'additionalInfo'

function instructionSectionsForFormsType(
  formsType: ContractOnboardingFormType
): FormInputInstructionSection[] {
  switch (formsType) {
    case ProjectOnboardingFormType.PAY_APP:
      return ['formValues', 'repeatingInputs', 'specialConditions', 'additionalInfo']
    case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
      return ['formValues', 'repeatingInputs', 'additionalInfo']
    case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
      return ['formValues', 'additionalInfo']
    case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
      return ['formValues', 'additionalInfo']
    case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
      return ['formValues', 'additionalInfo']
  }
}

interface ContractFormUploadProps {
  formsType: ContractOnboardingFormType
  onUploadForms: (
    pendingFiles: PendingFile[],
    instructions: OnboardingFormsInstructions,
    includeChangeOrderLogOnPayApps?: boolean
  ) => Promise<void>
  submitting?: boolean
}

interface OnboardingFormUploadDialogProps {
  /**
   * These props are used if the the form is being uploaded for a specific contract,
   * rather than for general use as a "form set".
   */
  contractFormUploadProps: ContractFormUploadProps | null
  open: boolean
  /** Exits the dialog. `fromButton` is true if triggered by tapping the Cancel button. */
  onClose: (fromButton: boolean) => void
  cancelLabel?: string
  helpCenterLinkScrollTo?: string
  /** Pass in undefined if this dialog is NOT for change order log forms */
  initialIncludeCoLogInPayAppPackage: boolean | undefined
}

/**
 * Dialog box for uploading new form templates. This is accessed from project onboarding,
 * project settings, and through the vendors module for lien waivers. This is also accessed
 * from the billing home page, project onboarding list. In this case, the dialog will allow the
 * user to select the form type and the expectation is that the forms will be uploaded as a
 * "form set", meaning they will be grouped together and can be selected as a group during
 * future project onboarding.
 **/
export function OnboardingFormUploadDialog({
  contractFormUploadProps,
  open,
  onClose,
  cancelLabel,
  helpCenterLinkScrollTo,
  initialIncludeCoLogInPayAppPackage,
}: OnboardingFormUploadDialogProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const [formSetType, setFormSetType] = useState<FormSetType>(DEFAULT_FORM_SET_TYPE)
  const [pendingFiles, setPendingFiles] = useState<PendingFile[]>([])
  const [repeatingValuesNote, setRepeatingValuesNote] = useState<string>('')
  const [formValuesNote, setFormValuesNote] = useState<string>('')
  const [specialFormsNote, setSpecialFormsNote] = useState<string>('')
  const [additionalInfoNote, setAdditionalInfoNote] = useState<string>('')
  const [requiresNotarization, setRequiresNotarization] = useState<boolean>(false)
  const [signatureType, setSignatureType] =
    useState<FormTemplateOnboardingSignatureType>(DEFAULT_SIGNATURE_TYPE)
  const [includeCoLogOnPayApps, setIncludeCoLogOnPayApps] = useState<boolean>(
    !!initialIncludeCoLogInPayAppPackage
  )

  const [uploadCompanyFormSet, { loading: submittingCompanyFormSet }] =
    useUploadCompanyFormTemplatesMutation()

  const isOnboardingCompanyFormSet = contractFormUploadProps === null

  const [formType, templateType]: [ContractOnboardingFormType, FormTemplateType | null] =
    useMemo(() => {
      if (isOnboardingCompanyFormSet) {
        switch (formSetType) {
          case FormSetType.PAY_APP_LUMP_SUM:
            return [ProjectOnboardingFormType.PAY_APP, FormTemplateType.PAY_APP_LUMP_SUM]
          case FormSetType.PAY_APP_UNIT_PRICE:
            return [ProjectOnboardingFormType.PAY_APP, FormTemplateType.PAY_APP_UNIT_PRICE]
          case FormSetType.PAY_APP_TIME_AND_MATERIALS:
            return [ProjectOnboardingFormType.PAY_APP, FormTemplateType.PAY_APP_TIME_AND_MATERIALS]
          case FormSetType.PRIMARY_LIEN_WAIVER:
            return [ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER, FormTemplateType.LIEN_WAIVER]
          case FormSetType.VENDOR_LIEN_WAIVER:
            return [ProjectOnboardingFormType.VENDOR_LIEN_WAIVER, FormTemplateType.LIEN_WAIVER]
          case FormSetType.CHANGE_ORDER_LOG:
            return [ProjectOnboardingFormType.CHANGE_ORDER_LOG, FormTemplateType.CHANGE_ORDER_LOG]
          case FormSetType.CHANGE_ORDER_REQUEST:
            return [
              ProjectOnboardingFormType.CHANGE_ORDER_REQUEST,
              FormTemplateType.CHANGE_ORDER_REQUEST,
            ]
        }
      }
      return [contractFormUploadProps.formsType, null]
    }, [contractFormUploadProps?.formsType, formSetType, isOnboardingCompanyFormSet])

  const shouldDisplaySignatureInput = formType !== ProjectOnboardingFormType.CHANGE_ORDER_LOG
  const requiresSignature = signatureType !== FormTemplateOnboardingSignatureType.NONE
  const shouldDisplayIncludeCoLogToggle =
    formType === ProjectOnboardingFormType.CHANGE_ORDER_LOG &&
    initialIncludeCoLogInPayAppPackage !== undefined

  const { title, subtitle, subscript } = useMemo(() => {
    if (isOnboardingCompanyFormSet) {
      return {
        title: t(`${i18nBase}.add_new_forms`),
        subtitle: '',
        subscript: '',
      }
    }
    switch (formType) {
      case ProjectOnboardingFormType.PAY_APP:
        return {
          title: t(`${i18nBase}.upload_pay_app_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.pay_app_upload_reminder`),
        }
      case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
        return {
          title: t(`${i18nBase}.upload_primary_lien_waiver_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.primary_lien_waiver_upload_reminder`),
        }
      case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
        return {
          title: t(`${i18nBase}.upload_vendor_lien_waiver_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.vendor_lien_waiver_upload_reminder`),
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
        return {
          title: t(`${i18nBase}.upload_cor_forms_title`),
          subtitle: '',
          subscript: t(`${i18nBase}.cor_upload_reminder`),
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
        return {
          title: t(`${i18nBase}.upload_cor_log_forms_title`),
          subtitle: t(`${i18nBase}.upload_cor_log_forms_subtitle`),
          subscript: shouldDisplayIncludeCoLogToggle ? (
            <IncludeChangeOrderLogInPayAppToggle
              shouldInclude={includeCoLogOnPayApps}
              onChange={setIncludeCoLogOnPayApps}
              disabled={false}
              shouldShowDisabledTooltip={false}
            />
          ) : undefined,
        }
    }
  }, [
    formType,
    includeCoLogOnPayApps,
    isOnboardingCompanyFormSet,
    shouldDisplayIncludeCoLogToggle,
    t,
  ])

  const signatureTypes = useMemo(() => {
    // This format is needed for backwards compatibility. `signatureTypes` used to be
    // multi-select. Now it's a single-select dropdown, and based on the selection,
    // `requiresNotarization` can additionally be checked on.
    if (!shouldDisplaySignatureInput) {
      return [FormTemplateOnboardingSignatureType.NONE]
    }
    if (requiresSignature && requiresNotarization) {
      return [signatureType, FormTemplateOnboardingSignatureType.NOTARIZATION]
    }
    return [signatureType]
  }, [shouldDisplaySignatureInput, requiresSignature, requiresNotarization, signatureType])

  const formTypeDropdownOptions = useMemo(() => {
    return Object.keys(FormSetType).map((formSetTypeOption) => {
      return {
        name: t(`${i18nBase}.form_set_types.${formSetTypeOption as keyof typeof FormSetType}`),
        value: FormSetType[formSetTypeOption as keyof typeof FormSetType],
      }
    })
  }, [t])

  const handleUpdatePendingFiles = useCallback(
    (update: PendingFile[]) => {
      if (pendingFiles.length === 0 && update.length > 0) {
        setIncludeCoLogOnPayApps(true)
      }
      if (update.length === 0) {
        setIncludeCoLogOnPayApps(false)
      }
      setPendingFiles(update)
    },
    [pendingFiles.length]
  )

  const handleRemovePendingFiles = useCallback(
    (index: number) => {
      const pendingFilesCopy = [...pendingFiles]
      pendingFilesCopy.splice(index, 1)
      handleUpdatePendingFiles(pendingFilesCopy)
    },
    [handleUpdatePendingFiles, pendingFiles]
  )

  const handleUploadSubmit = useCallback(async () => {
    const instructions: OnboardingFormsInstructions = {
      signatureTypes,
      // Pass undefined if any of the optional fields are empty
      repeatingValuesNote: repeatingValuesNote || undefined,
      formValuesNote: formValuesNote || undefined,
      specialFormsNote: specialFormsNote || undefined,
      note: additionalInfoNote || undefined,
    }

    if (isOnboardingCompanyFormSet) {
      try {
        if (templateType === null) {
          // This is just for typechecking; templateType will never be null
          // (See `templateType` definition above)
          return
        }
        const forms = pendingFiles
          .map(({ file, name }) => ({ file, name }))
          .filter((pendingFile) => pendingFile.file !== undefined) as PendingUploadFile[]

        await uploadCompanyFormSet({
          variables: {
            input: {
              companyId,
              forms,
              formType,
              templateType,
              includeChangeOrderLogOnPayApps:
                formType === ProjectOnboardingFormType.PAY_APP && includeCoLogOnPayApps,
              ...instructions,
            },
          },
        })
        trackSelectedProjectForms({
          formsType: formType,
          selectType: 'newForms',
          location: 'project-list',
        })
        snackbar.showSuccess(t(`${i18nBase}.uploaded_forms`))
      } catch (error) {
        snackbar.showError(error.message)
        return
      }
    } else {
      const { onUploadForms } = contractFormUploadProps
      const includeChangeOrderLogOnPayApps = shouldDisplayIncludeCoLogToggle
        ? includeCoLogOnPayApps
        : undefined
      await onUploadForms(pendingFiles, instructions, includeChangeOrderLogOnPayApps)
    }

    handleUpdatePendingFiles([])
    onClose(false)
  }, [
    signatureTypes,
    repeatingValuesNote,
    formValuesNote,
    specialFormsNote,
    additionalInfoNote,
    isOnboardingCompanyFormSet,
    handleUpdatePendingFiles,
    onClose,
    templateType,
    pendingFiles,
    uploadCompanyFormSet,
    companyId,
    formType,
    includeCoLogOnPayApps,
    snackbar,
    t,
    contractFormUploadProps,
    shouldDisplayIncludeCoLogToggle,
  ])

  const handleUpdateSignatureType = useCallback((event: SelectChangeEvent) => {
    setSignatureType(event.target.value as FormTemplateOnboardingSignatureType)
  }, [])

  const handleUpdateRequiresNotarization = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!shouldDisplaySignatureInput || !requiresSignature) {
        return
      }
      setRequiresNotarization(checked)
    },
    [requiresSignature, shouldDisplaySignatureInput]
  )

  const handleUpdateFormSetType = useCallback((event: SelectChangeEvent) => {
    setFormSetType(event.target.value as FormSetType)
  }, [])

  const resetDialog = useCallback(() => {
    setPendingFiles([])
    setRepeatingValuesNote('')
    setFormValuesNote('')
    setSpecialFormsNote('')
    setAdditionalInfoNote('')
    setSignatureType(DEFAULT_SIGNATURE_TYPE)
    setRequiresNotarization(false)
    setFormSetType(DEFAULT_FORM_SET_TYPE)
    setIncludeCoLogOnPayApps(!!initialIncludeCoLogInPayAppPackage)
  }, [initialIncludeCoLogInPayAppPackage])

  const hasUploadedFiles = pendingFiles.length > 0
  const isSubmitDisabled = !hasUploadedFiles
  const isSubmitting = contractFormUploadProps?.submitting || submittingCompanyFormSet
  const instructionSections = instructionSectionsForFormsType(formType)
  const requiresNotarizationDisplayValue = requiresSignature ? requiresNotarization : false
  const isPayAppFormType = formType === ProjectOnboardingFormType.PAY_APP
  const isLienWaiverFormType =
    formType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER ||
    formType === ProjectOnboardingFormType.VENDOR_LIEN_WAIVER

  return (
    <SitelineDialog
      open={open}
      onSubmit={handleUploadSubmit}
      onClose={onClose}
      cancelLabel={cancelLabel}
      title={title}
      subtitle={
        <>
          <Spacer minHeight={8} maxHeight={8} />
          <FormUploadBestPracticesHelperText
            helpCenterLinkScrollTo={helpCenterLinkScrollTo}
            prefix={subtitle}
          />
        </>
      }
      submitLabel={t(`${i18nBase}.submit_forms`)}
      size="email"
      disableSubmit={isSubmitDisabled}
      className={classes.root}
      onResetDialog={resetDialog}
      subscript={subscript}
      submitting={isSubmitting}
    >
      <Column gap={20}>
        <Column className="formOnboardingSection">
          {isOnboardingCompanyFormSet && (
            <>
              <SitelineText variant="h4" color="grey90">
                {t(`${i18nBase}.form_type`)}
              </SitelineText>
              <FormControl variant="outlined" fullWidth>
                <Select value={formSetType} onChange={handleUpdateFormSetType} fullWidth>
                  {formTypeDropdownOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isPayAppFormType && (
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.upload_form_set_pay_app`)}
                </SitelineText>
              )}
              {isLienWaiverFormType && (
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.upload_form_set_lien_waiver`)}
                </SitelineText>
              )}
            </>
          )}
          <div className="fileUpload">
            <FileDragUpload
              pendingFiles={pendingFiles}
              setPendingFiles={handleUpdatePendingFiles}
              allowMultiple
              onRemovePendingFile={handleRemovePendingFiles}
              size={hasUploadedFiles ? 'small' : 'medium'}
              layout={hasUploadedFiles ? 'row' : 'column'}
            />
          </div>
          {hasUploadedFiles && (
            <SitelineText color="grey50" variant="body1" className="examples">
              {t(`${i18nBase}.upload_instructions_examples`)}
            </SitelineText>
          )}
        </Column>
        {hasUploadedFiles && (
          <>
            {shouldDisplaySignatureInput && (
              <Column className="formOnboardingSection">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.signature_type`)}
                </SitelineText>
                <FormControl variant="outlined" fullWidth>
                  <Select value={signatureType} onChange={handleUpdateSignatureType} fullWidth>
                    {SORTED_SIGNATURE_TYPES.map((currentOption) => (
                      <MenuItem
                        key={currentOption}
                        value={currentOption}
                        className={classes.menuItem}
                      >
                        {t(`${i18nBase}.signature_types.${currentOption}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  label={t(`${i18nBase}.signature_types.NOTARIZATION`)}
                  className="signatureType"
                  control={
                    <SitelineCheckbox
                      value={requiresNotarizationDisplayValue}
                      checked={requiresNotarizationDisplayValue}
                      onChange={handleUpdateRequiresNotarization}
                      disabled={!requiresSignature}
                    />
                  }
                />
              </Column>
            )}
            {instructionSections.includes('formValues') && (
              <Column className="formOnboardingSection">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.form_values`)}
                </SitelineText>
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.form_values_subtitle`)}
                </SitelineText>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  value={formValuesNote}
                  placeholder={
                    formType === ProjectOnboardingFormType.PAY_APP
                      ? t(`${i18nBase}.form_values_placeholder_pay_app`)
                      : t(`${i18nBase}.form_values_placeholder`)
                  }
                  onChange={(event) => setFormValuesNote(event.target.value)}
                  slotProps={SLOT_PROPS}
                />
              </Column>
            )}
            {instructionSections.includes('repeatingInputs') && (
              <Column className="formOnboardingSection">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.repeating_inputs`)}
                </SitelineText>
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.repeating_inputs_subtitle`)}
                </SitelineText>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  placeholder={t(`${i18nBase}.repeating_inputs_placeholder`)}
                  value={repeatingValuesNote}
                  onChange={(event) => setRepeatingValuesNote(event.target.value)}
                  slotProps={SLOT_PROPS}
                />
              </Column>
            )}
            {instructionSections.includes('specialConditions') && (
              <Column className="formOnboardingSection">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.special_conditions`)}
                </SitelineText>
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.special_conditions_subtitle`)}
                </SitelineText>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  value={specialFormsNote}
                  placeholder={t(`${i18nBase}.special_conditions_placeholder`)}
                  onChange={(event) => setSpecialFormsNote(event.target.value)}
                  slotProps={SLOT_PROPS}
                />
              </Column>
            )}
            {instructionSections.includes('additionalInfo') && (
              <Column className="formOnboardingSection">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.additional_info`)}
                </SitelineText>
                <SitelineText variant="body1" color="grey50">
                  {t(`${i18nBase}.additional_info_subtitle`)}
                </SitelineText>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  value={additionalInfoNote}
                  placeholder={t(`${i18nBase}.additional_info_placeholder`)}
                  onChange={(event) => setAdditionalInfoNote(event.target.value)}
                  slotProps={SLOT_PROPS}
                />
              </Column>
            )}
          </>
        )}
      </Column>
    </SitelineDialog>
  )
}
