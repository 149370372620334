import { Alert } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'siteline-common-web'

export const SitelineAlert = styled(Alert)(() => ({
  '&.MuiAlert-root': {
    alignItems: 'center',
    // Redeclaring the original padding as !important to override any other padding rules
    padding: '6px 16px !important',
  },
  '&.MuiAlert-message': {
    color: colors.grey90,
    fontSize: '.8125rem',
  },
  '&.MuiAlert-standardWarning': {
    backgroundColor: colors.yellow20,
    '& .MuiAlert-icon': {
      color: colors.yellow50,
    },
    '& .MuiAlert-message': {
      color: colors.yellow70,
    },
  },
  '&.MuiAlert-standardSuccess': {
    backgroundColor: colors.green10,
    '& .MuiAlert-icon': {
      color: colors.green40,
    },
  },
  '&.MuiAlert-standardInfo': {
    backgroundColor: colors.blue10,
    '& .MuiAlert-icon': {
      color: colors.blue50,
    },
  },
  '&.MuiAlert-standardError': {
    backgroundColor: colors.red10,
    '& .MuiAlert-icon': {
      color: colors.red70,
    },
  },
}))
