import { useChat } from '@ai-sdk/react'
import { ChevronRight } from '@mui/icons-material'
import { Button, Card, CircularProgress, IconButton, Slide, styled, TextField } from '@mui/material'
import clsx from 'clsx'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, useLocation } from 'react-use'
import { colors, Column, Row, SitelineText } from 'siteline-common-web'
import aiIcon from '../../../assets/images/aiChat.svg'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { config } from '../../../common/config/constants'
import { useMultiCompanyContext } from '../../../common/contexts/CompanyContext'
import { useUserContext } from '../../../common/contexts/UserContext'
import { Z_INDEX } from '../../../common/themes/Main'
import { ChatMessage } from './ChatMessage'

export const AI_CHAT_ENABLED_STORAGE_KEY = 'siteline-ai-chat-enabled'

const i18nBase = 'ai_assistant'

const TRANSITION_DURATION = 300
const MAX_NAME_CHARS = 16

const StyledChatSidebar = styled(Card)(({ theme }) => ({
  position: 'fixed',
  zIndex: Z_INDEX.popover,
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  top: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: colors.white,
  borderRadius: 8,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
  width: '30vw',
  minWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  '& .actions': {
    margin: theme.spacing(-2, -2, 0, 0),
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  '& .chatContainer': {
    height: '100%',
    marginTop: theme.spacing(-3),
    '& .messagesContainer': {
      height: '100%',
      overflow: 'hidden',
      '& .initialMessage': {
        position: 'relative',
        pointerEvents: 'none',
        '& .top': {
          position: 'relative',
          height: 124,
          transition: theme.transitions.create(['height', 'margin-top'], {
            duration: TRANSITION_DURATION,
            easing: 'ease-out',
          }),
          '& .iconContainer': {
            position: 'absolute',
            top: 56,
            left: '50%',
            transform: 'translateX(-50%)',
            transition: theme.transitions.create(['top', 'transform', 'left'], {
              duration: TRANSITION_DURATION,
              easing: 'ease-out',
            }),
            '& img': {
              width: 20,
              height: 20,
              transform: 'scale(1.5)',
              transition: theme.transitions.create('transform', { duration: TRANSITION_DURATION }),
            },
          },
          '& .helloText': {
            position: 'absolute',
            top: 100,
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap',
            transition: theme.transitions.create(['top', 'transform', 'left'], {
              duration: TRANSITION_DURATION,
              easing: 'ease-out',
            }),
          },
        },
        '& .border': {
          height: 1,
          width: 0,
          backgroundColor: colors.grey20,
          opacity: 0,
          transition: theme.transitions.create(['opacity', 'width'], {
            duration: TRANSITION_DURATION,
            easing: 'ease-out',
          }),
          marginTop: theme.spacing(2),
        },
        '& .bottom': {
          position: 'absolute',
          margin: theme.spacing(-1, 4, 3),
          opacity: 1,
          transition: theme.transitions.create('opacity', { duration: TRANSITION_DURATION }),
        },
        '&.hasMessages': {
          '& .top': {
            marginTop: 0,
            height: 24,
            '& .iconContainer': {
              width: 24,
              left: 0,
              top: 2,
              transform: 'translateX(0)',
              '& img': {
                transform: 'scale(1)',
              },
            },
            '& .helloText': {
              left: 40,
              top: 0,
              transform: 'translateX(0)',
            },
          },
          '& .bottom': {
            opacity: 0,
          },
          '& .border': {
            opacity: 1,
            width: '100%',
          },
        },
      },
      '& .messages': {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingTop: theme.spacing(3),
        '& ol': {
          listStyle: 'decimal',
          marginLeft: theme.spacing(5),
        },
        '& ul': {
          listStyle: 'disc',
          marginLeft: theme.spacing(5),
        },
        '& h1': {
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(4),
        },
        '& h2': {
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
        },
        '& h3': {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
  '& .waitingForResponse': {
    marginBottom: theme.spacing(3),
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& svg': {
      color: colors.brandOrange,
    },
  },
  '& .error': {
    marginBottom: theme.spacing(2),
  },
}))

interface ChatSidebarProps {
  isOpen: boolean
  onClose: () => void
}

/**
 * Listener in the top navigation bar that opens a chatbot popup
 */
export function ChatSidebar({ isOpen, onClose }: ChatSidebarProps) {
  const { firstName } = useUserContext()
  const { href } = useLocation()
  const { t } = useTranslation()
  const { companyId } = useMultiCompanyContext()

  const { messages, status, error, reload, handleSubmit, input, handleInputChange } = useChat({
    api: config.url.CHAT_API_URL,
    body: {
      currentUrl: href,
      companyId,
    },
    fetch: async (url, options) => {
      const auth = getAuth(getApp())
      const currentUser = auth.currentUser
      if (!currentUser) {
        throw new Error('User not authenticated')
      }
      const token = await currentUser.getIdToken()
      return fetch(url, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${token}`,
        },
      })
    },
    // Throttle the messages and data updates to 100ms:
    experimental_throttle: 100,
  })

  const hasMessages = messages.length > 0

  useEffect(() => {
    onClose()
  }, [onClose, href])

  useEffect(() => {
    const handleClick = (evt: Event) => {
      if (isOpen) {
        const chatButton = document.getElementById('aiChatButton')
        const isChatButtonClick = chatButton?.contains(evt.target as Node)
        if (isChatButtonClick) {
          return
        }

        const container = document.getElementById('popupContainer')
        const isBackdropClick = !container?.contains(evt.target as Node)
        if (isBackdropClick) {
          onClose()
        }
      }
    }

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [onClose, isOpen])

  const ref = useRef<HTMLDivElement>(null)
  useDebounce(
    () => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    },
    100,
    [messages, ref]
  )

  // Focus the input when opened
  useEffect(() => {
    if (isOpen) {
      const input = document.getElementById('chat-input') as HTMLInputElement
      input.focus()
    }
  }, [isOpen])

  // After a certain number of characters, the greeting won't fit on one line. In that case, just
  // remove the first name from the greeting.
  const greeting =
    firstName.length > MAX_NAME_CHARS
      ? t(`${i18nBase}.hello`)
      : t(`${i18nBase}.hello_name`, { firstName })

  const showSpinner = useMemo(() => {
    // Status must be submitted (text sent but nothing received), or streaming (chatbot is responding)
    // to show the spinner
    if (status !== 'submitted' && status !== 'streaming') {
      return false
    }

    // If there is no last message, don't show the spinner
    const lastMessage = _.last(messages)
    if (!lastMessage) {
      return false
    }

    // If the last message is a user message, it means we don't have anything back from the bot yet,
    // so we show the spinner
    if (lastMessage.role === 'user') {
      return true
    }

    // Same if the last message is empty
    const lastPart = _.last(lastMessage.parts)
    if (!lastPart) {
      return true
    }

    // If the last message is a tool invocation, and it's not finished, show the spinner
    if (lastPart.type === 'tool-invocation') {
      return true
    }

    // If the last message is a step start, it means the bot is thinking, so we show the spinner
    if (lastPart.type === 'step-start') {
      return true
    }

    // In other cases, don't show the spinner
    return false
  }, [messages, status])

  return (
    <Slide in={isOpen} direction="left">
      <StyledChatSidebar id="popupContainer" className={clsx({ isOpen })}>
        <Row justifyContent="flex-end" className="actions">
          <IconButton onClick={onClose}>
            <ChevronRight />
          </IconButton>
        </Row>
        <Column className="chatContainer">
          <Column className="messagesContainer">
            <div className={clsx('initialMessage', { hasMessages })}>
              <Row className="top" gap={16} alignItems="center">
                <Row alignItems="center" justifyContent="center" className="iconContainer">
                  <img src={aiIcon} alt="Siteline" />
                </Row>
                <Row alignItems="center" className="helloText">
                  <SitelineText variant="h4" color="grey50">
                    {greeting}
                  </SitelineText>
                </Row>
              </Row>
              <div className="border" />
              <Column alignItems="center" gap={8} className="bottom">
                <SitelineText variant="h4" color="grey90">
                  {t(`${i18nBase}.title`)}
                </SitelineText>
                <SitelineText variant="body2" color="grey50" align="center">
                  {t(`${i18nBase}.subtitle`)}
                </SitelineText>
              </Column>
            </div>
            <Column className="messages">
              {messages.map((message) => (
                <ChatMessage
                  key={message.id}
                  id={message.id}
                  parts={message.parts}
                  role={message.role}
                />
              ))}
              {showSpinner && (
                <div className="waitingForResponse">
                  <CircularProgress size={20} />
                </div>
              )}
              {error && (
                <SitelineAlert
                  severity="error"
                  action={<Button onClick={() => reload()}>{t(`${i18nBase}.retry`)}</Button>}
                  className="error"
                >
                  {error.message}
                </SitelineAlert>
              )}
              <div ref={ref} />
            </Column>
          </Column>
          <form onSubmit={handleSubmit}>
            <TextField
              id="chat-input"
              value={input}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              placeholder={t(`${i18nBase}.placeholder`)}
            />
          </form>
        </Column>
      </StyledChatSidebar>
    </Slide>
  )
}
