import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractForVendorsCopyDialog } from '../../components/vendors/add-vendors/CopyProjectVendorsDialog'
import { ContractWithGc, contractsHaveSameGc } from '../util/Contact'
import { ComplianceContract } from '../util/LegalRequirement'
import { ContractForForms } from '../util/ProjectOnboarding'
import { BlueChip } from './SitelineChip'

interface SameGcChipProps {
  currentContract: ContractWithGc | undefined
  otherContract: ComplianceContract | ContractForForms | ContractForVendorsCopyDialog | undefined
  /**
   * This component will return null if the GC on `currentContract` is not a match for the GC on
   * `otherContract`. Pass in `generalContractorId` if you want this comparison to be made directly
   * to a specific GC id instead of passing in `otherContract`.
   */
  generalContractorId?: string
}

/**
 * Status chip displayed on dropdown menus of projects to copy from. Indicates
 * that the current project has the same GC as the current project. Primarily
 * displayed on the CopyProjectFormsDialog component. Handles comparing GCs
 * and rendering conditionally.
 **/
export function SameGcChip({
  currentContract,
  otherContract,
  generalContractorId,
}: SameGcChipProps) {
  const { t } = useTranslation()

  const hasSameGc = useMemo(() => {
    if (generalContractorId) {
      const currentGc = currentContract?.project.generalContractor
      return !!currentGc && currentGc.company.id === generalContractorId
    }
    return contractsHaveSameGc(currentContract, otherContract)
  }, [currentContract, otherContract, generalContractorId])

  if (!hasSameGc) {
    return null
  }

  return <BlueChip size="small" label={t('projects.subcontractors.settings.forms.same_gc')} />
}
