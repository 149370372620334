import { Message } from '@ai-sdk/react'
import { memo, useMemo } from 'react'
import { ChatMessagePart } from './ChatMessagePart'

/**
 * A single message in the chat.
 * A message is a collection of parts (actual text message, tool invocation, etc.)
 * We display each part separately.
 */
export const ChatMessage = memo(function ChatMessage({
  id,
  role,
  parts: messageParts,
}: Pick<Message, 'role' | 'parts' | 'id'>) {
  const parts = useMemo(() => messageParts ?? [], [messageParts])
  return (
    <>
      {parts.map((part, index) => (
        <ChatMessagePart key={`${id}-${index}`} role={role} part={part} />
      ))}
    </>
  )
})
