import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { integrationTypes, supportsLinkingTaxGroup } from 'siteline-common-all'
import { toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { SitelineDialog } from '../../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../../common/contexts/CompanyContext'
import * as fragments from '../../../../common/graphql/Fragments'
import {
  Company,
  TaxGroupProperties,
  useCreateTaxGroupsMutation,
  useUpdateTaxGroupMutation,
} from '../../../../common/graphql/apollo-operations'
import { trackCreateTaxGroup, trackEditTaxGroup } from '../../../../common/util/MetricsTracking'
import { AddOrEditTaxGroupForm } from './AddOrEditTaxGroupForm'

export type AddTaxRateLocation =
  | 'settings'
  | 'excelImport'
  | 'integrationImport'
  | 'sov'
  | 'quickBill'
  | 'changeOrderRequest'
  | 'createProject'
  | 'rateTableTaxSettings'

interface AddOrEditTaxGroupDialogProps {
  open: boolean
  onClose: () => void
  taxGroup: TaxGroupProperties | null
  allTaxGroups: TaxGroupProperties[]
  onAddedTaxGroups: (taxGroups: TaxGroupProperties[]) => void
  location: AddTaxRateLocation
}

const i18nBase = 'projects.subcontractors.taxes'

/** Dialog for creating or editing a tax group */
export function AddOrEditTaxGroupDialog({
  open,
  onClose,
  taxGroup,
  allTaxGroups,
  onAddedTaxGroups,
  location,
}: AddOrEditTaxGroupDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { companyId, company } = useCompanyContext()
  const [createTaxGroups, { loading: creating }] = useCreateTaxGroupsMutation()
  const [updateTaxGroup, { loading: updating }] = useUpdateTaxGroupMutation()
  const initialTaxName = useMemo(() => taxGroup?.name ?? '', [taxGroup])
  const [taxName, setTaxName] = useState(initialTaxName)
  const initialTaxPercent = useMemo(() => taxGroup?.taxPercent ?? null, [taxGroup])
  const [taxPercent, setTaxPercent] = useState<number | null>(initialTaxPercent)

  const companyIntegrations = useMemo(
    () => [...(company?.companyIntegrations ?? [])],
    [company?.companyIntegrations]
  )
  const taxGroupSupportedIntegrations = useMemo(
    () => companyIntegrations.filter((integration) => supportsLinkingTaxGroup(integration.type)),
    [companyIntegrations]
  )
  const initialIntegrationTaxGroupIds = useMemo(() => {
    if (!taxGroup) {
      return {}
    }
    const mappings = taxGroup.integrationMappings as integrationTypes.TaxGroupIntegrationMappings
    return _.fromPairs(
      mappings.integrations.map((mapping) => [
        mapping.companyIntegrationId,
        mapping.integrationTaxGroupId,
      ])
    )
  }, [taxGroup])
  // Map of company integration IDs to the selected integration tax group IDs
  const [integrationTaxGroupIds, setIntegrationTaxGroupIds] = useState<Record<string, string>>(
    initialIntegrationTaxGroupIds
  )

  const handleResetDialog = useCallback(() => {
    setTaxName(initialTaxName)
    setTaxPercent(initialTaxPercent)
    setIntegrationTaxGroupIds(initialIntegrationTaxGroupIds)
  }, [initialTaxName, initialTaxPercent, initialIntegrationTaxGroupIds])

  // Update the tax name and percent if the provided data changes
  useEffect(() => {
    handleResetDialog()
  }, [handleResetDialog])

  const isEditingTaxGroup = taxGroup !== null

  const handleSubmit = useCallback(async () => {
    if (!taxName || taxPercent === null) {
      return
    }

    const integrationMappingsInput = Object.entries(integrationTaxGroupIds).map(
      ([companyIntegrationId, integrationTaxGroupId]) => ({
        companyIntegrationId,
        integrationTaxGroupId,
      })
    )

    if (isEditingTaxGroup) {
      try {
        await updateTaxGroup({
          variables: {
            input: {
              taxGroupId: taxGroup.id,
              name: taxName,
              taxPercent,
              integrationMappings: integrationMappingsInput,
            },
          },
        })
        trackEditTaxGroup({
          taxGroupId: taxGroup.id,
          newTaxGroupName: taxName,
          newTaxRatePercent: taxPercent,
          newIntegrations: companyIntegrations
            .filter((integration) => Object.keys(integrationTaxGroupIds).includes(integration.id))
            .map((integration) => integration.shortName),
        })
        onClose()
      } catch (err) {
        snackbar.showError(err.message)
      }
    } else {
      try {
        const { data } = await createTaxGroups({
          variables: {
            input: {
              companyId,
              taxGroups: [
                {
                  name: taxName,
                  taxPercent,
                  integrationMappings: integrationMappingsInput,
                },
              ],
            },
          },
          update(cache, { data }) {
            if (!data || !company) {
              return
            }

            cache.modify<WritableDeep<Company>>({
              id: cache.identify(company),
              fields: {
                taxGroups(existingRequests, { toReference }) {
                  const newTaxGroupRefs = data.createTaxGroups.map((taxGroup) => {
                    return cache.writeFragment({
                      data: taxGroup,
                      fragment: fragments.taxGroup,
                      fragmentName: 'TaxGroupProperties',
                    })
                  })
                  const refs = toReferences(existingRequests, toReference)
                  return _.compact([...refs, ...newTaxGroupRefs])
                },
              },
            })
          },
        })
        trackCreateTaxGroup({ taxGroupName: taxName, taxRatePercent: taxPercent, location })
        if (data) {
          onAddedTaxGroups([...data.createTaxGroups])
        }
      } catch (err) {
        snackbar.showError(err.message)
      }
    }
  }, [
    company,
    companyId,
    companyIntegrations,
    createTaxGroups,
    integrationTaxGroupIds,
    isEditingTaxGroup,
    location,
    onAddedTaxGroups,
    onClose,
    snackbar,
    taxGroup?.id,
    taxName,
    taxPercent,
    updateTaxGroup,
  ])

  const allTaxGroupNamesSet = useMemo(() => {
    const allTaxGroupNames = allTaxGroups.map((taxGroup) => _.toLower(taxGroup.name))
    return new Set(allTaxGroupNames)
  }, [allTaxGroups])
  const doesTaxGroupNameMatchExistingTaxGroupName = useMemo(() => {
    // If editing a tax group and the name hasn't changed, consider it valid
    if (taxName === initialTaxName) {
      return false
    }
    return allTaxGroupNamesSet.has(_.toLower(taxName))
  }, [allTaxGroupNamesSet, initialTaxName, taxName])

  const shouldDisableSubmit =
    !taxName.trim() || taxPercent === null || doesTaxGroupNameMatchExistingTaxGroupName

  return (
    <SitelineDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      maxWidth="sm"
      disableSubmit={shouldDisableSubmit}
      submitting={creating || updating}
      title={isEditingTaxGroup ? t(`${i18nBase}.edit_tax_group`) : t(`${i18nBase}.add_tax_group`)}
      subtitle={isEditingTaxGroup ? undefined : t(`${i18nBase}.create_description`)}
      subtitleVariant="body1"
      submitLabel={isEditingTaxGroup ? t('common.actions.save') : t('common.actions.add')}
      onResetDialog={handleResetDialog}
    >
      <AddOrEditTaxGroupForm
        taxName={taxName}
        onTaxNameChange={setTaxName}
        taxPercent={taxPercent}
        onTaxPercentChange={setTaxPercent}
        integrationTaxGroupIds={integrationTaxGroupIds}
        onIntegrationTaxGroupIdsChange={setIntegrationTaxGroupIds}
        integrations={taxGroupSupportedIntegrations}
        isNameValid={!doesTaxGroupNameMatchExistingTaxGroupName}
      />
    </SitelineDialog>
  )
}
