import { DocumentNode } from 'graphql'
import _ from 'lodash'
import { useEffect } from 'react'
import {
  BillingType,
  FormTemplateAnnotationType,
  PhysicalSignatureRequired,
  ProjectOnboardingFormType,
  SignatureAnnotationType,
  pdfTypes,
} from 'siteline-common-all'
import { ReadonlyDeep } from 'type-fest'
import { ContractForProjectHome } from '../../components/billing/home/ProjectHome'
import {
  ContractForProjectOnboarding,
  PayAppRequirementGroupForOnboarding,
} from '../../components/billing/onboarding/OnboardingTaskList'
import { ContractForProjectSettings } from '../../components/vendors/Vendors.lib'
import { SignatureInput } from '../components/Pdf/PdfViewer'
import {
  CompanyFormTemplateCollectionProperties,
  CompanyLienWaiverTemplateSetProperties,
  CompanyPayAppFormTemplateSetProperties,
  ContractForPayAppRequirementGroupsQuery,
  ContractForVendorsProjectHome,
  FormTemplateAnnotationValueInput,
  FormTemplateProperties,
  GetCompanyForFormsQuery,
  MinimalSubcontractorLienWaiverTemplateSetProperties,
  PayAppRequirementGroupProperties,
} from '../graphql/apollo-operations'
import { ContractOnboardingFormType } from './ProjectOnboarding'

export function numSignaturesMissing(
  signatureInput: SignatureInput,
  metadata: pdfTypes.PageMetadata[]
) {
  const requiredSignatureIds = _.flatten(
    _.map(metadata, ({ annotations }) => {
      return annotations
        .filter(
          (annotation) =>
            annotation.signatureType === SignatureAnnotationType.DIGITAL && !annotation.isOptional
        )
        .map((annotation) => annotation.id)
    })
  )

  return _.difference(requiredSignatureIds, signatureInput.signedAnnotationIds).length
}

/**
 * Return the number of missing user-entered fields. Missing signatures are detected separately.
 */
export function numRequiredUserEnteredFieldsMissing(
  formValuesInput: FormTemplateAnnotationValueInput[],
  metadata: pdfTypes.PageMetadata[]
) {
  const filledOutAnnotationIds = formValuesInput
    .filter((input) => input.value.length > 0)
    .map((input) => input.formTemplateAnnotationId)
  const allAnnotations = _.flatten(_.map(metadata, (metadata) => metadata.annotations))
  const requiredAnnotations = _.filter(allAnnotations, (annotation) => {
    return (
      annotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD && !annotation.isOptional
    )
  })
  const requiredAnnotationIds = _.map(requiredAnnotations, (annotation) => annotation.id)
  return _.difference(requiredAnnotationIds, filledOutAnnotationIds).length
}

type CompanyForPhysicalSignature = { notarySignature?: { id: string } | null }

/**
 * Given a list of page metadata, determine if a physical signature is required.
 * Note that if the signing company has a default notary signature, notary annotations will be ignored,
 * as long as the forms allow for it (useCompanyNotarySignatureIfAvailable = true)
 */
export function requiresPhysicalSignature(
  pages: pdfTypes.PageMetadata[],

  /**
   * Customer company signing the form.
   * For forms signed by the subcontractor, pass the subcontractor company.
   * For forms signed by a vendor, pass null.
   */
  company: CompanyForPhysicalSignature | null
): PhysicalSignatureRequired {
  for (const page of pages) {
    const annotations = page.annotations
    if (annotations.length === 0) {
      continue
    }
    const isMissingCompanyNotarySignature = !company || !company.notarySignature
    if (
      annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.NOTARY) &&
      (isMissingCompanyNotarySignature || !page.useCompanyNotarySignatureIfAvailable)
    ) {
      return PhysicalSignatureRequired.NOTARY_SIGNATURE_REQUIRED
    }
    if (
      annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.WITNESS)
    ) {
      return PhysicalSignatureRequired.WITNESS_SIGNATURE_REQUIRED
    }
    if (annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.WET)) {
      return PhysicalSignatureRequired.WET_SIGNATURE_REQUIRED
    }
  }
  return PhysicalSignatureRequired.NONE_REQUIRED
}

/**
 * Turns off autocomplete functionality for all browsers. We have to do it this way because Chrome
 * actively tries really hard to NOT get you to do this. Given that we have our own autocomplete
 * for emails, we don't want to double-pop the autocomplete box.
 */
export function useBrowserAutocompleteDisabled(formRef: React.RefObject<HTMLElement>) {
  useEffect(() => {
    const elements = formRef.current?.getElementsByTagName('input') || []
    // Using a basic for loop here because HTMLCollectionOf only supports traverse by index
    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      const input = elements[elementIndex] as HTMLInputElement
      input.autocomplete = 'new-password'
    }
  }, [formRef])
}

export function createSignatureInput(signatureInput: SignatureInput) {
  const { signatureId, uploaded, digital } = signatureInput
  return {
    ...(signatureId && { signatureId }),
    ...(uploaded &&
      uploaded.file && {
        uploadSignatureInput: { signatureFile: uploaded.file },
      }),
    ...(digital && {
      digitalSignatureInput: {
        signatureName: digital.signature,
        signatureFont: digital.signatureFont,
      },
    }),
    signedAnnotationIds: signatureInput.signedAnnotationIds,
  }
}

export type CompanyWithForms = GetCompanyForFormsQuery['company']

export type VariantAndVersion = {
  variantId: string
  versionId: string
}

/**
 * Returns the latest version of a template
 */
export function getLatestVersion(
  template: ReadonlyDeep<VariantInput['template']>
): VariantInput['template']['versions'][number] | null {
  const latestVersion = _.maxBy(template.versions, (version) => version.versionNumber)
  return latestVersion ?? null
}

type VariantInput = {
  id: string
  template: {
    id: string
    versions: { id: string; versionNumber: number }[]
  }
}

/**
 * Given a variant, returns the IDs of the latest template version and the variant itself.
 */
export function resolveTemplateFromVariant(
  variant: ReadonlyDeep<VariantInput>
): VariantAndVersion | null {
  const latestVersion = getLatestVersion(variant.template)
  if (!latestVersion) {
    return null
  }
  return { versionId: latestVersion.id, variantId: variant.id }
}

type TemplateInput = {
  id: string
  versions: { id: string; versionNumber: number }[]
  variants: { id: string; isDefaultVariant: boolean }[]
}

/**
 * Given a template, returns the IDs of the latest version and the default variant.
 */
export function resolveTemplate(template: ReadonlyDeep<TemplateInput>): VariantAndVersion | null {
  const latestVersion = getLatestVersion(template)
  const defaultVariant = template.variants.find((variant) => variant.isDefaultVariant)
  if (!latestVersion || !defaultVariant) {
    return null
  }
  return { versionId: latestVersion.id, variantId: defaultVariant.id }
}

function deriveFormProcessingStatusFromPayAppRequirementGroups(
  payAppRequirementGroups: PayAppRequirementGroupProperties[]
): boolean {
  const allRequirements = _.flatMap(
    payAppRequirementGroups,
    (group) => group.payAppRequirements
  ).filter((requirement) => !!requirement.templateVariant)

  return allRequirements.some(
    (requirement) => requirement.templateVariant?.template.isCustomerReady === false
  )
}

function deriveFormProcessingStatusFromLienWaiverTemplateSet(
  templateSet: MinimalSubcontractorLienWaiverTemplateSetProperties
): boolean {
  return _.compact([
    templateSet.conditionalFinalVariant,
    templateSet.conditionalProgressVariant,
    templateSet.unconditionalFinalVariant,
    templateSet.unconditionalProgressVariant,
  ]).some((variant) => variant.template.isCustomerReady === false)
}

export function deriveTemplateIdsFromPayAppRequirementGroups(
  payAppRequirementGroups: PayAppRequirementGroupProperties[],
  /**
   * We use this function in several places. For form selection, we want to include processing forms.
   * For form preview, we want to exclude processing forms.
   */
  includeProcessingForms: boolean
): VariantAndVersion[] {
  return _.chain(payAppRequirementGroups)
    .orderBy((requirementGroup) => requirementGroup.order)
    .flatMap((group) =>
      _.chain(group.payAppRequirements)
        .filter((requirement) => {
          if (includeProcessingForms) {
            return !!requirement.templateVariant
          }
          return !!requirement.templateVariant?.template.isCustomerReady
        })
        .orderBy((requirement) => requirement.groupOrder)
        .flatMap((requirement) => {
          if (!requirement.templateVariant) {
            return null
          }
          return resolveTemplateFromVariant(requirement.templateVariant)
        })
        .value()
    )
    .compact()
    .value()
}

export function deriveTemplateIdsFromLienWaiverTemplateSet(
  templateSet: MinimalSubcontractorLienWaiverTemplateSetProperties,
  /**
   * We use this function in several places. For form selection, we want to include processing forms.
   * For form preview, we want to exclude processing forms.
   */
  includeProcessingForms: boolean
): VariantAndVersion[] {
  return _.chain([
    templateSet.conditionalFinalVariant,
    templateSet.conditionalProgressVariant,
    templateSet.unconditionalFinalVariant,
    templateSet.unconditionalProgressVariant,
  ])
    .compact()
    .filter((variant) => {
      if (includeProcessingForms) {
        return true
      }
      return variant.template.isCustomerReady
    })
    .map(resolveTemplateFromVariant)
    .compact()
    .value()
}

export function derivePayAppTemplateIdsFromContract(
  contract: ContractForPayAppRequirementGroupsQuery['contractByProjectId']
): VariantAndVersion[] {
  if (!contract.payAppRequirementGroups.length) {
    return []
  }
  const payAppRequirementGroups = [...contract.payAppRequirementGroups]
  const templateIds = deriveTemplateIdsFromPayAppRequirementGroups(payAppRequirementGroups, false)

  return templateIds
}

export function derivePayAppTemplateIdsFromCollection(
  collection: CompanyFormTemplateCollectionProperties,
  includeProcessingForms: boolean
): VariantAndVersion[] {
  const payAppRequirementGroups = collection.payAppForms?.payAppRequirementGroups ?? []
  const templateIds = deriveTemplateIdsFromPayAppRequirementGroups(
    [...payAppRequirementGroups],
    includeProcessingForms
  )
  return templateIds
}

export function getPayAppRequirementGroupForFormTemplateId(
  contract: ContractForPayAppRequirementGroupsQuery['contractByProjectId'],
  templateId: string
): PayAppRequirementGroupForOnboarding | undefined {
  return _.find(contract.payAppRequirementGroups, (group) => {
    return group.payAppRequirements.some(
      (requirement) =>
        requirement.templateVariant && requirement.templateVariant.template.id === templateId
    )
  })
}

export function derivePrimaryLienWaiverTemplatesFromContract(
  contract: ContractForProjectOnboarding
): VariantAndVersion[] {
  if (!contract.lienWaiverTemplates) {
    return []
  }
  return deriveTemplateIdsFromLienWaiverTemplateSet(contract.lienWaiverTemplates, false)
}

export function derivePrimaryLienWaiverTemplatesFromCollection(
  collection: CompanyFormTemplateCollectionProperties,
  includeProcessingForms: boolean
): VariantAndVersion[] {
  const primaryLienWaivers = collection.primaryLienWaivers?.lienWaivers
  if (!primaryLienWaivers) {
    return []
  }
  return deriveTemplateIdsFromLienWaiverTemplateSet(primaryLienWaivers, includeProcessingForms)
}

export function deriveVendorLienWaiverTemplatesFromContract(
  contract:
    | ContractForProjectOnboarding
    | ContractForVendorsProjectHome
    | ContractForProjectSettings
): VariantAndVersion[] {
  if (!contract.lowerTierLienWaiverTemplates) {
    return []
  }
  return deriveTemplateIdsFromLienWaiverTemplateSet(contract.lowerTierLienWaiverTemplates, false)
}

export function deriveVendorLienWaiverTemplatesFromCollection(
  collection: CompanyFormTemplateCollectionProperties,
  includeProcessingForms: boolean
): VariantAndVersion[] {
  const vendorLienWaivers = collection.vendorLienWaivers?.lienWaivers
  if (!vendorLienWaivers) {
    return []
  }
  return deriveTemplateIdsFromLienWaiverTemplateSet(vendorLienWaivers, includeProcessingForms)
}

export function deriveChangeOrderRequestTemplateFromContract(
  contract: Pick<ContractForProjectOnboarding, 'changeOrderRequestTemplate'>
): VariantAndVersion | null {
  if (!contract.changeOrderRequestTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(contract.changeOrderRequestTemplate)
}

export function deriveChangeOrderLogTemplateFromContract(
  contract: Pick<ContractForProjectOnboarding | ContractForProjectHome, 'changeOrderLogTemplate'>
): VariantAndVersion | null {
  if (!contract.changeOrderLogTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(contract.changeOrderLogTemplate)
}

export function deriveChangeOrderLogTemplateFromCollection(
  collection: CompanyFormTemplateCollectionProperties
): VariantAndVersion | null {
  const changeOrderLog = collection.changeOrderLog
  if (!changeOrderLog) {
    return null
  }
  return resolveTemplate(changeOrderLog.template)
}

export type CompanyTemplateSet = {
  id: string
  name: string
  generalContractor: { id: string; name: string } | null
  formTemplates: VariantAndVersion[]
  /**
   * This is only applicable to pay app forms sets. True if the pay app set includes a form
   * that is conditional. For example, a pay app set with a form that is only generated on retention
   * pay apps.
   */
  hasConditionalRequirements?: boolean
  isProcessingForms: boolean
}

type CollectionTemplateSetsMap = Partial<Record<ContractOnboardingFormType, CompanyTemplateSet>>

export type CompanyTemplateCollection = {
  id: string
  name: string
  generalContractor: { id: string; name: string } | null
  templateSets: CollectionTemplateSetsMap
}

export function filterCollectionCustomerReadyFormTemplates(
  collection: CompanyTemplateCollection
): CollectionTemplateSetsMap {
  const filteredTemplateSets: CollectionTemplateSetsMap = {}
  Object.entries(collection.templateSets).forEach(([formType, formSet]) => {
    const hasFormsForPreview = formSet.formTemplates.length > 0 && !formSet.isProcessingForms
    if (hasFormsForPreview) {
      filteredTemplateSets[formType as ContractOnboardingFormType] = formSet
    }
  })
  return filteredTemplateSets
}

export function buildPayAppTemplateSet(
  templateSet: CompanyPayAppFormTemplateSetProperties
): CompanyTemplateSet {
  const requirementGroups = [...templateSet.payAppRequirementGroups]
  const isProcessingForms = deriveFormProcessingStatusFromPayAppRequirementGroups(requirementGroups)
  return {
    id: templateSet.id,
    name: templateSet.name,
    generalContractor: templateSet.generalContractor,
    hasConditionalRequirements: templateSet.payAppRequirementGroups.some(
      (group) => group.payAppRequirements.length > 1
    ),
    isProcessingForms,
    formTemplates: deriveTemplateIdsFromPayAppRequirementGroups(requirementGroups, false),
  }
}

export function buildPayAppTemplateSets(
  companyPayAppFormTemplateSets: CompanyPayAppFormTemplateSetProperties[],
  billingType: BillingType
): CompanyTemplateSet[] {
  return _.chain(companyPayAppFormTemplateSets)
    .filter((formTemplateSet) => formTemplateSet.billingType === billingType)
    .map(buildPayAppTemplateSet)
    .value()
}

export function buildLienWaiverTemplateSet(
  templateSet: CompanyLienWaiverTemplateSetProperties
): CompanyTemplateSet {
  const lienWaiverTemplateSet = templateSet.lienWaivers
  const isProcessingForms = lienWaiverTemplateSet
    ? deriveFormProcessingStatusFromLienWaiverTemplateSet(lienWaiverTemplateSet)
    : false
  const templateIds = lienWaiverTemplateSet
    ? deriveTemplateIdsFromLienWaiverTemplateSet(lienWaiverTemplateSet, false)
    : []
  return {
    id: templateSet.id,
    name: templateSet.name,
    generalContractor: templateSet.generalContractor,
    formTemplates: templateIds,
    isProcessingForms,
  }
}

/** Applies to primary & vendor lien waivers */
export function buildLienWaiverTemplateSets(
  lienWaiverTemplateSets: CompanyLienWaiverTemplateSetProperties[]
): CompanyTemplateSet[] {
  return lienWaiverTemplateSets.map(buildLienWaiverTemplateSet)
}

export function buildChangeOrderTemplateSet(
  defaultChangeOrderTemplate: FormTemplateProperties
): CompanyTemplateSet {
  const formTemplate = resolveTemplate(defaultChangeOrderTemplate)
  return {
    id: defaultChangeOrderTemplate.id,
    name: defaultChangeOrderTemplate.userVisibleName,
    generalContractor: null,
    formTemplates: formTemplate ? [formTemplate] : [],
    isProcessingForms: defaultChangeOrderTemplate.isCustomerReady === false,
  }
}

/** Applies to change order log & change order request templates */
export function buildChangeOrderTemplateSets(
  defaultChangeOrderTemplate: FormTemplateProperties
): CompanyTemplateSet[] {
  const changeOrderTemplateSet = buildChangeOrderTemplateSet(defaultChangeOrderTemplate)
  return [changeOrderTemplateSet]
}

export type SelectFormsRefetchQuery = {
  refetchQueries: Array<
    | {
        query: DocumentNode
        variables: {
          input: { projectIds: string[]; companyId: string }
        }
      }
    | {
        query: DocumentNode
        variables: {
          input: { projectId: string; companyId: string }
        }
      }
  >
}

/** Single forms that the company has defaults for */
export const DEFAULT_FORM_TYPES = [
  ProjectOnboardingFormType.CHANGE_ORDER_REQUEST,
  ProjectOnboardingFormType.CHANGE_ORDER_LOG,
]

/** Form types that are stored in sets on the company. Ex: Primary lien waiver form set includes conditional progress, unconditional final, etc. */
export const FORM_SET_TYPES = [
  ProjectOnboardingFormType.PAY_APP,
  ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER,
  ProjectOnboardingFormType.VENDOR_LIEN_WAIVER,
]
