import { Grid2, Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { PropsWithChildren, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import { Waypoint } from 'react-waypoint'
import { pdfTypes } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { clampToLines } from 'siteline-common-web/src/utils/CSS'
import { Document, Page as PdfPage } from '../../../pdf'
import { themeSpacing } from '../../themes/Main'
import { SitelineAlert } from '../SitelineAlert'
import { SitelineDialog, SitelineDialogProps } from '../SitelineDialog'

const THUMBNAIL_WIDTH = 220
const THUMBNAIL_PDF_WIDTH = 188
const THUMBNAIL_HEIGHT = 145

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  children: {
    marginBottom: theme.spacing(2),
  },
  processingFormsAlert: {
    marginBottom: theme.spacing(2),
  },
  previewContainer: {
    overflow: 'auto',
    display: 'flex',
  },
  previewGrid: {
    width: '100%',
  },
  loader: {
    marginTop: theme.spacing(3),
  },
  thumbnailPanel: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minWidth: THUMBNAIL_WIDTH,
    },
  },
  thumbnailContainer: {
    height: '100%',
    width: THUMBNAIL_WIDTH,
    overflow: 'auto',
    position: 'fixed',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mainContainer: {
    position: 'relative',
    overflow: 'auto',
    '&.disableScroll': {
      overflow: 'hidden',
    },
  },
  documentContainer: {
    backgroundColor: colors.grey10,
    overflow: 'auto',
  },
  skeletonContainer: {
    background: colors.white,
  },
  documentThumb: {
    overflow: 'hidden',
    margin: theme.spacing(0, 1, 3, 1),
    backgroundColor: colors.grey10,
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: 120,
    },
  },
  documentImage: {
    height: THUMBNAIL_HEIGHT,
    border: `1px solid ${colors.grey20}`,
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 83,
    },
    '& .emptyThumbnail': {
      width: THUMBNAIL_PDF_WIDTH,
      height: 200,
      backgroundColor: colors.white,
    },
  },
  selectedImage: {
    borderColor: colors.grey40,
  },
  documentName: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5),
    '& .Siteline-smallText': clampToLines(2),
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${colors.grey20}`,
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  emptyState: {
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(20),
  },
  skeletonPage: {
    padding: theme.spacing(4),
    background: colors.white,
    width: '100%',
  },
  skeletonThumbnail: {
    width: THUMBNAIL_PDF_WIDTH,
    height: THUMBNAIL_HEIGHT + themeSpacing(2),
    backgroundColor: colors.white,
  },
}))

const GRID_ITEM_SIZE = { xs: 12, md: 11 }

const i18nBase = 'projects.subcontractors.settings.forms'

export interface PdfPreviewDialogProps
  extends PropsWithChildren<
    Pick<
      SitelineDialogProps,
      | 'open'
      | 'onClose'
      | 'cancelLabel'
      | 'title'
      | 'supertitle'
      | 'subtitle'
      | 'subscript'
      | 'onSubmit'
      | 'submitLabel'
      | 'disableSubmit'
      | 'submitting'
      | 'fixedTopPosition'
      | 'onResetDialog'
    >
  > {
  file: Blob | null
  metadata?: pdfTypes.PageMetadata[]
  isProcessingForms: boolean
  loading: boolean
  /**
   * This should only be defined if the component is actually in an empty state.
   * For example, in the "copy forms dialog", this should only be defined if the user
   * hasn't yet selected a project to copy forms from. PdfPreviewDialog doesn't handle
   * this rendering logic, so this empty state will always be displayed if passed in.
   */
  emptyState?: ReactNode
}

/** Simpler version of the PDF viewer for previewing files in a dialog */
export const PdfPreviewDialog = function PdfPreviewDialog({
  file,
  metadata = [],
  isProcessingForms,
  loading,
  emptyState,
  children,
  ...props
}: PdfPreviewDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [documentContainerRef, { width: documentWidth, top }] = useMeasure<HTMLDivElement>()
  const [previewContainerRef, { height: previewHeight }] = useMeasure<HTMLDivElement>()

  const [pageCount, setPageCount] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [hasDocumentLoaded, setHasDocumentLoaded] = useState<boolean>(false)
  const documentContainer = useRef<HTMLDivElement>(null)
  const isWaypointDisabled = useRef<boolean>(true)
  const hasScrolled = useRef<boolean>(false)

  const resetDialog = useCallback(() => {
    isWaypointDisabled.current = true
    hasScrolled.current = false
    setSelectedPage(0)
    setHasDocumentLoaded(false)
    if (props.onResetDialog) {
      props.onResetDialog()
    }
  }, [props])

  const emptyPage = useMemo(
    () => (
      <div
        style={{ width: documentWidth, height: documentWidth * 2, backgroundColor: colors.white }}
      />
    ),
    [documentWidth]
  )

  const emptyThumbnail = useMemo(
    () => <div className={classes.skeletonThumbnail}></div>,
    [classes.skeletonThumbnail]
  )

  const handleDocumentScroll = useCallback(() => {
    // The first time the user scrolls, enable waypoint scrolling
    if (!hasScrolled.current) {
      isWaypointDisabled.current = false
      hasScrolled.current = true
    }
  }, [])

  const handleScrollToPage = useCallback(
    (el: HTMLElement | null) => {
      if (!el) {
        return
      }
      if (documentContainer.current) {
        const container = documentContainer.current
        const yCoordinate = el.getBoundingClientRect().top - top
        isWaypointDisabled.current = true
        container.scrollTo({ top: yCoordinate + container.scrollTop })
        setTimeout(() => (isWaypointDisabled.current = false))
      }
    },
    [top]
  )

  const thumbnailContainerStyles = useMemo(() => ({ height: previewHeight }), [previewHeight])

  const documentContainerStyles = useMemo(
    () => ({
      width: documentWidth,
      height: documentWidth,
    }),
    [documentWidth]
  )

  // Only show thumbnails once the document has loaded, since they need to be constrained
  // by the layout of the dialog once it has been rendered
  const shouldShowThumbnails = !!file && hasDocumentLoaded
  const shouldShowSkeletonThumbnails = (loading || !hasDocumentLoaded) && !shouldShowThumbnails
  const shouldShowDocument = !!file
  const shouldShowSkeletonDocument = loading && !shouldShowDocument
  const shouldDisplayEmptyState = !!emptyState && !shouldShowSkeletonDocument && !shouldShowDocument
  const shouldShowPreviewContainer = !!file || loading || shouldDisplayEmptyState
  const isProcessingAllForms = isProcessingForms && !shouldShowPreviewContainer

  return (
    <SitelineDialog maxWidth="md" {...props} className={classes.root} onResetDialog={resetDialog}>
      {children && <div className={classes.children}>{children}</div>}
      {isProcessingForms && (
        <div className={classes.processingFormsAlert}>
          <SitelineAlert severity="info">
            {isProcessingAllForms
              ? t(`${i18nBase}.processing_other_forms`)
              : t(`${i18nBase}.processing_some_forms`)}
          </SitelineAlert>
        </div>
      )}
      {shouldShowPreviewContainer && (
        <div ref={previewContainerRef} className={classes.previewContainer}>
          <Grid2
            container
            wrap="nowrap"
            justifyContent="space-between"
            spacing={0}
            className={classes.previewGrid}
          >
            {/* Thumbnails */}
            <Grid2 className={classes.thumbnailPanel}>
              <div className={classes.thumbnailContainer} style={thumbnailContainerStyles}>
                {shouldShowThumbnails && (
                  <Document file={file} loading={emptyThumbnail}>
                    {_.range(pageCount).map((pageIndex) => (
                      <div
                        key={pageIndex}
                        onClick={() => {
                          handleScrollToPage(document.getElementById(`page-${pageIndex + 1}`))
                          setSelectedPage(pageIndex)
                        }}
                      >
                        <div className={classes.documentThumb}>
                          <div
                            className={clsx(classes.documentImage, {
                              [classes.selectedImage]: selectedPage === pageIndex,
                            })}
                          >
                            <PdfPage
                              pageNumber={pageIndex + 1}
                              width={THUMBNAIL_PDF_WIDTH}
                              loading={emptyThumbnail}
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                            />
                          </div>
                          <div className={classes.documentName}>
                            <SitelineText variant="smallText" color="grey50" showTitle>
                              {metadata[pageIndex]?.userVisibleName}
                            </SitelineText>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Document>
                )}
                {shouldShowSkeletonThumbnails &&
                  _.range(2).map((index) => (
                    <div key={index} className={classes.documentThumb}>
                      <div className={classes.documentImage}>
                        {loading && (
                          <Skeleton
                            variant="rectangular"
                            width={THUMBNAIL_PDF_WIDTH}
                            height={200}
                          />
                        )}
                        {!loading && <div className="emptyThumbnail" />}
                      </div>
                      <div className={classes.documentName}></div>
                    </div>
                  ))}
              </div>
            </Grid2>

            {/* Pages */}
            <Grid2
              size={GRID_ITEM_SIZE}
              className={clsx(classes.mainContainer, { disableScroll: !file })}
              ref={documentContainer}
              onScroll={handleDocumentScroll}
            >
              <div className={classes.documentContainer}>
                <div ref={documentContainerRef}>
                  {shouldShowDocument && (
                    <Document
                      file={file}
                      loading={emptyPage}
                      // It's possible that this success callback is triggered with a null value
                      onLoadSuccess={(document: { numPages: number } | null) => {
                        if (document !== null) {
                          setPageCount(document.numPages)
                        }
                        setHasDocumentLoaded(true)
                      }}
                    >
                      {_.range(pageCount).map((pageIndex) => (
                        <div
                          id={`page-${pageIndex + 1}`}
                          key={pageIndex}
                          className={classes.pageContainer}
                        >
                          <Waypoint
                            onEnter={() =>
                              !isWaypointDisabled.current && setSelectedPage(pageIndex)
                            }
                            fireOnRapidScroll={false}
                            scrollableAncestor={documentContainer.current}
                            topOffset={-120}
                          />
                          <PdfPage
                            width={documentWidth}
                            pageNumber={pageIndex + 1}
                            loading={emptyPage}
                            renderAnnotationLayer={false}
                          />
                        </div>
                      ))}
                    </Document>
                  )}
                  {shouldDisplayEmptyState && (
                    <div style={documentContainerStyles} className={classes.emptyState}>
                      {emptyState}
                    </div>
                  )}
                </div>
                {shouldShowSkeletonDocument && (
                  <div className={clsx(classes.pageContainer, classes.skeletonPage)}>
                    {_.range(14).map((index) => (
                      <Skeleton
                        key={index}
                        variant="text"
                        width="100%"
                        height={50}
                        style={{ marginBottom: 20 }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Grid2>
          </Grid2>
        </div>
      )}
    </SitelineDialog>
  )
}
